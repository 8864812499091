import React, { useCallback } from "react";

import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

import Loading from "../../containers/Loading";
import { useAppSelector } from "../../hooks";
import { rootPath } from "../../path_helpers";
import { sessionSelector } from "../../store/sessionReducer";
import Scaffold from "../app/Scaffold";
import CustomerLocked from "./CustomerLocked";
import RRoutes from "./Routes";

export default function AuthenticatedRoutes() {
  const session = useAppSelector(sessionSelector);
  const { pathname } = useLocation();

  const resetNotFound = useCallback(() => {
    document.location.href = "/";
  }, []);

  if (session.isNotFound) {
    return (
      <div className="TT-login-form-container">
        <p>
          Die von Ihnen angeforderte Seite konnte leider nicht gefunden werden.{" "}
          <Link to="/" onClick={resetNotFound}>
            zurück zur Hauptseite
          </Link>
        </p>

        <p>
          The page you were looking for could not be found.{" "}
          <Link to="/" onClick={resetNotFound}>
            back to main page
          </Link>
        </p>

        <p>
          La page que vous cherchiez n'a pas pu être trouvée.{" "}
          <Link to="/" onClick={resetNotFound}>
            Retour à la page d'accueil
          </Link>
        </p>
      </div>
    );
  }

  if (!session.isAuthenticated && !session.loading) {
    return <Navigate to="/login" />;
  }

  if (!session.currentCustomer || !session.currentProject) {
    return <Loading fullscreen absolute />;
  }

  if (session.currentCustomer && session.currentProject && pathname === "/") {
    return <Navigate to={rootPath(session)} />;
  }

  const isUserOfCustomer = !!session.currentUser && session.currentUser.customerId === session.currentCustomer.id;

  if (session.currentCustomer?.attrs.locked && isUserOfCustomer) {
    return <CustomerLocked />;
  }

  return (
    <React.Suspense fallback={<Loading fullscreen absolute />}>
      <Scaffold>
        <React.Suspense fallback={<Loading fullscreen absolute />}>
          <Routes>
            <Route path="/:customer/:project/*" element={<RRoutes />} />
          </Routes>
        </React.Suspense>
      </Scaffold>
    </React.Suspense>
  );
}
