import { useState } from "react";

import { GiArtificialIntelligence } from "react-icons/gi";

import { useAppSelector } from "../../hooks";
import { AI_ENABLED_USERS } from "../../hooks/useAi";
import { sessionSelector } from "../../store/sessionReducer";
import CorrectionsModal from "./CorrectionsModal";

export default function TextCorrectionsModal() {
  const { currentUser } = useAppSelector(sessionSelector);
  const [show, setShow] = useState(false);

  if (!AI_ENABLED_USERS.includes(currentUser.username)) {
    return null;
  }

  return (
    <>
      <button type="button" onClick={() => setShow(true)} title="AI-Textverbesserung">
        <GiArtificialIntelligence />
      </button>

      <CorrectionsModal show={show} onHide={() => setShow(false)} />
    </>
  );
}
