import React, { ReactNode } from "react";

import { Modal, ModalProps } from "react-bootstrap";

import Loading from "../containers/Loading";

const voidFun = () => {};

interface TTModalProps extends ModalProps {
  children?: ReactNode;
}

export default function TTModal({ onHide, children, ...props }: TTModalProps) {
  return (
    <Modal onHide={onHide || voidFun} {...props}>
      <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
    </Modal>
  );
}
