import React from "react";

import { Button } from "react-bootstrap";
import { MdSave } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const SaveButton: TTButtonProps = React.memo(({ children, ...props }) => (
  <Button variant="primary" {...props}>
    <MdSave /> {children}
  </Button>
));

export default SaveButton;
