import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { startOfMonth } from "date-fns";

import { RootState } from "../../store";
import type { InventoryItemDeploymentInfo, InventoryItemStatus, Nullable } from "../../types";

type InventoryReducerType = {
  search: string;
  itemTypeId: Nullable<string>;
  deploymentInfo: Nullable<InventoryItemDeploymentInfo>;
  status: Nullable<InventoryItemStatus>;
  attrFilters: { [key: string]: Nullable<string | boolean> };
  month: Date;
  osName: Nullable<string>;
  osVersion: Nullable<string>;
};

const initialState: InventoryReducerType = {
  search: "",
  itemTypeId: null,
  deploymentInfo: null,
  status: null,
  attrFilters: {},
  month: startOfMonth(new Date()),
  osName: null,
  osVersion: null,
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },

    setItemTypeId(state, { payload }: PayloadAction<Nullable<string>>) {
      state.itemTypeId = payload;
    },

    setDeploymentInfo(state, { payload }: PayloadAction<Nullable<InventoryItemDeploymentInfo>>) {
      state.deploymentInfo = payload;
    },

    setItemStatus(state, { payload }: PayloadAction<Nullable<InventoryItemStatus>>) {
      state.status = payload;
    },

    setAttrFilter(state, { payload: [name, value] }: PayloadAction<[string, Nullable<string | boolean>]>) {
      if (value === null || value === undefined) {
        delete state.attrFilters[name];
      } else {
        state.attrFilters[name] = value;
      }
    },

    setMonth(state, { payload }: PayloadAction<Date>) {
      state.month = payload;
    },

    setOsName(state, { payload }: PayloadAction<Nullable<string>>) {
      state.osName = payload;
    },

    setOsVersion(state, { payload }: PayloadAction<Nullable<string>>) {
      state.osVersion = payload;
    },

    resetFilters(state) {
      state.search = "";
      state.month = initialState.month;
      state.itemTypeId = null;
      state.attrFilters = {};
      state.osName = null;
      state.osVersion = null;
      state.deploymentInfo = null;
    },
  },
});

export const {
  setSearch,
  setItemTypeId,
  setDeploymentInfo,
  setItemStatus,
  setAttrFilter,
  setMonth,
  resetFilters,
  setOsName,
  setOsVersion,
} = inventorySlice.actions;
export const inventorySelector = (state: RootState) => state.inventory;
export default inventorySlice.reducer;
