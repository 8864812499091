import React, { useCallback, useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import clsx from "clsx";

import { isRoot, userFeatureEnabled } from "../../abilities/helpers";
import { callDoesDisappear, dispatchCallEvent, dispatchIncomingCall } from "../../actions/acdActions";
import { setCurrentCustomerAndProject } from "../../actions/sessionActions";
import { CUSTOMER_GET_QUERY } from "../../api/customers";
import { PROJECT_GET_QUERY } from "../../api/projects";
import Loading from "../../containers/Loading";
import { ReactLazyLoading } from "../../containers/ReactLoading";
import ResponsiveComponent from "../../containers/ResponsiveComponent";
import SubSubNav from "../../containers/SubSubNav";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../store/sessionReducer";
import type { CustomerDataInterface, ProjectDataInterface } from "../../types";
import CallHistoryEntry from "../acd/CallHistoryEntry";
import OverdueDocuments from "../documents/OverdueDocuments";
import FlashMessagesList from "../flash";
import BottomBar from "./BottomBar";
import FlashNews from "./FlashNews";
import DesktopHeader from "./Header/DekstopHeader";
import MobileHeader from "./Header/MobileHeader";
import PasswordResetModal from "./PasswordResetModal";
import ProjectNews from "./ProjectNews";
import TaskNotificationModal from "./TaskNotificationModal";
import ToastNotifications from "./ToastNotifications";
import Breadcrumb from "./TTBreadcrumb";

const IS_PROD = process.env.NODE_ENV === "production";

const Sidebar = ReactLazyLoading(() => import("./Sidebar"));

type PropsType = {
  children?: React.ReactNode;
};

const Scaffold: React.FunctionComponent<PropsType> = ({ children }) => {
  const { currentUser } = useAppSelector(sessionSelector);

  const { currentCustomer, currentProject } = useAppSelector(sessionSelector);
  const dispatch = useAppDispatch();

  const [loadProject] = useLazyQuery<ProjectDataInterface>(PROJECT_GET_QUERY, { fetchPolicy: "no-cache" });
  const [loadCustomer] = useLazyQuery<CustomerDataInterface>(CUSTOMER_GET_QUERY, { fetchPolicy: "no-cache" });

  useEffect(() => {
    const handler = (ev: StorageEvent) => {
      if (ev.key === "su_token" && ev.newValue !== null) {
        document.location.href = "/";
      }
    };

    window.addEventListener("storage", handler);

    return () => {
      window.removeEventListener("storage", handler);
    };
  });

  const projectHandler = useCallback(
    async (ev: CustomEvent<string>) => {
      if (currentProject.id === ev.detail) {
        const { data } = await loadProject({ variables: { customerId: currentCustomer.id, projectId: ev.detail } });

        if (data?.project) {
          dispatch(setCurrentCustomerAndProject(currentCustomer, data.project));
        }
      }
    },
    [loadProject, currentProject.id, currentCustomer, dispatch],
  );

  const customerHandler = useCallback(
    async (ev: CustomEvent<string>) => {
      if (currentCustomer.id === ev.detail) {
        const { data } = await loadCustomer({ variables: { customerId: ev.detail } });

        if (data?.customer) {
          dispatch(setCurrentCustomerAndProject(data.customer, currentProject));
        }
      }
    },
    [loadCustomer, currentProject, currentCustomer, dispatch],
  );

  useEffect(() => {
    document.addEventListener("tt:projectEdited", projectHandler);
    document.addEventListener("tt:customerEdited", customerHandler);

    return () => {
      document.removeEventListener("tt:projectEdited", projectHandler);
      document.removeEventListener("tt:customerEdited", customerHandler);
    };
  });

  async function triggerCall() {
    if (!IS_PROD) {
      const { INIT_CALL: callEvents, INCOMING_CALL: incoming } = await import("../../example_call");
      dispatch(dispatchIncomingCall(incoming));
      callEvents.forEach((ev) => dispatch(dispatchCallEvent(ev)));
    }
  }

  async function triggerCallEnd() {
    const { END_CALL: callEvents, CALL_DISAPPEARS: disappearing } = await import("../../example_call");
    callEvents.forEach((ev) => dispatch(dispatchCallEvent(ev)));
    dispatch(callDoesDisappear(disappearing));
  }

  return (
    <>
      <ResponsiveComponent desktopComponent={<DesktopHeader />} mobileComponent={<MobileHeader />} />

      <main
        className={clsx("container-fluid", {
          "TT-feature-redaction": process.env.REACT_APP_REDACT_PERSONAL_DATA === "true",
        })}
      >
        <Sidebar />

        <PasswordResetModal />

        <div className="TT-main-container">
          <div className="TT-nav-container">
            <ResponsiveComponent desktopComponent={<Breadcrumb />} mobileComponent={null} />

            <React.Suspense fallback={<Loading />}>
              <SubSubNav />
            </React.Suspense>

            <ToastNotifications />
            <ProjectNews />
          </div>

          <FlashMessagesList />

          <TaskNotificationModal />

          {currentUser.customerId === "1" && <FlashNews />}
          {children}
        </div>
      </main>

      {currentUser.attrs.e164 && <CallHistoryEntry />}
      {userFeatureEnabled(currentUser, "documents") && !isRoot(currentUser) && <OverdueDocuments />}

      <BottomBar />

      {!IS_PROD && (
        <p id="TT-debug-menu">
          <button onClick={triggerCall}>Call</button>
          <button onClick={triggerCallEnd}>End Call</button>
        </p>
      )}
    </>
  );
};

export default Scaffold;
