import { useEffect } from "react";

import type { Nilable } from "../types";

const INFIX = process.env.NODE_ENV === "production" ? "" : " – DEV ";

export default function useTitle(title: Nilable<String>) {
  useEffect(() => {
    if (title) {
      document.title = title + INFIX + (title.match(/Termitool\s*$/) ? "" : " – Termitool");
    }
  }, [title]);
}
