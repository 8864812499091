import { gql } from "@apollo/client";

import { EMPLOYEE_FRAGMENT } from "./employees";
import { USER_FRAGMENT } from "./users";

export const PERSONAL_FILE_FRAGMENT = gql`
  fragment PersonalFileFragment on PersonalFile {
    id
    file
    mimeType
    description
    userId
    read

    personalFileTypeId
    fileType {
      id
      name
      required
      category
    }

    creator {
      ...UserFragment
    }

    editor {
      ...UserFragment
    }

    pubDate
    insertedAt
    updatedAt
  }
  ${USER_FRAGMENT}
`;

export const PERSONAL_FILES_QUERY = gql`
  query personalFiles($customerId: ID!, $filters: PersonalFileListFilters) {
    personalFiles(customerId: $customerId, filters: $filters) {
      ...PersonalFileFragment
    }
  }
  ${PERSONAL_FILE_FRAGMENT}
`;

export const PERSONAL_FILE_QUERY = gql`
  query personalFile($customerId: ID!, $id: ID!) {
    personalFile(customerId: $customerId, id: $id) {
      ...PersonalFileFragment
      user {
        ...UserFragment
      }
    }
  }
  ${PERSONAL_FILE_FRAGMENT}
`;

export const PERSONAL_FILE_MUTATION = gql`
  mutation mutatePersonalFile($customerId: ID!, $id: ID, $values: PersonalFileInput!) {
    mutatePersonalFile(customerId: $customerId, id: $id, values: $values) {
      ...PersonalFileFragment
    }
  }
  ${PERSONAL_FILE_FRAGMENT}
`;

export const PERSONAL_FILE_MARK_READ_MUTATION = gql`
  mutation markPersonalFileRead($customerId: ID!, $id: ID!) {
    markPersonalFileRead(customerId: $customerId, id: $id) {
      ...PersonalFileFragment
    }
  }
  ${PERSONAL_FILE_FRAGMENT}
`;

export const DELETE_PERSONAL_FILE_MUTATION = gql`
  mutation deletePersonalFile($customerId: ID!, $id: ID!) {
    deletePersonalFile(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const SEND_PERSONAL_FILE_CHALLENGE_MUTATION = gql`
  mutation sendPersonalFileChallenge($id: ID!) {
    sendPersonalFileChallenge(id: $id)
  }
`;

export const VERIFY_PERSONAL_FILE_CHALLENGE_MUTATION = gql`
  mutation verifyPersonalFileChallenge($id: ID!, $response: String!) {
    verifyPersonalFileChallenge(id: $id, response: $response)
  }
`;

export const SEND_PERSONAL_FILE_MUTATION = gql`
  mutation sendPersonalFile($customerId: ID!, $id: ID!) {
    sendPersonalFile(customerId: $customerId, id: $id)
  }
`;

export const FILE_TYPES_LIST_QUERY = gql`
  query personalFileTypes($customerId: ID!, $filters: PersonalFileTypeListFilters) {
    personalFileTypes(customerId: $customerId, filters: $filters) {
      id
      name
      required
      category
      insertedAt
      updatedAt
    }
  }
`;

export const FILE_TYPES_COUNT_QUERY = gql`
  query countPersonalFileTypes($customerId: ID!, $filters: PersonalFileTypeListFilters) {
    countPersonalFileTypes(customerId: $customerId, filters: $filters)
  }
`;

export const FILET_TYPE_GET_QUERY = gql`
  query personalFileType($customerId: ID!, $id: ID!) {
    personalFileType(customerId: $customerId, id: $id) {
      id
      name
      required
      category
      insertedAt
      updatedAt
    }
  }
`;

export const FILE_TYPE_MUTATION = gql`
  mutation mutatePersonalFileType($customerId: ID!, $id: ID, $values: PersonalFileTypeInput!) {
    mutatePersonalFileType(customerId: $customerId, id: $id, values: $values) {
      id
      name
      required
      category
      insertedAt
      updatedAt
    }
  }
`;

export const FILE_TYPE_DELETE_MUTATION = gql`
  mutation deletePersonalFileType($customerId: ID!, $id: ID!) {
    deletePersonalFileType(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const MISSING_PERSONAL_FILES_QUERY = gql`
  query missingPersonalFileTypes($customerId: ID!, $filters: MissingPersonalFileTypesFilters) {
    missingPersonalFileTypes(customerId: $customerId, filters: $filters) {
      employee {
        ...EmployeeFragment
      }

      fileTypes {
        id
        name
      }
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;

export const COUNT_MISSING_PERSONAL_FILES_QUERY = gql`
  query countMissingPersonalFileTypes($customerId: ID!, $filters: MissingPersonalFileTypesFilters) {
    countMissingPersonalFileTypes(customerId: $customerId, filters: $filters)
  }
`;
