import { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import * as Sentry from "@sentry/react";

import type { Nilable } from "./types";

export const GRAPHQL_URI = process.env.NODE_ENV === "production" ? "/graphql" : "http://localhost:4001/graphql";
export const GRAPHQL_URI_RS = process.env.NODE_ENV === "production" ? "/graphql-rs" : "http://localhost:8000/graphql";
export const GRAPHQL_URI_WS_RS =
  process.env.NODE_ENV === "production" ? "wss://termitool.de/ws-rs" : "ws://localhost:8000/ws";
export const GRAPHQL_ACD_URI =
  process.env.NODE_ENV === "production" ? "https://acd.termitel.de/graphql" : "http://localhost:4000/graphql";

export const SOCKET_PATH =
  process.env.NODE_ENV === "development" ? `ws://${document.location.hostname}:4001/socket` : "/socket";

export const ACD_SOCKET_PATH =
  process.env.NODE_ENV === "development"
    ? `ws://${document.location.hostname}:4000/socket`
    : "wss://acd.termitel.de/socket";

export const authLink = (getToken: () => Nilable<string>) =>
  setContext((_, { headers }) => {
    const token = getToken();

    if (token) {
      headers = {
        ...headers,
        authorization: token ? `Bearer ${token}` : undefined,
      };
    }

    return { headers };
  });

export const sentryLink = new ApolloLink((operation, forward) => {
  Sentry.addBreadcrumb({
    category: "graphql",
    message: `GraphQL ${operation.operationName}`,
    level: "info",
    data: {
      operationName: operation.operationName,
      variables: JSON.stringify(operation.variables),
    },
  });

  return forward(operation);
});
