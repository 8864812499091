import { getAuthorizationToken } from "../authorization_token";
import * as acd from "./acd";
import * as calendar from "./calendar";
import * as contactGroups from "./contacts/contact_groups";
import * as contacts from "./contacts/contacts";
import * as customerContacts from "./customerContacts";
import * as customers from "./customers";
import * as feastdays from "./feastdays";
import * as histories from "./histories";
import * as profiles from "./profiles";
import * as projects from "./projects";
import * as session from "./session";
import * as teams from "./teams";
import * as todos from "./todos";
import * as users from "./users";

const fetchOptions = (): RequestInit => ({
  method: "get",
  cache: "no-cache",
  credentials: "same-origin",
  referrer: "no-referrer",
  headers: { Authorization: "Bearer " + getAuthorizationToken() },
});

export {
  session,
  customers,
  users,
  projects,
  todos,
  customerContacts,
  profiles,
  teams,
  feastdays,
  contacts,
  contactGroups,
  histories,
  acd,
  calendar,
  fetchOptions,
};
