import React from "react";

import { Button } from "react-bootstrap";
import { MdAddCircle } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const AddButton: TTButtonProps = React.memo(({ children, ...props }) => {
  return (
    <Button variant="secondary" {...props}>
      <MdAddCircle /> {children}
    </Button>
  );
});

export default AddButton;
