import { RS_API_URI } from "../api/session";
import type { SessionInterface } from "../store/sessionReducer";
import type { FileExchangeFile } from "../types";
import { sessionPrefix } from "./helpers";

export const fileExchangePath = (session: SessionInterface) => `${sessionPrefix(session)}/file-exchange`;
export const showFileExchangeFilePath = (session: SessionInterface, file: FileExchangeFile) =>
  `${sessionPrefix(session)}/file-exchange/${file.id}`;
export const editFileExchangeFilePath = (session: SessionInterface, file: FileExchangeFile) =>
  `${sessionPrefix(session)}/file-exchange/${file.id}/edit`;
export const newFileExchangeFilePath = (session: SessionInterface) => `${sessionPrefix(session)}/file-exchange/new`;

export const fileExchangeDownloadPath = (file: FileExchangeFile) => `${RS_API_URI}/file-exchange/${file.id}`;
