import { gql } from "@apollo/client";

export const NEWS_POST_FRAGMENT = gql`
  fragment CompanyNewsFragment on CompanyNews {
    id
    content
    validUntil
    customerId
    insertedAt
    updatedAt

    image {
      thumb
      medium
      original
    }
  }
`;

export const LAST_NEWS_POST_QUERY = gql`
  query lastCompanyNewsPost($customerId: ID!) {
    lastCompanyNewsPost(customerId: $customerId) {
      ...CompanyNewsFragment
    }
  }
  ${NEWS_POST_FRAGMENT}
`;

export const NEWS_POSTS_QUERY = gql`
  query companyNews($customerId: ID!, $filters: CompanyNewsListFilters) {
    companyNews(customerId: $customerId, filters: $filters) {
      ...CompanyNewsFragment
    }
  }
  ${NEWS_POST_FRAGMENT}
`;

export const NEWS_POST_MUTATION = gql`
  mutation mutateCompanyNews($customerId: ID!, $id: ID, $values: CompanyNewsInput!) {
    mutateCompanyNews(customerId: $customerId, id: $id, values: $values) {
      ...CompanyNewsFragment
    }
  }
  ${NEWS_POST_FRAGMENT}
`;

export const DELETE_NEWS_POST_MUTATION = gql`
  mutation deleteCompanyNews($customerId: ID!, $id: ID!) {
    deleteCompanyNews(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const NEWS_POST_QUERY = gql`
  query companyNewsPost($customerId: ID!, $id: ID) {
    companyNewsPost(customerId: $customerId, id: $id) {
      ...CompanyNewsFragment
    }
  }
  ${NEWS_POST_FRAGMENT}
`;

export const COUNT_NEWS_POSTS_QUERY = gql`
  query countCompanyNews($customerId: ID!) {
    countCompanyNews(customerId: $customerId)
  }
`;
