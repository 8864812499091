import Cookies from "js-cookie";

import store from "./store";
import { setAuthorizationToken as setAuthTokenReducer } from "./store/sessionReducer";
import type { Nilable } from "./types";

export function setAuthorizationToken(token: string) {
  localStorage.setItem("jwtToken", token);
  store.dispatch(setAuthTokenReducer(token));
}

export function removeAuthorizationToken() {
  if (process.env.NODE_ENV !== "development") {
    localStorage.removeItem("jwtToken");
    store.dispatch(setAuthTokenReducer(null));
  }
}

export function getAuthorizationToken(): Nilable<string> {
  return localStorage.jwtToken;
}

export function setSuToken(token = getAuthorizationToken()) {
  if (token) {
    localStorage.setItem("su_token", token);
  }
}

export function getSuToken(): Nilable<string> {
  return localStorage.su_token;
}

export function removeSuToken() {
  localStorage.removeItem("su_token");
}

export function setAuthorizationCookie(token: string) {
  Cookies.set("_jwt", token, {
    expires: 30,
    sameSite: "strict",
    secure: document.location.protocol === "https:",
    path: "/",
    domain: document.location.hostname,
  });
}
