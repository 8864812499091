import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../store";

type UsersReducerType = {
  filter: string;
};

const initialState: UsersReducerType = {
  filter: "",
};

const mailTemplatesSlice = createSlice({
  name: "admin/mail-templates",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<string>) {
      state.filter = action.payload;
    },
    resetFilters: (_state) => initialState,
  },
});

export const { setFilter, resetFilters } = mailTemplatesSlice.actions;
export const adminMailTemplatesSelector = (state: RootState) => state.admin.mailTemplates;
export default mailTemplatesSlice.reducer;
