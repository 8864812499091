import type { CompanyNewstImageType } from "../types";

type PropsType = {
  type: "original" | "medium" | "thumb";
  image: CompanyNewstImageType;
  alt?: string;
};

export const NewsImage = ({ type, image, alt }: PropsType) => (
  <img src={image[type]} alt={alt || ""} className={`TT-news-jumbotron-image rounded`} />
);
