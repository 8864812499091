import React from "react";

import { Button } from "react-bootstrap";
import { MdCloudDownload } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const ExportButton: TTButtonProps = React.memo(({ children, ...props }) => (
  <Button variant="primary" {...props}>
    <MdCloudDownload /> {children}
  </Button>
));

export default ExportButton;
