import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../store";

type UsersReducerType = {
  filter: string;
};

const initialState: UsersReducerType = {
  filter: "",
};

const mailAttachmentsSlice = createSlice({
  name: "admin/mail-attachments",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<string>) {
      state.filter = action.payload;
    },
    resetFilters: (_state) => initialState,
  },
});

export const { setFilter, resetFilters } = mailAttachmentsSlice.actions;
export const adminMailAttachmentsSelector = (state: RootState) => state.admin.mailAttachments;
export default mailAttachmentsSlice.reducer;
