import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import type { AttributeFilterType, CustomerFeedbackStateType, Nilable, Nullable } from "../../types";

export interface CustomerFeedbackReducerInterface {
  state: CustomerFeedbackStateType[];
  topics: Nullable<string>[];
  starts: Nullable<Date>;
  stops: Nullable<Date>;
  filterAttrs: Record<string, AttributeFilterType>;
}

const initialState: CustomerFeedbackReducerInterface = {
  state: ["NONE", "JUSTIFIED", "UNJUSTIFIED"],
  topics: [],
  starts: null,
  stops: null,
  filterAttrs: {},
};

const customerFeedbackSlice = createSlice({
  name: "customerFeedback",
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<CustomerFeedbackStateType[]>) {
      state.state = action.payload;
    },
    setTopics(state, action: PayloadAction<Nullable<string>[]>) {
      state.topics = action.payload;
    },
    setStarts(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.starts = payload;
    },
    setStops(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.stops = payload;
    },
    setAttributeFilter(state, action: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (!action.payload.value) {
        delete state.filterAttrs[action.payload.name];
      } else {
        state.filterAttrs[action.payload.name] = action.payload.value;
      }
    },

    resetFilters(state) {
      state.state = ["NONE", "JUSTIFIED", "UNJUSTIFIED"];
      state.topics = [];
      state.starts = null;
      state.stops = null;
      state.filterAttrs = {};
    },
  },
});

export const { setStatus, setTopics, setStarts, setStops, setAttributeFilter, resetFilters } =
  customerFeedbackSlice.actions;
export const customerFeedbackSelector = (state: RootState) => state.customerFeedback;
export default customerFeedbackSlice.reducer;
