import { RS_API_URI } from "../api/session";
import { SessionInterface } from "../store/sessionReducer";
import type { MailAttachmentInterface, MailInterface, MailThreadInterface, QueryStringType } from "../types";
import { queryString, sessionPrefix } from "./helpers";

export const mailsPath = (session: SessionInterface) => `${sessionPrefix(session)}/mails`;
export const newMailPath = (session: SessionInterface) => `${sessionPrefix(session)}/mails/new`;

export const replyMailPath = (session: SessionInterface, mail: MailInterface, qs: QueryStringType = null) =>
  `${sessionPrefix(session)}/mails/${mail.id}/reply${queryString(qs)}`;
export const forwardMailPath = (session: SessionInterface, mail: MailInterface, qs: QueryStringType = null) =>
  `${sessionPrefix(session)}/mails/${mail.id}/forward${queryString(qs)}`;
export const editMailPath = (session: SessionInterface, mail: MailInterface) =>
  `${sessionPrefix(session)}/mails/${mail.id}/edit`;

export const showMailAttachmentPath = (_session: SessionInterface, attachment: MailAttachmentInterface) =>
  `${RS_API_URI}/mails/attachments/${attachment.id}`;

export function showMailPath(session: SessionInterface, thread: MailThreadInterface, mail?: MailInterface): string;
export function showMailPath(session: SessionInterface, thread: undefined, mail: MailInterface): string;

export function showMailPath(session: SessionInterface, thread: MailThreadInterface | undefined, mail?: MailInterface) {
  let path = `${sessionPrefix(session)}/mails/${thread?.id || mail?.threadId}`;

  if (mail) {
    path += `#mail-${mail.id}`;
  }

  return path;
}
