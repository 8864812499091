import { useEffect, useState } from "react";

import { Button, Modal, ProgressBar } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { MdAccessAlarm, MdLoop } from "react-icons/md";

import TTModal from "../../../TTModal";

type PropsType = {
  onHide: () => void;
  show: boolean;
};

export default function ReloadModal({ onHide, show }: PropsType) {
  const [reloadTimeout, setReloadTimeout] = useState<number | null>(null);
  const { t } = useTranslation(["bottomBar"]);

  useEffect(() => {
    if (show) {
      const tm = window.setInterval(() => {
        setReloadTimeout((tm) => (tm || 0) + 1);
      }, 1000);

      return () => {
        setReloadTimeout(null);
        window.clearTimeout(tm);
      };
    }
  }, [show]);

  // technically we don't need this `useEffect()`, but it is considered harmful to not wrap side effects in
  // a `useEffect()`
  useEffect(() => {
    if (reloadTimeout && reloadTimeout >= 60) {
      localStorage.setItem("automatic_reload_after_version_update", "1");
      window.location.reload();
    }
  }, [reloadTimeout]);

  return (
    <TTModal onHide={onHide} show={show} size="lg">
      <Modal.Header>
        <Modal.Title>{t("bottomBar:automatic_reload.update_available")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ProgressBar now={reloadTimeout || 0} max={60} label={`${reloadTimeout} s`} className="mb-3" />

        <Trans parent="p" t={t} i18nKey="bottomBar:automatic_reload.happening">
          Es gibt ein Update des Termitools. Bitte laden Sie sobald wie möglich neu! Wenn Sie nichts tun, wird in{" "}
          {{ seconds: 60 - (reloadTimeout || 0) }} s automatisch neu geladen.
        </Trans>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => window.location.reload()}>
          <MdLoop /> {t("bottomBar:automatic_reload.reload_now")}
        </Button>

        <Button variant="secondary" onClick={onHide}>
          <MdAccessAlarm /> {t("bottomBar:automatic_reload.remember_later")}
        </Button>
      </Modal.Footer>
    </TTModal>
  );
}
