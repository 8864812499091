import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { endOfDay, startOfDay } from "date-fns";

import { RootState } from "../../store";
import type { Nullable, RangeType, TeamInterface } from "../../types";

export type DashboardReducerType = {
  activeTab: RangeType;
  dateStart: Date;
  dateStop: Date;
  team: null | TeamInterface;
};

type DateTabActionType = { tab: RangeType; start: Nullable<Date> | undefined; stop: Nullable<Date> | undefined };
type TeamTabActionType = Nullable<TeamInterface>;

const initialState: DashboardReducerType = {
  activeTab: "today",
  dateStart: startOfDay(new Date()),
  dateStop: endOfDay(new Date()),
  team: null,
};

const contactsSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<DateTabActionType>) {
      state.activeTab = action.payload.tab;

      if (action.payload.start && action.payload.stop) {
        state.dateStart = action.payload.start;
        state.dateStop = action.payload.stop;
      }
    },
    setTeam(state, action: PayloadAction<TeamTabActionType>) {
      state.team = action.payload;
    },
  },
});

export const { setActiveTab, setTeam } = contactsSlice.actions;
export const dashboardSelector = (state: RootState) => state.dashboard;
export default contactsSlice.reducer;
