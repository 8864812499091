import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";

import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdCall } from "react-icons/md";

import acdEndpointApollo from "../../acdEndpointApollo";
import { DIAL_MUTATION } from "../../api/acd";
import TTFormGroup from "../../containers/TTFormGroup";
import handleError from "../../handleError";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { acdSelector, setCallContact } from "../../store/acdReducer";
import { sessionSelector } from "../../store/sessionReducer";
import type { ProjectInterface, UserInterface } from "../../types";
import { addDangerFlash } from "../flash/flashReducer";
import TTModal from "../TTModal";
import { dialerSelector, toggleDialer } from "./dialerReducer";

const noWithPrefix = (user: UserInterface, project: ProjectInterface, no: string): string => {
  const prefix = project.attrs?.project_user_prefixes?.find((p: { user_id: string }) => p.user_id === user.id);
  if (prefix) return prefix.prefix + no;
  if (project.attrs.project_prefix) return project.attrs.project_prefix + no;
  return "0" + no;
};

export default function TTDialer() {
  const [number, setNumber] = useState("");
  const dispatch = useAppDispatch();
  const { currentProject, currentUser } = useAppSelector(sessionSelector);
  const dialer = useAppSelector(dialerSelector);
  const { endpointToken } = useAppSelector(acdSelector);
  const { t } = useTranslation(["acd", "translation"]);

  const [dialMutation] = useMutation<boolean>(DIAL_MUTATION, { client: acdEndpointApollo });

  function toggle() {
    dispatch(toggleDialer());
  }

  function updateNumber(ev: React.ChangeEvent<HTMLInputElement>) {
    setNumber(ev.target.value);
  }

  async function dial() {
    let no = number;
    no = no.replace(/^\s*|\s*$/, "");
    no = no.replace(/^\+/, "00");

    if (no.match(/^(00(?!4|3)|0004)/)) {
      no = no.substring(1);
    }

    no = noWithPrefix(currentUser, currentProject, no);

    try {
      const ret = await dialMutation({ variables: { target: no } });

      dispatch(toggleDialer());
      if (ret) {
        dispatch(setCallContact(dialer.contact));
      } else {
        dispatch(addDangerFlash(t("translation:global.general_error")));
      }
    } catch (e) {
      dispatch(addDangerFlash(t("translation:global.general_error")));
      handleError(e);
    }
  }

  useEffect(() => {
    setNumber(dialer.no || "");
  }, [dialer.no]);

  useEffect(() => {
    if (dialer.showDialer) {
      setNumber(dialer.no || "");
    }
  }, [dialer.showDialer, dialer.no]);

  if (!endpointToken) {
    return null;
  }

  return (
    <>
      <button type="button" onClick={toggle} title={t("acd:dialer.dial")}>
        <MdCall />
      </button>

      <TTModal show={dialer.showDialer} onHide={toggle} size="lg" autoFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("acd:dialer.dial")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TTFormGroup>
            <Form.Label>{t("acd:dialer.phone_no")}</Form.Label>
            <Form.Control type="tel" onChange={updateNumber} value={number} autoFocus />
            <Form.Text muted>{t("acd:dialer.explanation")}</Form.Text>
          </TTFormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={dial}>
            {t("acd:dialer.dial")}
          </Button>

          <Button variant="secondary" onClick={toggle}>
            {t("translation:global.cancel")}
          </Button>
        </Modal.Footer>
      </TTModal>
    </>
  );
}
