import { RS_API_URI } from "../api/session";
import { SessionInterface } from "../store/sessionReducer";
import type { JobResultInterface, QueryStringType } from "../types";
import { queryString, sessionPrefix } from "./helpers";

export const jobsPath = (session: SessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/jobs${queryString(args)}`;
export const showJobPath = (session: SessionInterface, job: JobResultInterface, args = {}) =>
  `${sessionPrefix(session)}/jobs/${job.id}${queryString(args)}`;

export const jobDownloadPath = (job: JobResultInterface) => `${RS_API_URI}/jobs/${job.id}`;
