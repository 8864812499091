import clsx from "clsx";
import { getIn, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Select, { ActionMeta, OnChangeValue } from "react-select";

import { fieldInvalid, fieldValid, itemByValue } from "../new_utils";
import type { FormikReactSelectPropsInterface, OptionProp } from "../types";

function FormikReactSelect(props: FormikReactSelectPropsInterface) {
  const {
    options,
    name,
    id,
    className,
    placeholder,
    validate = true,
    isMulti = false,
    isDisabled = false,
    isClearable = false,
    onChange,
    isValid,
    isInvalid,
  } = props;
  const formik = useFormikContext();
  const { t } = useTranslation(["translation"]);

  function handleChange(
    ev: OnChangeValue<OptionProp, false> | OnChangeValue<OptionProp, true>,
    opts: ActionMeta<OptionProp>,
  ) {
    let value;

    if (isMulti) {
      value = (ev || []).map((row: OptionProp) => row.value);
    } else {
      value = getIn(ev, "value");
    }

    formik.setFieldTouched(name, true, false);
    formik.setFieldValue(name, value, true);

    if (onChange) {
      onChange(ev, opts);
    }
  }

  function handleBlur() {
    formik.setFieldTouched(name, true, true);
  }

  return (
    <Select
      placeholder={placeholder || t("translation:global.choose…")}
      classNamePrefix="TT-formik-react-select"
      className={clsx("TT-formik-react-select", className, {
        "is-invalid": isInvalid ?? (validate && fieldInvalid(formik.errors, formik.touched, name)),
        "is-valid": isValid ?? (validate && fieldValid(formik.errors, formik.touched, name)),
      })}
      name={name}
      id={id}
      options={options}
      value={itemByValue(getIn(formik.values, name), options, isMulti) || null}
      onBlur={handleBlur}
      onChange={handleChange}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
    />
  );
}

export default FormikReactSelect;
