import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import type { FileExchangeStatus, Nullable, UserInterface } from "../../types";

export type FileExchangeReducerType = {
  status: Nullable<FileExchangeStatus>;
  owner: Nullable<UserInterface>;
  search: Nullable<string>;
};

const initialState: FileExchangeReducerType = {
  status: "PENDING",
  owner: null,
  search: null,
};

const fileExchangeSlice = createSlice({
  name: "fileExchange",
  initialState,
  reducers: {
    setStatus(state, { payload }: PayloadAction<Nullable<FileExchangeStatus>>) {
      state.status = payload;
    },

    setOwner(state, { payload }: PayloadAction<Nullable<UserInterface>>) {
      state.owner = payload;
    },

    setSearch(state, { payload }: PayloadAction<Nullable<string>>) {
      state.search = payload;
    },

    resetFilters(state) {
      state.status = null;
      state.owner = null;
      state.search = null;
    },
  },
});

export const { setStatus, setOwner, setSearch, resetFilters } = fileExchangeSlice.actions;
export const fileExchangeSelector = (state: RootState) => state.fileExchange;
export default fileExchangeSlice.reducer;
