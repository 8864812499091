import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import { authLink, GRAPHQL_ACD_URI } from "../graphqlUtils";
import store from "../store";

const client = new ApolloClient({
  link: authLink(() => store.getState().acd.endpointToken).concat(createHttpLink({ uri: GRAPHQL_ACD_URI })),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default client;
