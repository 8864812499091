import { gql } from "@apollo/client";

export const PROJECT_NEWS_FRAGMENT = gql`
  fragment ProjectNewsFragment on ProjectNews {
    id
    subject
    document
    active
    customerId
    projectId
    insertedAt
    updatedAt
  }
`;

export const PROJECT_NEWS_LIST_QUERY = gql`
  query projectNews($customerId: ID!, $projectId: ID!, $filters: ListProjectNewsFilters) {
    projectNews(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...ProjectNewsFragment
    }
  }
  ${PROJECT_NEWS_FRAGMENT}
`;

export const PROJECT_NEWS_COUNT_QUERY = gql`
  query countProjectNews($customerId: ID!, $projectId: ID!, $filters: ListProjectNewsFilters) {
    countProjectNews(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const PROJECT_NEWS_GET_QUERY = gql`
  query projectNewsPost($customerId: ID!, $projectId: ID!, $id: ID!) {
    projectNewsPost(customerId: $customerId, projectId: $projectId, id: $id) {
      ...ProjectNewsFragment
    }
  }
  ${PROJECT_NEWS_FRAGMENT}
`;

export const PROJECT_NEWS_DELETE_MUTATION = gql`
  mutation deleteProjectNews($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteProjectNews(customerId: $customerId, projectId: $projectId, id: $id) {
      ...ProjectNewsFragment
    }
  }
  ${PROJECT_NEWS_FRAGMENT}
`;

export const PROJECT_NEWS_MUTATION = gql`
  mutation mutateProjectNews($customerId: ID!, $projectId: ID!, $id: ID, $values: ProjectNewsInput!) {
    mutateProjectNews(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...ProjectNewsFragment
    }
  }
  ${PROJECT_NEWS_FRAGMENT}
`;

export const PROJECT_NEWS_ATTACHMENT_MUTATION = gql`
  mutation mutateProjectNewsAttachment(
    $customerId: ID!
    $projectId: ID!
    $newsId: ID!
    $values: ProjectNewsAttachmentInput!
  ) {
    mutateProjectNewsAttachment(customerId: $customerId, projectId: $projectId, newsId: $newsId, values: $values) {
      id
      file
    }
  }
`;

export const HIDE_PROJECT_NEWS_MUTATION = gql`
  mutation hideProjectNews($customerId: ID!, $projectId: ID!, $id: ID!) {
    hideProjectNews(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      newsId
      userId
      insertedAt
    }
  }
`;
