import { SessionInterface } from "../store/sessionReducer";
import {
  RosterAttendanceTypeInterface,
  RosterGroupInterface,
  RosterStandardAttendanceInterface,
  RosterStandardDayInterface,
  RosterTaskInterface,
  UserInterface,
} from "../types";
import { sessionPrefix } from "./helpers";

export const rosterPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster`;

export const rosterAttendanceTypesPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/roster/attendance-types`;
export const newRosterAttendanceTypePath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/roster/attendance-types/new`;
export const editRosterAttendanceTypePath = (session: SessionInterface, type: RosterAttendanceTypeInterface) =>
  `${sessionPrefix(session)}/roster/attendance-types/${type.id}/edit`;

export const rosterTasksPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster/tasks`;
export const newRosterTaskPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster/tasks/new`;
export const editRosterTaskPath = (session: SessionInterface, task: RosterTaskInterface) =>
  `${sessionPrefix(session)}/roster/tasks/${task.id}/edit`;

export const rosterGroupsPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster/groups`;
export const newRosterGroupPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster/groups/new`;
export const editRosterGroupPath = (session: SessionInterface, group: RosterGroupInterface) =>
  `${sessionPrefix(session)}/roster/groups/${group.id}/edit`;

export const rosterStandardAttendancesPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/roster/standard-attendances`;
export const newRosterStandardAttendancePath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/roster/standard-attendances/new`;
export const editRosterStandardAttendancePath = (
  session: SessionInterface,
  attendance: RosterStandardAttendanceInterface,
) => `${sessionPrefix(session)}/roster/standard-attendances/${attendance.id}/edit`;

export const rosterStandardDaysPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster/standard-days`;
export const userRosterStandardDayPath = (session: SessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/roster/standard-days/${user.id}`;
export const editRosterStandardDayPath = (session: SessionInterface, day: RosterStandardDayInterface) =>
  `${sessionPrefix(session)}/roster/standard-days/${day.id}/edit`;

export const rosterAttendancePlanningPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/roster/attendance-planning`;

export const rosterDayPlanningPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster/day-planning`;

export const rosterDayPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster/day`;
export const rosterMonthPath = (session: SessionInterface) => `${sessionPrefix(session)}/roster/month`;
