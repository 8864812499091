import { useQuery } from "@apollo/client";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { DOCUMENT_QUERY } from "../../api/documents";
import Loading from "../../containers/Loading";
import { useAppSelector } from "../../hooks";
import useCustomerChange from "../../hooks/useCustomerChange";
import useI18nBreadcrumb from "../../hooks/useI18nBreadcrumb";
import useTitle from "../../hooks/useTitle";
import { documentsPath, showDocumentPath } from "../../path_helpers/document_paths";
import { sessionSelector } from "../../store/sessionReducer";
import { SessionInterface } from "../../store/sessionReducer";
import type { DocumentDataInterface, DocumentType } from "../../types";
import DocumentDetails from "./DocumentDetails";

const BREADCRUMB_IDENTIFIER = "documents/show";

const breadcrumbItem = (t: TFunction, session: SessionInterface, document?: DocumentType) => ({
  type: BREADCRUMB_IDENTIFIER,
  to: document ? showDocumentPath(session, document) : "",
  text: t("documents:show.title", { id: document?.id || "…", subject: document?.subject || "…" }),
  after: "documents",
});

type PropsType = {
  id?: string;
  cancelAction?: () => void;
  session?: SessionInterface;
};

export default function Show({ id: propsId, session: propsSession, cancelAction: cancelActionProp }: PropsType) {
  const session = useAppSelector(sessionSelector);
  const { t } = useTranslation(["documents"]);
  const currentSession = propsSession || session;
  const { currentCustomer, currentProject } = currentSession;
  const { id: paramsId } = useParams<"id">();
  const id = propsId || paramsId;
  const navigate = useNavigate();

  const defaultCancelAction = () => {
    navigate(documentsPath(session));
  };
  const cancelAction = cancelActionProp || defaultCancelAction;

  const variables = { customerId: currentCustomer.id, projectId: currentProject.id, id };
  const { data } = useQuery<DocumentDataInterface>(DOCUMENT_QUERY, { variables });

  useTitle(t("documents:show.title", { id: data?.document?.id || "…", subject: data?.document?.subject || "…" }));
  useI18nBreadcrumb(BREADCRUMB_IDENTIFIER, breadcrumbItem, data?.document, ["documents"]);
  useCustomerChange(documentsPath(session));

  if (!data?.document) return <Loading stretch />;

  return <DocumentDetails session={currentSession} document={data!.document} cancelAction={cancelAction} />;
}
