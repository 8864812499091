import { SessionInterface } from "../store/sessionReducer";
import { ReminderInterface } from "../types/reminders";
import { sessionPrefix } from "./helpers";

export const adminRemindersPath = (session: SessionInterface) => `${sessionPrefix(session)}/admin/reminders`;
export const newAdminReminderPath = (session: SessionInterface) => `${sessionPrefix(session)}/admin/reminders/new`;
export const editAdminReminderPath = (session: SessionInterface, reminder: ReminderInterface) =>
  `${sessionPrefix(session)}/admin/reminders/${reminder.id}/edit`;

export const reminderNotificationsPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/reminder-notifications`;
