import { CSSProperties } from "react";

import { RenderElementProps } from "slate-react";

import { Image } from "./Image";

export const Element = ({ attributes, children, element }: RenderElementProps) => {
  const style: CSSProperties = { textAlign: element.align || "left" };

  switch (element.type) {
    case "quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "heading-three":
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "image":
      return <Image attributes={attributes} element={element} children={children} />;
    case "link":
      return (
        <a href={element.url} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};
