import * as Sentry from "@sentry/react";

import store from "./store";

export function setContext() {
  const state = store.getState();

  Sentry.setUser({
    id: state.session?.currentUser?.id,
    username: state.session?.currentUser?.username,
    email: state.session?.currentUser?.email,
  });

  Sentry.setContext("currentCustomer", {
    id: state.session?.currentCustomer?.id,
    identifier: state.session?.currentCustomer?.identifier,
  });

  Sentry.setContext("currentProject", {
    id: state.session?.currentProject?.id,
    identifier: state.session?.currentProject?.identifier,
  });
}
