import { useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";
import { GiArtificialIntelligence } from "react-icons/gi";
import { MdContentCopy } from "react-icons/md";

import { CancelButton, DoneButton } from "../../containers/buttons";
import RecordButton from "../../containers/buttons/RecordButton";
import Spinner from "../../containers/Spinner";
import TTFormGroup from "../../containers/TTFormGroup";
import TTTextarea from "../../containers/TTTextarea";
import useAi from "../../hooks/useAi";
import useSpeechToText from "../../hooks/useSpeechToText";
import TTModal from "../TTModal";

type PropsType = {
  show: boolean;
  onHide: () => void;
};

export default function CorrectionsModal({ show, onHide }: PropsType) {
  const [text, setText] = useState("");

  const [suggestion, runningRequest, sendRequest, cancelRequest] = useAi();
  const { isRecording, startRecording, stopRecording } = useSpeechToText(setText);

  function handleChange(ev: React.ChangeEvent<HTMLTextAreaElement>) {
    setText(ev.target.value);
  }

  function copySuggestion() {
    navigator.clipboard.writeText(suggestion);
  }

  return (
    <TTModal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Text-Korrekturen</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TTFormGroup>
          <Form.Label htmlFor="text">Eingabe-Text</Form.Label>
          <TTTextarea onChange={handleChange} value={text} />
        </TTFormGroup>

        <TTFormGroup>
          <Form.Label htmlFor="suggestion">Textverbesserung</Form.Label>
          <TTTextarea readOnly value={suggestion} />
        </TTFormGroup>
      </Modal.Body>

      <Modal.Footer>
        {runningRequest && (
          <>
            <Spinner color="#000" />
            <CancelButton size="sm" onClick={cancelRequest}>
              abbrechen
            </CancelButton>
          </>
        )}

        {!runningRequest && (
          <RecordButton size="sm" isRecording={isRecording} onClick={isRecording ? stopRecording : startRecording} />
        )}

        <Button
          size="sm"
          variant="secondary"
          onClick={() => sendRequest(text)}
          disabled={runningRequest || text.length <= 5}
        >
          <GiArtificialIntelligence /> Verbesserungsvorschlag erzeugen
        </Button>

        <Button size="sm" variant="secondary" onClick={copySuggestion} disabled={runningRequest || !suggestion}>
          <MdContentCopy /> Vorschlag kopieren
        </Button>

        <DoneButton size="sm" onClick={onHide}>
          schließen
        </DoneButton>
      </Modal.Footer>
    </TTModal>
  );
}
