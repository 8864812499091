import React from "react";

import { Button } from "react-bootstrap";
import { MdCheck } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const SaveButton: TTButtonProps = React.memo(({ children, ...props }) => (
  <Button {...props}>
    <MdCheck /> {children}
  </Button>
));

export default SaveButton;
