import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";
import type { Nullable, SortDirType, TTDict } from "../types";

export type SortingReducerEntryType = {
  order: string;
  orderAttr?: Nullable<string>;
  direction: SortDirType;
};
export type SortingReducerType = TTDict<string, SortingReducerEntryType>;

const initialState: SortingReducerType = {
  "inventory/items": { order: "NAME", direction: "ASC" },
  "admin/projects": { order: "NAME", direction: "ASC" },
  "admin/users": { order: "NAME", direction: "ASC" },
  contacts: { order: "updated_at", orderAttr: null, direction: "DESC" },
  "contacts/calls": { order: "inserted_at", orderAttr: null, direction: "DESC" },
  "customer-contacts": { order: "INSERTED_AT", orderAttr: null, direction: "DESC" },
  todos: { order: "INSERTED_AT", orderAttr: null, direction: "DESC" },
  "calendar/feedback": { order: "INSERTED_AT", orderAttr: null, direction: "DESC" },
  "calendar/storno": { order: "INSERTED_AT", orderAttr: null, direction: "DESC" },
  "calendar/events": { order: "START_TIME", orderAttr: null, direction: "DESC" },
  "staff/worktimes": { order: "NAME", direction: "DESC" },
  "staff/vacation": { order: "INSERTED_AT", direction: "DESC" },
  knowledgebase: { order: "ID", direction: "DESC" },
  tickets: { order: "ID", direction: "DESC" },
  emails: { order: "RECEIVED_AT", direction: "DESC" },
  "project-news": { order: "INSERTED_AT", direction: "DESC" },
};

type SortingPayloadType = Omit<SortingReducerEntryType, "direction"> & { path: keyof typeof initialState };
type SortingWDirPayloadType = SortingReducerEntryType & { path: keyof typeof initialState };

const usersSlice = createSlice({
  name: "admin/users",
  initialState,
  reducers: {
    changeSorting(state, action: PayloadAction<SortingWDirPayloadType>) {
      state[action.payload.path] = { ...state[action.payload.path], ...action.payload };
    },
    sortButtonClicked(state, action: PayloadAction<SortingPayloadType>) {
      const currentOrder = state[action.payload.path];
      let direction = currentOrder?.direction;
      const isActive =
        currentOrder?.order === action.payload.order &&
        (!action.payload.orderAttr || action.payload.orderAttr === currentOrder.orderAttr);

      if (isActive) {
        direction = currentOrder.direction === "ASC" ? "DESC" : "ASC";
      } else {
        direction = "ASC";
      }

      state[action.payload.path] = { ...action.payload, direction };
    },
  },
});

export const { changeSorting, sortButtonClicked } = usersSlice.actions;
export const sortingSelector = (key: keyof SortingReducerType) => (state: RootState) =>
  state.sorting[key] || { direction: "ASC", order: "name" };
export default usersSlice.reducer;
