import { useQuery } from "@apollo/client";

import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CONTACT_GET_QUERY } from "../../../api/contacts/contacts";
import { useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import type { ContactDataInterface, ContactInterface, ContactQueryVarsInterface, TTDict } from "../../../types";

const SEVENIT_CONTACTS: TTDict<string, string> = {
  sevdesk: "4802964",
  service: "348202",
};

type PropsType = {
  contactChosen: (cnt: ContactInterface) => void;
};

const SevenItAdditionalButtons = ({ contactChosen }: PropsType) => {
  const session = useAppSelector(sessionSelector);
  const { t } = useTranslation(["acd"]);

  const { data } = useQuery<ContactDataInterface, ContactQueryVarsInterface>(CONTACT_GET_QUERY, {
    variables: {
      customerId: session.currentCustomer.id,
      projectId: session.currentProject.id,
      id: SEVENIT_CONTACTS[session.currentProject.identifier] || "",
    },
    skip: !SEVENIT_CONTACTS[session.currentProject.identifier],
  });

  const contact = data?.contact;

  if (!contact) return null;

  return (
    <Button variant="secondary" size="sm" onClick={() => contactChosen(contact)}>
      {t("acd:incoming_call.no_ident")}
    </Button>
  );
};

export const ADDITIONAL_BUTTONS = {
  "sevenit-gmbh": {
    sevdesk: SevenItAdditionalButtons,
    service: SevenItAdditionalButtons,
  },
};
