import { useState } from "react";

import { Col, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { CustomerLogo } from "../../../../containers/CustomerLogo";
import { useAppSelector } from "../../../../hooks";
import { sessionSelector } from "../../../../store/sessionReducer";
import { getSubnavItems } from "../../navigation";
import Switcher from "../../Switcher";
import MobileUserMenu from "./UserMenu";

export default function MobileHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const session = useAppSelector(sessionSelector);
  const { currentCustomer, currentProject, currentUser } = session;
  const { t } = useTranslation(["translation", "contacts"]);
  const location = useLocation();
  const { items, adminItems, hiddenItems } = getSubnavItems(t, session, location.pathname);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <Navbar color="light" expand="false" className="mobile-navbar">
      <Col xs={3}>
        <Navbar.Toggle
          onClick={toggle}
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label={t("translation:nav.show_nav")}
        />
      </Col>

      <Navbar.Offcanvas id="mobile-nav" placement="start" style={{ width: "70%" }} show={isOpen} onHide={toggle}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
            <CustomerLogo logo={currentCustomer.logo} type="thumb" /> {currentCustomer.name}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            {items
              .concat(adminItems)
              .concat(hiddenItems)
              .map((item, i) => (
                <Nav.Link onClick={toggle} as={Link} to={item.to} key={i}>
                  {item.icon && <item.icon />} {item.text}
                </Nav.Link>
              ))}
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>

      <Col xs={7} className="text-center">
        {currentCustomer && (
          <div className="navbar-brand">
            <Switcher currentCustomer={currentCustomer} currentProject={currentProject} />
          </div>
        )}
      </Col>

      <Col xs={2}>{currentUser && <MobileUserMenu />}</Col>
    </Navbar>
  );
}
