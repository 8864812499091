import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const EMPLOYEE_FRAGMENT = gql`
  fragment EmployeeFragment on Employee {
    id

    entering
    leaving
    active

    deleted

    iban
    bic
    taxId
    socialSecurityNumber

    standardBonus

    cpsNumber
    headset
    healthInsurance
    notes
    probationEnding
    gender
    nationality
    disability
    disabilityLevel
    taxLevel
    childTaxAllowance
    additionalJobs
    denomination

    languagesFluent
    languagesNative

    position
    levels

    children {
      id
      name
      birthdate
    }

    userId
    user {
      ...UserFragment
    }

    insertedAt
    updatedAt
  }
  ${USER_FRAGMENT}
`;

export const EMPLOYEE_LIST_QUERY = gql`
  query employees($customerId: ID!, $filters: EmployeesListFilters) {
    employees(customerId: $customerId, filters: $filters) {
      id

      entering
      leaving
      probationEnding
      active
      headset

      position
      levels

      userId
      user {
        ...UserFragment
      }

      insertedAt
      updatedAt
    }
  }
  ${USER_FRAGMENT}
`;

export const COUNT_EMPLOYEES_QUERY = gql`
  query countEmployees($customerId: ID!, $filters: EmployeesListFilters) {
    countEmployees(customerId: $customerId, filters: $filters)
  }
`;

export const EMPLOYEE_GET_QUERY = gql`
  query employee($customerId: ID!, $id: ID, $userId: ID) {
    employee(customerId: $customerId, id: $id, userId: $userId) {
      ...EmployeeFragment

      user {
        id
        email
        bio
        attrs

        contact {
          id
          customerId
          projectId

          infos {
            id
            itype
            value
            description
          }

          addresses {
            id
            address
            city
            zip
          }
        }
      }
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;

export const EMPLOYEE_MUTATION = gql`
  mutation mutateEmployee($customerId: ID!, $id: ID, $values: EmployeeInput!) {
    mutateEmployee(customerId: $customerId, id: $id, values: $values) {
      ...EmployeeFragment
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;

export const DELETE_EMPLOYEE_MUTATION = gql`
  mutation deleteEmployee($customerId: ID!, $id: ID!) {
    deleteEmployee(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const EMPLOYEE_INFO_MUTATION = gql`
  mutation mutateEmployeeInfo($customerId: ID!, $id: ID!, $infoId: ID, $values: ContactInfoInput!) {
    mutateEmployeeInfo(customerId: $customerId, id: $id, infoId: $infoId, values: $values) {
      id
    }
  }
`;

export const DELETE_EMPLOYEE_INFO_MUTATION = gql`
  mutation deleteEmployeeInfo($customerId: ID!, $id: ID!, $infoId: ID!) {
    deleteEmployeeInfo(customerId: $customerId, id: $id, infoId: $infoId) {
      id
    }
  }
`;

export const EMPLOYEE_ADDRESS_MUTATION = gql`
  mutation mutateEmployeeAddress($customerId: ID!, $id: ID!, $addressId: ID, $values: ContactAddressInput!) {
    mutateEmployeeAddress(customerId: $customerId, id: $id, addressId: $addressId, values: $values) {
      id
    }
  }
`;

export const TERMINATE_EMPLOYEE_MUTATION = gql`
  mutation terminateEmployee($customerId: ID!, $projectId: ID!, $id: ID!, $values: TerminateEmployeeInput!) {
    terminateEmployee(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...EmployeeFragment
    }
  }
  ${EMPLOYEE_FRAGMENT}
`;
