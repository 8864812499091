import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { endOfDay, isValid, startOfDay } from "date-fns";

import { RootState } from "../../store";
import type { EventStatusType, Nullable, UserInterface } from "../../types";

export type CalendarViewsType = "month" | "week" | "work_week" | "day" | "agenda";

export type CalendarReducerType = {
  ownerId: Nullable<string>;
  creator: Nullable<UserInterface>;
  eventType: string[];
  date: Date;
  view: CalendarViewsType;
  eventStatus: Nullable<EventStatusType>;
  from: Nullable<Date>;
  to: Nullable<Date>;
  insertedStart: Nullable<Date>;
  insertedStop: Nullable<Date>;
  shiftedAtStart: Nullable<Date>;
  shiftedAtEnd: Nullable<Date>;

  teamId: null | string;
  page: number;

  sendEvents: {
    from: Nullable<Date>;
    to: Nullable<Date>;
    type: string[];
    sentStatus: Nullable<boolean>;
    eventStatus: Nullable<string>;
  };

  feedback: {
    ownerId: null | string;
    filterAttr: null | string;
    filterAttrValue: null | string;
    done: boolean;
    page: number;
    tookPlace: Nullable<boolean>;
  };
};

const initialState: CalendarReducerType = {
  ownerId: null,
  creator: null,
  eventType: [],
  date: new Date(),
  view: "month",
  eventStatus: "NOT_CLEARED",
  from: null,
  to: null,
  insertedStart: null,
  insertedStop: null,
  shiftedAtStart: null,
  shiftedAtEnd: null,
  teamId: null,
  page: 0,

  sendEvents: {
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
    type: [],
    sentStatus: null,
    eventStatus: null,
  },

  feedback: {
    ownerId: null,
    filterAttr: null,
    filterAttrValue: null,
    done: false,
    tookPlace: null,
    page: 0,
  },
};

const todosSlice = createSlice({
  name: "calendar/calendar",
  initialState,
  reducers: {
    setOwnerId(state, action: PayloadAction<Nullable<string>>) {
      state.ownerId = action.payload;
    },
    setCreator(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.creator = action.payload;
    },
    setEventType(state, action: PayloadAction<string[]>) {
      state.eventType = action.payload;
    },
    setEventStatus(state, action: PayloadAction<Nullable<EventStatusType>>) {
      state.eventStatus = action.payload;
    },
    setFrom(state, action: PayloadAction<Nullable<Date>>) {
      state.from = action.payload && isValid(action.payload) ? startOfDay(action.payload) : null;
    },
    setTo(state, action: PayloadAction<Nullable<Date>>) {
      state.to = action.payload && isValid(action.payload) ? endOfDay(action.payload) : null;
    },
    setInsertedStart(state, action: PayloadAction<Nullable<Date>>) {
      state.insertedStart = action.payload && isValid(action.payload) ? startOfDay(action.payload) : null;
    },
    setInsertedStop(state, action: PayloadAction<Nullable<Date>>) {
      state.insertedStop = action.payload && isValid(action.payload) ? endOfDay(action.payload) : null;
    },

    setTeamId(state, action: PayloadAction<Nullable<string>>) {
      state.teamId = action.payload;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setFeedbackPage(state, { payload }: PayloadAction<number>) {
      state.feedback.page = payload;
    },
    setFeedbackFilterAttr(state, action: PayloadAction<{ attr: null | string; value: null | string }>) {
      state.feedback.filterAttr = action.payload.attr;
      state.feedback.filterAttrValue = action.payload.value;
    },
    toggleFeedbackDone(state) {
      state.feedback.done = !state.feedback.done;
    },
    setFeedbackOwnerId(state, action: PayloadAction<null | string>) {
      state.feedback.ownerId = action.payload;
    },
    setDate(state, action: PayloadAction<Date>) {
      state.date = action.payload;
    },
    setView(state, action: PayloadAction<CalendarViewsType>) {
      state.view = action.payload;
    },
    setSendEventsFrom(state, action: PayloadAction<Date>) {
      state.sendEvents.from = action.payload ? startOfDay(action.payload) : null;
    },
    setSendEventsTo(state, action: PayloadAction<Date>) {
      state.sendEvents.to = action.payload ? endOfDay(action.payload) : null;
    },
    setSendEventsType(state, action: PayloadAction<string[]>) {
      state.sendEvents.type = action.payload;
    },
    setSendEventStatus(state, action: PayloadAction<Nullable<string>>) {
      state.sendEvents.eventStatus = action.payload;
    },
    setSendEventsSentStatus(state, { payload }: PayloadAction<Nullable<boolean>>) {
      state.sendEvents.sentStatus = payload;
    },
    setTookPlace(state, { payload }: PayloadAction<Nullable<boolean>>) {
      state.feedback.tookPlace = payload;
    },
    setShiftedAtStart(state, action: PayloadAction<Nullable<Date>>) {
      state.shiftedAtStart = action.payload && isValid(action.payload) ? startOfDay(action.payload) : null;
    },
    setShiftedAtEnd(state, action: PayloadAction<Nullable<Date>>) {
      state.shiftedAtEnd = action.payload && isValid(action.payload) ? endOfDay(action.payload) : null;
    },
    resetFeedback(state) {
      state.feedback = initialState.feedback;
      state.ownerId = null;
      state.creator = null;
      state.teamId = null;
    },

    resetSendEvents(state) {
      state.sendEvents = initialState.sendEvents;
    },

    resetFilters(state) {
      state.ownerId = null;
      state.creator = null;
      state.eventType = [];
      state.date = new Date();
      state.view = "month";
      state.eventStatus = "NOT_CLEARED";
      state.from = null;
      state.to = null;
      state.insertedStart = null;
      state.insertedStop = null;
      state.shiftedAtStart = null;
      state.shiftedAtEnd = null;
      state.teamId = null;
    },
  },
});

export const {
  setOwnerId,
  setCreator,
  setEventType,
  setEventStatus,
  setFrom,
  setTo,
  setInsertedStart,
  setInsertedStop,
  setTeamId,
  setFeedbackFilterAttr,
  toggleFeedbackDone,
  setFeedbackOwnerId,
  setDate,
  setView,
  setSendEventsFrom,
  setSendEventsTo,
  setSendEventsType,
  setSendEventsSentStatus,
  setSendEventStatus,
  setPage,
  setFeedbackPage,
  resetFeedback,
  resetSendEvents,
  resetFilters,
  setTookPlace,
  setShiftedAtStart,
  setShiftedAtEnd,
} = todosSlice.actions;
export const calendarSelector = (state: RootState) => state.calendar;
export default todosSlice.reducer;
