import { SessionInterface } from "../store/sessionReducer";
import type { PermissionType, TtAcdCallInterface } from "../types";
import { featureEnabled } from "./helpers";

export interface AcdMayInterface {
  (session: SessionInterface, path: "acd/calls"): boolean;
  (session: SessionInterface, path: "acd/calls", action: "show", call: TtAcdCallInterface): boolean;

  (session: SessionInterface, path: "acd/dashboard"): boolean;
}

const AcdPermissions: PermissionType<any> = {
  "acd/calls": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "acd_calls"),
  },
  "acd/dashboard": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "acd/dashboard"),
  },
};

export default AcdPermissions;
