import { SessionInterface } from "../store/sessionReducer";
import type { PermissionType, TodoInterface } from "../types";
import { featureEnabled, isCustomerAdmin, isStaffAdmin, projectFeatureEnabled, userFeatureEnabled } from "./helpers";

export interface TodoMayInterface {
  (session: SessionInterface, path: "todos/todos"): boolean;
  (session: SessionInterface, path: "todos/todos", action: "new" | "export" | "filterUsers"): boolean;
  (
    session: SessionInterface,
    path: "todos/todos",
    action: "own" | "edit" | "delete" | "send" | "assign" | "comment" | "show" | "archive",
    customer: TodoInterface,
  ): boolean;
}

const TODO_PERMISSIONS: PermissionType<TodoInterface> = {
  "todos/todos": {
    new: ({ currentUser, currentCustomer, currentProject }) =>
      projectFeatureEnabled(currentProject, "todos") &&
      (currentCustomer.identifier !== "termitel" || isCustomerAdmin(currentUser, currentCustomer)),
    index: ({ currentUser, currentProject, currentCustomer }) =>
      featureEnabled(currentUser, currentProject, "todos") &&
      (currentCustomer.identifier !== "termitel" || isCustomerAdmin(currentUser, currentCustomer)),
    own: ({ currentUser, currentProject, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentProject, "todos") &&
      (currentCustomer.identifier !== "termitel" || isStaffAdmin(currentUser)) &&
      !resource.ownerId,
    edit: ({ currentProject, currentUser, currentCustomer }, todo) =>
      featureEnabled(currentUser, currentProject, "todos") &&
      userFeatureEnabled(currentUser, "todos/edit") &&
      (currentCustomer.identifier !== "termitel" || isStaffAdmin(currentUser)) &&
      (!!todo.ownerId || isCustomerAdmin(currentUser, currentCustomer)),
    delete: ({ currentProject, currentUser, currentCustomer }) =>
      (featureEnabled(currentUser, currentProject, "todos") &&
        isCustomerAdmin(currentUser, currentCustomer) &&
        currentCustomer.identifier !== "termitel") ||
      isStaffAdmin(currentUser),
    send: ({ currentCustomer, currentProject, currentUser }, resource) =>
      ((featureEnabled(currentUser, currentProject, "todos") &&
        userFeatureEnabled(currentUser, "todos/send-todo") &&
        currentCustomer.identifier !== "termitel") ||
        isStaffAdmin(currentUser)) &&
      !resource?.sent &&
      !!resource.owner,
    export: ({ currentUser, currentProject, currentCustomer }) =>
      (featureEnabled(currentUser, currentProject, "todos") &&
        userFeatureEnabled(currentUser, "todos/export") &&
        currentCustomer.identifier !== "termitel") ||
      isStaffAdmin(currentUser),
    filterUsers: ({ currentUser, currentProject, currentCustomer }) =>
      (featureEnabled(currentUser, currentProject, "customer_contacts") &&
        userFeatureEnabled(currentUser, "todos/filterUser") &&
        currentCustomer.identifier !== "termitel") ||
      isStaffAdmin(currentUser),
    assign: ({ currentUser, currentProject, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentProject, "todos") &&
      userFeatureEnabled(currentUser, "todos/assign") &&
      (currentCustomer.identifier !== "termitel" || isStaffAdmin(currentUser)) &&
      !resource.ownerId,
    archive: ({ currentUser, currentProject, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentProject, "todos") &&
      !resource.allDone &&
      ([resource.ownerId, resource.creatorId].includes(currentUser.id) ||
        userFeatureEnabled(currentUser, "todos/archive-foreign") ||
        isCustomerAdmin(currentUser, currentCustomer)),
    comment: ({ currentProject, currentUser }, resource) =>
      !!resource.ownerId && featureEnabled(currentUser, currentProject, "todos"),
  },
};

export default TODO_PERMISSIONS;
