import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const PROJECT_LIST_QUERY = gql`
  query projects($customerId: ID!, $filters: ListProjectsFilters) {
    projects(customerId: $customerId, filters: $filters) {
      id
      name
      identifier
      active
      attrs
      insertedAt
      updatedAt
      customerId
    }
  }
`;

export const COUNT_PROJECTS_QUERY = gql`
  query countProjects($customerId: ID!, $filters: CountProjectsFilters) {
    countProjects(customerId: $customerId, filters: $filters)
  }
`;

export const GLOBAL_PROJECT_LIST_QUERY = gql`
  query projects($customerId: ID, $filters: ListProjectsFilters) {
    projects(customerId: $customerId, filters: $filters) {
      id
      customerId
      name
      active
      insertedAt
      updatedAt
      attrs

      customer {
        id
        name
      }
    }
  }
`;

const PROJECT_DETAIL_FRAGMENT = gql`
  fragment ProjectDetailFragment on Project {
    id
    name
    identifier
    active
    attrs

    customer {
      id
      name
      identifier
    }

    users {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export const PROJECT_GET_QUERY = gql`
  query project($customerId: ID!, $projectId: ID!) {
    project(customerId: $customerId, id: $projectId) {
      ...ProjectDetailFragment
    }
  }
  ${PROJECT_DETAIL_FRAGMENT}
`;

export const PROJECT_MUTATION = gql`
  mutation mutateProject($customerId: ID!, $projectId: ID, $project: ProjectInput!) {
    mutateProject(customerId: $customerId, id: $projectId, project: $project) {
      ...ProjectDetailFragment
    }
  }
  ${PROJECT_DETAIL_FRAGMENT}
`;

export const FEATURE_MUTATION = gql`
  mutation toggleFeature($customerId: ID!, $projectId: ID!, $feature: String!) {
    toggleFeature(customerId: $customerId, id: $projectId, feature: $feature) {
      ...ProjectDetailFragment
    }
  }
  ${PROJECT_DETAIL_FRAGMENT}
`;

export const COPY_PROJECT_MUTATION = gql`
  mutation copyProject($customerId: ID!, $projectId: ID!, $name: String!, $identifier: String!, $targetCustomer: ID) {
    copyProject(
      customerId: $customerId
      id: $projectId
      name: $name
      identifier: $identifier
      targetCustomer: $targetCustomer
    ) {
      id
      name
      identifier
      active
      attrs
      insertedAt
      updatedAt
    }
  }
`;

export const PROJECT_ATTACHMENT_FRAGMENT = gql`
  fragment ProjectAttachmentFragment on ProjectAttachment {
    id
    file
    identifier

    creator {
      ...UserFragment
    }

    editor {
      ...UserFragment
    }

    insertedAt
    updatedAt
  }
  ${USER_FRAGMENT}
`;

export const PROJECT_ATTACHMENTS_LIST_QUERY = gql`
  query projectAttachments($customerId: ID!, $projectId: ID!, $filters: ListProjectAttachmentsFilters) {
    projectAttachments(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...ProjectAttachmentFragment
    }
  }
  ${PROJECT_ATTACHMENT_FRAGMENT}
`;

export const PROJECT_ATTACHMENTS_COUNT_QUERY = gql`
  query countProjectAttachments($customerId: ID!, $projectId: ID!) {
    countProjectAttachments(customerId: $customerId, projectId: $projectId)
  }
`;

export const PROJECT_ATTACHMENT_GET_QUERY = gql`
  query projectAttachment($customerId: ID!, $projectId: ID!, $id: ID!) {
    projectAttachment(customerId: $customerId, projectId: $projectId, id: $id) {
      ...ProjectAttachmentFragment
    }
  }
  ${PROJECT_ATTACHMENT_FRAGMENT}
`;

export const PROJECT_ATTACHMENT_DELETE_MUTATION = gql`
  mutation deleteProjectAttachment($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteProjectAttachment(customerId: $customerId, projectId: $projectId, id: $id) {
      id
    }
  }
`;

export const PROJECT_ATTACHMENT_MUTATION = gql`
  mutation mutateProjectAttachment($customerId: ID!, $projectId: ID!, $id: ID, $attachment: ProjectAttachmentInput!) {
    mutateProjectAttachment(customerId: $customerId, projectId: $projectId, id: $id, attachment: $attachment) {
      ...ProjectAttachmentFragment
    }
  }
  ${PROJECT_ATTACHMENT_FRAGMENT}
`;
