import { getIn } from "formik";

import { ReactLazyLoading } from "../../../containers/ReactLoading";
import type { CustomerInterface, ProjectInterface } from "../../../types";

const COMPONENTS = {
  "sevenit-gmbh": {
    sevdesk: ReactLazyLoading(() => import("../../../customers/sevenit/Hotseat/ForwardModal")),
  },
};

export const getComponent = (customer: CustomerInterface, project: ProjectInterface) =>
  getIn(COMPONENTS, [customer.identifier, project.identifier]) || null;
