import { SessionInterface } from "../store/sessionReducer";
import type { CustomerInterface } from "../types";
import { sessionPrefix } from "./helpers";

export const adminCustomersPath = (session: SessionInterface) => `${sessionPrefix(session)}/admin/customers`;
export const newAdminCustomerPath = (session: SessionInterface) => `${sessionPrefix(session)}/admin/customers/new`;
export const editAdminCustomerPath = (session: SessionInterface, customer: CustomerInterface) =>
  `${sessionPrefix(session)}/admin/customers/${customer.id}/edit`;
export const editAdminCustomerProjectsPath = (session: SessionInterface, customer: CustomerInterface) =>
  `${sessionPrefix(session)}/admin/customers/${customer.id}/edit/projects`;
