import React from "react";

import { Button } from "react-bootstrap";
import { MdAdd } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const NewButton: TTButtonProps = React.memo(({ children, ...props }) => (
  <Button variant="secondary" size="sm" {...props}>
    <MdAdd /> {children}
  </Button>
));

export default NewButton;
