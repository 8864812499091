import { ApolloClient, from, InMemoryCache, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";

import { createLink as createAbsintheUploadLink } from "apollo-absinthe-upload-link";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { createClient } from "graphql-ws";
import _ from "lodash";

import { getAuthorizationToken } from "../authorization_token";
import { authLink, GRAPHQL_URI, GRAPHQL_URI_RS, GRAPHQL_URI_WS_RS, sentryLink } from "../graphqlUtils";
import { setContext } from "../sentry";

const httpLink = createAbsintheUploadLink({ uri: GRAPHQL_URI });
const httpLinkRs = createUploadLink({ uri: GRAPHQL_URI_RS });
const client = createClient({
  url: GRAPHQL_URI_WS_RS,
  shouldRetry: () => true,
  lazy: true,
  keepAlive: 10_000,
});

const wsLink = new GraphQLWsLink(client);

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors && "filter" in graphQLErrors) {
    graphQLErrors
      .filter(({ message }) => message !== "not_found")
      .forEach(({ message, locations, path, extensions }) => {
        if (["login", "refresh"].includes(operation.operationName)) return;

        console.error(
          `[GraphQL error]: Message: ${message}, Location:`,
          locations,
          "Path:",
          path,
          "extensions",
          extensions,
          "Operation:",
          operation.operationName,
          operation,
        );

        if (process.env.NODE_ENV === "production") {
          const variables = _.omit(operation.variables, ["password", "values.password"]);

          const errorContext = {
            extensions: JSON.stringify(extensions, undefined, 2),
            locations: JSON.stringify(locations, undefined, 2),
            path: JSON.stringify(path, undefined, 2),
            operationName: operation.operationName,
            operation: JSON.stringify(path, undefined, 2),
            variables: JSON.stringify(variables, undefined, 2),
          };

          const err = new Error(message);
          err.name = "graphQLError";
          if ("captureStackTrace" in Error) Error.captureStackTrace(err);

          Sentry.setTag("type", "graphql");

          Sentry.setContext("graphqlContext", errorContext);

          setContext();

          Sentry.captureException(err);
        }
      });
  }

  if (graphQLErrors && !("filter" in graphQLErrors)) {
    console.error("[unknown GraphQL error]", graphQLErrors);

    if (process.env.NODE_ENV === "production") {
      const err = new Error("unknown GraphQL error structure");
      err.name = "graphQLError";
      if ("captureStackTrace" in Error) Error.captureStackTrace(err);

      Sentry.setTag("type", "graphql");
      setContext();

      Sentry.captureException(err);
    }
  }

  if (networkError) {
    console.error("[Network error]: ", networkError);
  }
});

const link = from([sentryLink, errorLink, authLink(() => getAuthorizationToken()).concat(httpLink)]);
const linkRs = from([sentryLink, errorLink, authLink(() => getAuthorizationToken()).concat(httpLinkRs as any)]);

const RS_OPERTAIONS: Record<string, boolean> = {
  messages: true,
  countMessages: true,
  message: true,
  mutateMessage: true,
  deleteMessage: true,
  markMessageRead: true,
  users: true,
  countUsers: true,
  user: true,
  mutateUser: true,
  resetUserPassword: true,
  mutateUserProfile: true,
  mutateUserSignature: true,
  mutateUserPassword: true,
  mutateUserAvatar: true,
  enableOtp: true,
  disableOtp: true,
  disableOtpForUser: true,
  usernameUnique: true,
  e164Unique: true,
  deleteUser: true,
  userWithDetails: true,
  apiTokenForUser: true,
  customers: true,
  customersWithCustomerAdministrators: true,
  customersWithCustomerAdministratorsAndProjects: true,
  customer: true,
  customerAndProjects: true,
  mutateCustomer: true,
  deleteCustomer: true,
  coachings: true,
  countCoachings: true,
  coaching: true,
  mutateCoaching: true,
  deleteCoaching: true,
  confirmCoaching: true,
  currentEmployeeCoupon: true,
  employeeCoupons: true,
  countEmployeeCoupons: true,
  deleteEmployeeCoupon: true,
  mutateEmployeeCoupon: true,
  employeeCoupon: true,
  contracts: true,
  countContracts: true,
  contract: true,
  mutateContract: true,
  deleteContract: true,
  generateContract: true,
  contractForUserAndDate: true,
  projects: true,
  countProjects: true,
  project: true,
  mutateProject: true,
  copyProject: true,
  toggleFeature: true,
  workerJobs: true,
  countWorkerJobs: true,
  requeueWorkerJob: true,
  login: true,
  refresh: true,
  tokenForUser: true,
  sessionData: true,
  projectAttachments: true,
  countProjectAttachments: true,
  projectAttachment: true,
  mutateProjectAttachment: true,
  deleteProjectAttachment: true,
  teams: true,
  countTeams: true,
  team: true,
  isTeamLeader: true,
  deleteTeam: true,
  mutateTeam: true,
  copyTeam: true,
  teamsWithAllUsers: true,
  teamsByProjects: true,
  teamsWithUsers: true,
  teamsWithUsersAndForeignUsers: true,
  profiles: true,
  countProfiles: true,
  profile: true,
  mutateProfile: true,
  deleteProfile: true,
  indeedStats: true,
  indeedStatsEntry: true,
  mutateIndeedStatsForMonth: true,
  deleteIndeedStatsEntry: true,
  mutateIndeedStats: true,
  indeedStatsEntryValid: true,
  feastdays: true,
  countFeastdays: true,
  feastday: true,
  mutateFeastday: true,
  deleteFeastday: true,
  worktimesTasks: true,
  worktimesTask: true,
  mutateWorktimesTask: true,
  deleteWorktimesTask: true,
  worktimes: true,
  countWorktimes: true,
  worktime: true,
  worktimeStartEndValid: true,
  worktimeMutation: true,
  deleteWorktime: true,
  startWorktime: true,
  stopCurrentWorktime: true,
  confirmDayWorktimes: true,
  declineDayWorktimes: true,
  worktimesSupervisor: true,
  worktimesSummary: true,
  worktimesChangeRequests: true,
  countWorktimesChangeRequests: true,
  worktimesChangeRequest: true,
  mutateWorktimesChangeRequest: true,
  deleteWorktimesChangeRequest: true,
  inquireWorktimesChangeRequest: true,
  rejectWorktimesChangeRequest: true,
  approveWorktimesChangeRequest: true,
  punchlistEntries: true,
  countPunchlistEntries: true,
  punchlistEntry: true,
  deletePunchlistEntry: true,
  mutatePunchlistEntry: true,
  closePunchlistEntry: true,
  punchlistEntryComments: true,
  mutatePunchlistEntryComment: true,
  residualLeaves: true,
  residualLeave: true,
  mutateResidualLeave: true,
  deleteResidualLeave: true,
  eventTypes: true,
  countEventTypes: true,
  eventType: true,
  mutateEventType: true,
  deleteEventType: true,
  events: true,
  countEvents: true,
  event: true,
  deleteEvent: true,
  cancelEvent: true,
  uncancelEvent: true,
  clearEvent: true,
  unclearEvent: true,
  mutateEvent: true,
  shiftEvent: true,
  confirmEventWithToken: true,
  eventTimeValid: true,
  transferEventOwnership: true,
  historiesCallResults: true,
  countHistoriesCallResults: true,
  historiesCallResult: true,
  mutateHistoriesCallResult: true,
  deleteHistoriesCallResult: true,
  mutateContactHistoryAnnotation: true,
  markContactHistoryAnnotationDone: true,
  histories: true,
  countHistories: true,
  mutateHistory: true,
  history: true,
  markHistoryDone: true,
  pinHistory: true,
  deleteHistoryAttachment: true,
  callHistories: true,
  countCallHistories: true,
  contactStates: true,
  countContactStates: true,
  contactStatus: true,
  mutateContactStatus: true,
  deleteContactStatus: true,
  moveContactStatus: true,
  hotseatQuestions: true,
  countHotseatQuestions: true,
  mutateHotseatRequest: true,
  hotseatRequests: true,
  countHotseatRequests: true,
  hotseatQuestion: true,
  mutateHotseatQuestion: true,
  deleteHotseatRequest: true,
  markHotseatQuestionDone: true,
  acdCalls: true,
  countAcdCalls: true,
  acdCallTimes: true,
  acdCall: true,
  mutateAcdCall: true,
  contacts: true,
  countContacts: true,
  contact: true,
  deleteContact: true,
  transferContact: true,
  mutateContact: true,
  lockContact: true,
  unlockContact: true,
  mergeContacts: true,
  contactGroups: true,
  contactGroup: true,
  mutateContactGroup: true,
  deleteContactGroup: true,
  jobResults: true,
  countJobResults: true,
  jobResult: true,
  markJobResultSeen: true,
  deleteJobResult: true,
  createContactsBatchAction: true,
  communications: true,
  countCommunications: true,
  communication: true,
  mutateCommunication: true,
  deleteCommunication: true,
  communicationLists: true,
  countCommunicationLists: true,
  communicationList: true,
  mutateCommunicationList: true,
  deleteCommunicationList: true,
  taskOffers: true,
  countTaskOffers: true,
  taskOffer: true,
  mutateTaskOffer: true,
  deleteTaskOffer: true,
  taskProducts: true,
  countTaskProducts: true,
  taskProduct: true,
  mutateTaskProduct: true,
  deleteTaskProduct: true,
  contactAttachments: true,
  countContactAttachments: true,
  contactAttachment: true,
  mutateContactAttachment: true,
  deleteContactAttachment: true,
  workerStatsNew: true,
  acdProjectToken: true,
  acdEndpointToken: true,
  employeePerformance: true,
  employeePerformances: true,
  employees: true,
  countEmployees: true,
  employee: true,
  mutateEmployee: true,
  deleteEmployee: true,
  terminateEmployee: true,
  mutateEmployeeAddress: true,
  mutateEmployeeInfo: true,
  deleteEmployeeInfo: true,
  deleteEmployeeAddress: true,
  exportTasks: true,
  recalculateTaskPoints: true,
  changeRequests: true,
  countChangeRequests: true,
  changeRequest: true,
  mutateChangeRequest: true,
  deleteChangeRequest: true,
  sendChangeRequest: true,
  changeRequestByToken: true,
  mutateChangeRequestByToken: true,
  tickets: true,
  countTickets: true,
  ticket: true,
  mutateTicket: true,
  orderTicket: true,
  subscribeTicket: true,
  unsubscribeTicket: true,
  ticketTimeline: true,
  mutateTicketTimelineEntry: true,
  deleteTicketTimelineEntry: true,
  deleteTicket: true,
  ticketStats: true,
  mails: true,
  mailThreads: true,
  countMailThreads: true,
  countMails: true,
  mailThread: true,
  mail: true,
  assignMailThread: true,
  labelMail: true,
  lockMailThread: true,
  unlockMailThread: true,
  ownMail: true,
  mutateMailStatus: true,
  markMailSpam: true,
  mergeMailThreads: true,
  deleteMail: true,
  deleteMailThread: true,
  sendMail: true,
  mutateMail: true,
  mailTemplates: true,
  countMailTemplates: true,
  mailTemplate: true,
  mutateMailTemplate: true,
  deleteMailTemplate: true,
  mailTemplateAttachments: true,
  countMailTemplateAttachments: true,
  mailTemplateAttachment: true,
  mutateMailTemplateAttachment: true,
  deleteMailTemplateAttachment: true,
  oauthTokens: true,
  oauthToken: true,
  deleteOauthToken: true,
  mutateOauthToken: true,
  personalFileTypes: true,
  countPersonalFileTypes: true,
  personalFileType: true,
  mutatePersonalFileType: true,
  deletePersonalFileType: true,
  missingPersonalFileTypes: true,
  countMissingPersonalFileTypes: true,
  personalFiles: true,
  personalFile: true,
  mutatePersonalFile: true,
  markPersonalFileRead: true,
  deletePersonalFile: true,
  requestChallenge: true,
  verifyChallenge: true,
  sendPersonalFileChallenge: true,
  verifyPersonalFileChallenge: true,
  sendPersonalFile: true,
  createContractAdaption: true,
  dashboardStatsNew: true,
  sendEvent: true,
  confirmTodoWithToken: true,
  contactAddfields: true,
  countContactAddfields: true,
  contactAddfield: true,
  mutateContactAddfield: true,
  deleteContactAddfield: true,
  tasks: true,
  countTasks: true,
  task: true,
  mutateTask: true,
  deleteTask: true,
  sendTask: true,
  questionnaireTask: true,
  todos: true,
  countTodos: true,
  todo: true,
  mutateTodo: true,
  archiveTodo: true,
  createTodoNote: true,
  ownTodo: true,
  sendTodo: true,
  deleteTodo: true,
  acceptTodo: true,
  rejectTodo: true,
  assignTodo: true,
  agendaConfigs: true,
  agendaConfig: true,
  mutateAgendaConfig: true,
  deleteAgendaConfig: true,
  contactsPrioList: true,
  countContactsPrioList: true,
  projectNews: true,
  countProjectNews: true,
  projectNewsPost: true,
  deleteProjectNews: true,
  mutateProjectNews: true,
  mutateProjectNewsAttachment: true,
  inventoryItemTypes: true,
  countInventoryItemTypes: true,
  inventoryItemType: true,
  mutateInventoryItemType: true,
  deleteInventoryItemType: true,
  inventoryItemTypeMetaInfo: true,
  inventoryItems: true,
  countInventoryItems: true,
  inventoryItemsInNeedForDguvCheck: true,
  countInventoryItemsInNeedForDguvCheck: true,
  inventoryItem: true,
  mutateInventoryItem: true,
  deleteInventoryItem: true,
  inventoryHandoverItems: true,
  inventoryHandbackItems: true,
  inventoryItemAttachments: true,
  createInventoryItemAttachment: true,
  deleteInventoryItemAttachment: true,
  subscribeInventoryItem: true,
  inventorySets: true,
  countInventorySets: true,
  inventorySet: true,
  mutateInventorySet: true,
  deleteInventorySet: true,
  inventoryItemTimeline: true,
  countInventoryItemTimeline: true,
  mutateInventoryItemTimelineEntry: true,
  importInventoryItemTimelineEntries: true,
  deleteInventoryItemTimelineEntry: true,
  inventoryReservations: true,
  countInventoryReservations: true,
  inventoryReservation: true,
  mutateInventoryReservation: true,
  deleteInventoryReservation: true,
  inventoryReservationHandover: true,
  eventFeedbacks: true,
  countEventFeedbacks: true,
  eventFeedback: true,
  mutateEventFeedback: true,
  deleteEventFeedback: true,
  appointmentStats: true,
  uploads: true,
  countUploads: true,
  upload: true,
  mutateUpload: true,
  markUploadDone: true,
  deleteUpload: true,
  updateUnnContacts: true,
  staffHistories: true,
  countStaffHistories: true,
  staffHistory: true,
  mutateStaffHistory: true,
  deleteStaffHistory: true,
  hideProjectNews: true,
  recruitingTeamOverview: true,
  recruitingContactsCreated: true,
  mutateFteGoal: true,
  companyNews: true,
  lastCompanyNewsPost: true,
  mutateCompanyNews: true,
  deleteCompanyNews: true,
  companyNewsPost: true,
  countCompanyNews: true,
  fileExchangeEntries: true,
  countFileExchangeEntries: true,
  fileExchangeEntry: true,
  deleteFileExchangeEntry: true,
  mutateFileExchangeEntry: true,
  markFileExchangeEntryDone: true,
  createFileExchangeEntryFromEmployee: true,
  createFileExchangeEntryFromPersonalFile: true,
  fileExchangeUsers: true,
  reminders: true,
  countReminders: true,
  reminder: true,
  mutateReminder: true,
  deleteReminder: true,
  reminderNotifications: true,
  countReminderNotifications: true,
  markReminderNotificationDone: true,
  deleteReminderNotification: true,
  documents: true,
  countDocuments: true,
  document: true,
  mutateDocument: true,
  deleteDocument: true,
  confirmDocument: true,
  missingDocumentSignaturesForUsers: true,
  missingDocumentSignaturesForUser: true,
  overdueDocuments: true,
  mutateDocumentAttachment: true,
  dataPoolStats: true,
  dataPoolByProjectStats: true,
  sendRecruitmentPlannedNotification: true,
  projectStats: true,
  fixSugContacts: true,
  vacationRequests: true,
  countVacationRequests: true,
  vacationRequest: true,
  deleteVacationRequest: true,
  mutateVacationRequest: true,
  mutateVacationRequestComment: true,
  vacationOverview: true,
  vacationPlan: true,
  vacationAvailable: true,
  finalizeVacationRequest: true,
  availableVacationDays: true,
  importContacts: true,
  injixoSync: true,
  contactStatesStats: true,
  ttDashboardBirthdays: true,
  ttDashboardContracts: true,
  ttDashboardProbations: true,
  ttDashboardRoster: true,
  ttDashboardWorktimes: true,
  rosterTasks: true,
  countRosterTasks: true,
  rosterTask: true,
  mutateRosterTask: true,
  deleteRosterTask: true,
  archiveRosterTask: true,
  rosterGroups: true,
  rosterGroup: true,
  mutateRosterGroup: true,
  deleteRosterGroup: true,
  rosterAttendanceTypes: true,
  countRosterAttendanceTypes: true,
  rosterAttendanceType: true,
  rosterStandardAttendanceTypeValid: true,
  mutateRosterAttendanceType: true,
  deleteRosterAttendanceType: true,
  archiveRosterAttendanceType: true,
  rosterStandardAttendances: true,
  rosterStandardAttendance: true,
  mutateRosterStandardAttendance: true,
  deleteRosterStandardAttendance: true,
  rosterStandardDays: true,
  rosterStandardDay: true,
  mutateRosterStandardDay: true,
  deleteRosterStandardDay: true,
  shiftRosterStandardDayHours: true,
  copyRosterStandardDay: true,
  rosterAttendances: true,
  rosterAttendance: true,
  mutateRosterAttendance: true,
  deleteRosterAttendance: true,
  batchDeleteRosterAttendances: true,
  planStandardRosterAttendance: true,
  confirmRosterAttendances: true,
  rosterHours: true,
  rosterHour: true,
  mutateRosterHour: true,
  deleteRosterHour: true,
  batchDeleteRosterHours: true,
  planStandardRosterHours: true,
  confirmRosterHours: true,
  shiftRosterHours: true,
  copyRosterHours: true,
  rosterMonth: true,
  mutateRosterGroupsForUser: true,
  logEntries: true,
  countLogEntries: true,
  logEntry: true,
  knowledgebaseQuestions: true,
  countKnowledgebaseQuestions: true,
  knowledgebaseQuestion: true,
  deleteKnowledgebaseQuestion: true,
  mutateKnowledgebaseQuestion: true,
  regenKnowledgebaseSearchIndex: true,
  mutateKnowledgebaseAttachment: true,
  deleteKnowledgebaseAttachment: true,
  invoices: true,
  countInvoices: true,
  invoice: true,
  deleteInvoice: true,
  mutateInvoice: true,
  confirmInvoice: true,
  contactStatusUpdates: true,
  countContactStatusUpdates: true,
  contactStatusUpdate: true,
  mutateContactStatusUpdate: true,
  deleteContactStatusUpdate: true,
  termitelContracts: true,
  mutateTermitelContract: true,
  attachTermitelContractDocument: true,
  deleteTermitelContract: true,
  deleteTermitelContractDocument: true,

  termitelContractOverview: true,

  customerFeedbacks: true,
  countCustomerFeedbacks: true,
  customerFeedback: true,
  markCustomerFeedbackDone: true,
  deleteCustomerFeedback: true,
  customerFeedbackAnnotations: true,
  mutateCustomerFeedbackAnnotation: true,
  markCustomerFeedbackAnnotationDone: true,
  customerFeedbackTopics: true,
  countCustomerFeedbackTopics: true,
  customerFeedbackTopic: true,
  mutateCustomerFeedbackTopic: true,
  classifyCustomerFeedback: true,

  timesheetOverview: true,
  timesheetPresences: true,
  timesheetYearOverview: true,
  hotseatStats: true,
  historyAuditLogEntries: true,
  countHistoryAuditLogEntries: true,

  contactsAddfieldStats: true,
  exportHotseatRequests: true,
  rosterHoursMonthSums: true,
};

const apolloClient = new ApolloClient({
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    split((op) => !RS_OPERTAIONS[op.operationName], link, linkRs),
  ),
  cache: new InMemoryCache({
    typePolicies: {
      Contact: {
        fields: {
          infos: { merge: false },
          events: { merge: false },
          addresses: { merge: false },
          children: { merge: false },
        },
      },
      RosterStandardDay: {
        fields: {
          hours: { merge: false },
        },
      },
      Query: {
        fields: {
          rosterAttendances: { merge: false },
          rosterHours: { merge: false },
          rosterStandardDays: { merge: false },
          events: { merge: false },
        },
      },
      Mutation: {
        fields: {
          batchDeleteRosterAttendances: { merge: false },
          planStandardRosterAttendance: { merge: false },
          batchDeleteRosterHours: { merge: false },
          planStandardRosterHours: { merge: false },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default apolloClient;
