import { SessionInterface } from "../store/sessionReducer";
import { sessionPrefix } from "./helpers";

export * from "./helpers";
export * from "./admin_users_paths";
export * from "./admin_customers_path";
export * from "./contact_paths";
export * from "./customer_contact_paths";

export const rootPath = (session: SessionInterface) => sessionPrefix(session);
