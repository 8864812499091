import { SessionInterface } from "../store/sessionReducer";
import {
  PermissionType,
  RosterAttendanceInterface,
  RosterAttendanceTypeInterface,
  RosterGroupInterface,
  RosterHourInterface,
  RosterStandardAttendanceInterface,
  RosterStandardDayInterface,
  RosterTaskInterface,
} from "../types";
import { featureEnabled, isAdmin, projectFeatureEnabled, userFeatureEnabled } from "./helpers";

export interface RosterMayInterface {
  // roster/attendance-types
  (session: SessionInterface, path: "roster/attendance-types"): boolean;
  (session: SessionInterface, path: "roster/attendance-types", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "roster/attendance-types",
    action: "edit" | "delete",
    attendanceType: RosterAttendanceTypeInterface,
  ): boolean;

  // roster/tasks
  (session: SessionInterface, path: "roster/tasks"): boolean;
  (session: SessionInterface, path: "roster/tasks", action: "new"): boolean;
  (session: SessionInterface, path: "roster/tasks", action: "edit" | "delete", task: RosterTaskInterface): boolean;

  // roster/groups
  (session: SessionInterface, path: "roster/groups"): boolean;
  (session: SessionInterface, path: "roster/groups", action: "new"): boolean;
  (session: SessionInterface, path: "roster/groups", action: "edit" | "delete", group: RosterGroupInterface): boolean;

  // roster/standard-attendances
  (session: SessionInterface, path: "roster/standard-attendances"): boolean;
  (session: SessionInterface, path: "roster/standard-attendances", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "roster/standard-attendances",
    action: "edit" | "delete",
    wt: RosterStandardAttendanceInterface,
  ): boolean;

  // roster/standard-days
  (session: SessionInterface, path: "roster/standard-days"): boolean;
  (session: SessionInterface, path: "roster/standard-days", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "roster/standard-days",
    action: "edit" | "delete",
    wt: RosterStandardDayInterface,
  ): boolean;

  // roster/day-planning
  (session: SessionInterface, path: "roster/attendance-planning"): boolean;
  (session: SessionInterface, path: "roster/day-planning"): boolean;

  (session: SessionInterface, path: "roster"): boolean;
  (session: SessionInterface, path: "roster", action: "day"): boolean;
  (session: SessionInterface, path: "roster", action: "month"): boolean;

  // roster/injixo-sync
  (session: SessionInterface, path: "roster/injixo-sync"): boolean;
}

const ATTENDANCE_TYPE_PERMISSIONS: PermissionType<RosterAttendanceTypeInterface> = {
  "roster/attendance-types": {
    index: ({ currentUser, currentCustomer }) =>
      projectFeatureEnabled(currentCustomer, "roster") && userFeatureEnabled(currentUser, "roster_admin"),
  },
};

const TASKS_PERMISSIONS: PermissionType<RosterTaskInterface> = {
  "roster/tasks": {
    index: ({ currentUser, currentCustomer }) =>
      projectFeatureEnabled(currentCustomer, "roster") && userFeatureEnabled(currentUser, "roster_admin"),
  },
};

const GROUPS_PERMISSIONS: PermissionType<RosterGroupInterface> = {
  "roster/groups": {
    index: ({ currentUser, currentCustomer }) =>
      projectFeatureEnabled(currentCustomer, "roster") && userFeatureEnabled(currentUser, "roster_admin"),
  },
};

const STANDARD_ATTENDANCES_PERMISSIONS: PermissionType<RosterStandardAttendanceInterface> = {
  "roster/standard-attendances": {
    index: ({ currentUser, currentCustomer }) =>
      projectFeatureEnabled(currentCustomer, "roster") && userFeatureEnabled(currentUser, "roster_admin"),
  },
};

const STANDARD_DAYS_PERMISSIONS: PermissionType<RosterStandardDayInterface> = {
  "roster/standard-days": {
    index: ({ currentUser, currentCustomer }) =>
      projectFeatureEnabled(currentCustomer, "roster") && userFeatureEnabled(currentUser, "roster_admin"),
  },
};

const ATTENDANCE_PLANNING_PERMISSIONS: PermissionType<RosterAttendanceInterface> = {
  "roster/attendance-planning": {
    index: ({ currentUser, currentCustomer }) =>
      projectFeatureEnabled(currentCustomer, "roster") && userFeatureEnabled(currentUser, "roster_admin"),
  },
};

const DAY_PLANNING_PERMISSIONS: PermissionType<RosterHourInterface> = {
  "roster/day-planning": {
    index: ({ currentUser, currentCustomer }) =>
      projectFeatureEnabled(currentCustomer, "roster") && userFeatureEnabled(currentUser, "roster_admin"),
  },
};

const INJIXO_SYNC_PERMISSIONS: PermissionType<{}> = {
  "roster/injixo-sync": {
    index: ({ currentUser, currentCustomer }) =>
      projectFeatureEnabled(currentCustomer, "injixo_sync") &&
      (userFeatureEnabled(currentUser, "injixo_sync") || isAdmin(currentUser)),
  },
};

const ROSTER_PERMISSIONS: PermissionType<null> = {
  roster: {
    index: ({ currentUser, currentCustomer }, resource) => featureEnabled(currentUser, currentCustomer, "roster"),
  },
};

const RosterPermissions = {
  ...ATTENDANCE_TYPE_PERMISSIONS,
  ...TASKS_PERMISSIONS,
  ...GROUPS_PERMISSIONS,
  ...STANDARD_ATTENDANCES_PERMISSIONS,
  ...STANDARD_DAYS_PERMISSIONS,
  ...ATTENDANCE_PLANNING_PERMISSIONS,
  ...DAY_PLANNING_PERMISSIONS,
  ...ROSTER_PERMISSIONS,
  ...INJIXO_SYNC_PERMISSIONS,
};

export default RosterPermissions;
