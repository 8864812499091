import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../utils/dates";
import SlateEditor from "../../SlateEditor";
import type { DocumentSubcompProps } from "../types";
import { DOCUMENT_FORMS } from "../utils";

export default function WorkingInstruction({ document }: DocumentSubcompProps) {
  const [Icon] = DOCUMENT_FORMS[document.type];
  const { t } = useTranslation(["documents", "translation"]);

  return (
    <div className="TT-documents-document-workinginstruction">
      <header>
        <img
          src={document.relevantProject.customer.logo.medium}
          alt={document.relevantProject.customer.name}
          className="logo"
        />
        <div className="type">{t(`documents:document_types.${document.type}` as const)}</div>
        <div className="icon">
          <Icon />
        </div>
        <h2>{document.subject}</h2>
      </header>

      <div className="meta-info">
        <div>
          <strong>{t("documents:field_names.id")}</strong> {document.id}
        </div>
        <div>
          <strong>{t("documents:field_names.valid_from")}</strong>
          {document.validFrom ? dateFormat(document.validFrom) : "-"}
        </div>
        <div>
          <strong>{t("documents:field_names.valid_until")}</strong>
          {document.validUntil ? dateFormat(document.validUntil) : "-"}
        </div>
        <div>
          <strong>{t("translation:global.created")}</strong>
          {dateFormat(document.insertedAt)}
        </div>
        <div>
          <strong>{t("translation:global.updated")}</strong>
          {dateFormat(document.updatedAt)}
        </div>
      </div>

      <SlateEditor id="document-content-view" value={document.document.content || ""} readOnly toolbar={false} />

      <p>{t("documents:show.sorted_under")}</p>

      <ul className="TT-documents-document-keywords">
        {document.keywords.map((k) => (
          <li key={k}>{k}</li>
        ))}
      </ul>
    </div>
  );
}
