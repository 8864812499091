import React from "react";

import { FastField, FastFieldProps, Field, getIn, useFormikContext } from "formik";
import _ from "lodash";
import { FormControl, FormControlProps } from "react-bootstrap";

import { fieldInvalid, fieldValid } from "../new_utils";

const PROPS_TO_OMIT = ["tag", "validationCallback"];

interface FormikInputPropsType extends FormControlProps {
  fast?: boolean;
  validate?: boolean;
  fallbackOnNull?: boolean;
  name: string;
  validationCallback?: (value: any) => undefined | string | Promise<any>;
  children?: any;
  placeholder?: any;
  maxLength?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  min?: number;
  step?: number;
  max?: number;
  required?: boolean;
  autoFocus?: boolean;
}

const getValue = (value: any, fallback: boolean) => {
  if (!fallback) return value;

  if (value === undefined || value === null) return "";
  return value;
};

export default function FormikInput({
  validate = true,
  fast = false,
  fallbackOnNull = false,
  isValid,
  isInvalid,
  ...props
}: FormikInputPropsType) {
  const { values, errors, touched } = useFormikContext();
  const value = { value: getValue(getIn(values, props.name), !!fallbackOnNull) };
  const Component = fast ? FastField : Field;

  if (!fallbackOnNull) {
    delete value.value;
  }

  return (
    <Component name={props.name}>
      {({ field }: FastFieldProps) => (
        <FormControl
          isValid={isValid ?? (validate && fieldValid(errors, touched, props.name))}
          isInvalid={isInvalid ?? (validate && fieldInvalid(errors, touched, props.name))}
          {...field}
          {..._.omit(props, PROPS_TO_OMIT)}
          {...value}
        >
          {props.children}
        </FormControl>
      )}
    </Component>
  );
}
