import { gql } from "@apollo/client";

export const COUNT_MESSAGES_QUERY = gql`
  query countMessages {
    countMessages {
      unread
      read
    }
  }
`;

export const COUNT_MESSAGES_SUBSCRIPTION = gql`
  subscription countMessages {
    countMessages {
      unread
      read
    }
  }
`;
