import type { CustomerLogoType } from "../types";

type PropsType = {
  type: "original" | "medium" | "thumb";
  logo: CustomerLogoType;
  alt?: string;
};

export const CustomerLogo = ({ type, logo, alt }: PropsType) => (
  <img src={logo[type]} alt={alt || ""} className={`TT-customer-logo ${type}`} />
);
