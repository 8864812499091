import { gql } from "@apollo/client";

import type { ContactInputType } from "../../types";
import { gqlDate } from "../../utils/dates";
import { EVENT_DETAIL_FRAGMENT_WO_CONTACT } from "../calendar";
import { JOB_RESULT_FRAGMENT } from "../jobs";
import { USER_FRAGMENT } from "../users";

export const valuesToContactInput = (values: any): ContactInputType => {
  const contact = { ...values };

  if (!contact.birthdate) {
    delete contact.birthdate;
  } else {
    contact.birthdate = gqlDate(contact.birthdate);
  }

  if (!contact.civilStatus) {
    delete contact.civilStatus;
  }

  if (contact.infos) {
    contact.infos = contact.infos.map((info: any) => {
      const value = { ...info };
      if (value.new) {
        delete value.id;
      }

      value.value = value.value.trim();

      delete value.new;
      return value;
    });
  }

  if (contact.addresses) {
    contact.addresses = contact.addresses.map((address: any) => {
      const value = { ...address };
      if (value.new) {
        delete value.id;
      }

      delete value.new;
      return value;
    });
  }

  return contact;
};

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on ContactAddress {
    id
    address
    atype
    city
    cityPart
    country
    description
    zip
  }
`;

export const INFO_FRAGMENT = gql`
  fragment InfoFragment on ContactInfo {
    id
    description
    itype
    normalizedValue
    value
  }
`;

export const CONTACT_DETAIL_FRAGMENT = gql`
  fragment ContactDetailFragment on Contact {
    id
    parentId
    ctype
    displayName
    isCompany
    salutation
    firstname
    lastname
    companyName
    position
    birthdate
    attrs
    insertedAt
    updatedAt
    statusId
    lockedAt
    lockRefreshedAt
    lockerId
    placeOfBirth
    civilStatus
  }
`;

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    ...ContactDetailFragment

    ownerId
    owner {
      ...UserFragment
    }

    locker {
      ...UserFragment
    }

    children {
      ...ContactDetailFragment

      infos {
        ...InfoFragment
      }

      addresses {
        ...AddressFragment
      }
    }

    status {
      id
      name
    }

    infos {
      ...InfoFragment
    }

    addresses {
      ...AddressFragment
    }

    primaryAddress {
      ...AddressFragment
    }

    events {
      ...EventDetailWoContact
    }
  }

  ${CONTACT_DETAIL_FRAGMENT}
  ${USER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${INFO_FRAGMENT}
  ${EVENT_DETAIL_FRAGMENT_WO_CONTACT}
`;

export const CONTACT_LIST_QUERY = gql`
  query contacts($customerId: ID!, $projectId: ID!, $filters: ContactsListFilters) {
    contacts(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      ctype
      parentId
      displayName
      firstname
      lastname
      companyName
      isCompany
      attrs

      parent {
        ...ContactFragment
      }

      lastContact {
        id
        insertedAt
      }

      nextFollowup {
        id
        allDay
        startTime
      }

      primaryAddress {
        id
        address
        zip
        city
      }

      statusId
      status {
        id
        name
      }

      project {
        id
        name
        identifier
      }

      lockerId
      locker {
        ...UserFragment
      }

      insertedAt
      updatedAt
    }
  }
  ${USER_FRAGMENT}
  ${CONTACT_FRAGMENT}
`;

export const CONTACT_LIST_W_ADDRESSES_QUERY = gql`
  query contacts($customerId: ID!, $projectId: ID!, $filters: ContactsListFilters) {
    contacts(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      parentId
      displayName
      firstname
      lastname
      companyName
      isCompany
      attrs

      lastContact {
        id
        insertedAt
      }

      nextFollowup {
        id
        allDay
        startTime
      }

      primaryAddress {
        id
        address
        zip
        city
      }

      addresses {
        id
        address
        zip
        city
      }

      statusId
      status {
        id
        name
      }

      project {
        id
        name
        identifier
      }

      lockerId
      locker {
        ...UserFragment
      }

      insertedAt
      updatedAt
    }
  }
  ${USER_FRAGMENT}
`;

export const COUNT_CONTACTS_QUERY = gql`
  query countContacts($customerId: ID!, $projectId: ID!, $filters: ContactsListFilters) {
    countContacts(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const CONTACT_LIST_W_INFOS_QUERY = gql`
  query contacts($customerId: ID!, $projectId: ID!, $filters: ContactsListFilters) {
    contacts(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      parentId
      displayName
      salutation
      firstname
      lastname
      companyName
      isCompany
      position
      attrs

      infos {
        ...InfoFragment
      }

      insertedAt
      updatedAt
    }
  }
  ${INFO_FRAGMENT}
`;

export const CONTACT_LIST_W_INFOS_AND_ADDRESSES_QUERY = gql`
  query contacts($customerId: ID!, $projectId: ID!, $filters: ContactsListFilters) {
    contacts(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      parentId
      displayName
      firstname
      lastname
      companyName
      isCompany
      attrs

      infos {
        ...InfoFragment
      }

      addresses {
        ...AddressFragment
      }

      events {
        ...EventDetailWoContact
      }

      children {
        id
        displayName

        infos {
          ...InfoFragment
        }

        addresses {
          ...AddressFragment
        }
      }

      insertedAt
      updatedAt
    }
  }
  ${INFO_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${EVENT_DETAIL_FRAGMENT_WO_CONTACT}
`;

export const CONTACT_GET_QUERY = gql`
  query contact($customerId: ID!, $projectId: ID!, $id: ID!) {
    contact(customerId: $customerId, projectId: $projectId, id: $id) {
      ...ContactFragment
      statusId
      editorId

      parent {
        id
        displayName
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const CONTACTS_SUBSCRIPTION = gql`
  subscription contacts($customerId: ID!, $projectId: ID!) {
    contacts(customerId: $customerId, projectId: $projectId) {
      ...ContactFragment
      statusId
      editorId
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const CONTACT_DELETE_MUTATION = gql`
  mutation deleteContact($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteContact(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      parentId
    }
  }
`;

export const CONTACT_MUTATION = gql`
  mutation mutateContact($customerId: ID!, $projectId: ID!, $id: ID, $contact: ContactInput!) {
    mutateContact(customerId: $customerId, projectId: $projectId, id: $id, contact: $contact) {
      ...ContactFragment
      statusId
      editorId
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const CONTACT_TRANSFER_MUTATION = gql`
  mutation transferContact($customerId: ID!, $projectId: ID!, $id: ID!, $ownerId: ID!) {
    transferContact(customerId: $customerId, projectId: $projectId, id: $id, ownerId: $ownerId) {
      ...ContactFragment
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const STATUS_FRAGMENT = gql`
  fragment StatusFragment on ContactStatus {
    id
    name
    position
    allowedAfterCall

    enforceFollowup
    followupEventTypeId
    defaultFollowupSubject
    defaultFollowupDays
  }
`;

export const STATES_QUERY = gql`
  query contactStates($customerId: ID!, $projectId: ID!, $filters: ContactStatesListFilters) {
    contactStates(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...StatusFragment
    }
  }
  ${STATUS_FRAGMENT}
`;

export const STATES_COUNT_QUERY = gql`
  query countContactStates($customerId: ID!, $projectId: ID!, $filters: ContactStatesListFilters) {
    countContactStates(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const STATUS_QUERY = gql`
  query contactStatus($customerId: ID!, $projectId: ID!, $id: ID!) {
    contactStatus(customerId: $customerId, projectId: $projectId, id: $id) {
      ...StatusFragment
    }
  }
  ${STATUS_FRAGMENT}
`;

export const STATUS_MUTATION = gql`
  mutation mutateContactStatus($customerId: ID!, $projectId: ID!, $status: ContactStatusInput!, $id: ID) {
    mutateContactStatus(customerId: $customerId, projectId: $projectId, status: $status, id: $id) {
      ...StatusFragment
    }
  }
  ${STATUS_FRAGMENT}
`;

export const STATUS_MOVE_MUTATION = gql`
  mutation moveContactStatus($customerId: ID!, $projectId: ID!, $id: ID!, $direction: MoveContactStatusDirection!) {
    moveContactStatus(customerId: $customerId, projectId: $projectId, id: $id, direction: $direction) {
      ...StatusFragment
    }
  }
  ${STATUS_FRAGMENT}
`;

export const STATUS_DELETE_MUTATION = gql`
  mutation deleteContactStatus($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteContactStatus(customerId: $customerId, projectId: $projectId, id: $id) {
      ...StatusFragment
    }
  }
  ${STATUS_FRAGMENT}
`;

export const CONTACT_LOCK_MUTATION = gql`
  mutation lockContact($customerId: ID!, $projectId: ID!, $id: ID!) {
    lockContact(customerId: $customerId, projectId: $projectId, id: $id) {
      contactId
      lockerId
      lockedAt
      lockRefreshedAt
    }
  }
`;

export const CONTACT_UNLOCK_MUTATION = gql`
  mutation unlockContact($customerId: ID!, $projectId: ID!, $id: ID!) {
    unlockContact(customerId: $customerId, projectId: $projectId, id: $id) {
      contactId
      lockerId
      lockedAt
      lockRefreshedAt
    }
  }
`;

export const CONTACT_ATTACHMENTS_LIST_QUERY = gql`
  query contactAttachments(
    $customerId: ID!
    $projectId: ID!
    $contactId: ID!
    $filters: ContactsAttachmentsListFilters
  ) {
    contactAttachments(customerId: $customerId, projectId: $projectId, contactId: $contactId, filters: $filters) {
      id
      file
      mimeType
      description
      contactId
      insertedAt
      updatedAt

      creator {
        ...UserFragment
      }
      editor {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const CONTACT_ATTACHMENTS_COUNT_QUERY = gql`
  query countContactAttachments($customerId: ID!, $projectId: ID!, $contactId: ID!) {
    countContactAttachments(customerId: $customerId, projectId: $projectId, contactId: $contactId)
  }
`;

export const CONTACT_ATTACHMENT_MUTATION = gql`
  mutation mutateContactAttachment(
    $customerId: ID!
    $projectId: ID!
    $contactId: ID!
    $id: ID
    $values: ContactAttachmentInput!
  ) {
    mutateContactAttachment(
      customerId: $customerId
      projectId: $projectId
      contactId: $contactId
      id: $id
      values: $values
    ) {
      file
      mimeType
      description
      insertedAt
      updatedAt

      creator {
        ...UserFragment
      }
      editor {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const CONTACT_ATTACHMENT_DELETE_MUTATION = gql`
  mutation deleteContactAttachment($customerId: ID!, $projectId: ID!, $contactId: ID!, $id: ID!) {
    deleteContactAttachment(customerId: $customerId, projectId: $projectId, contactId: $contactId, id: $id) {
      id
    }
  }
`;

export const CONTACTS_MERGE_MUTATION = gql`
  mutation mergeContacts($customerId: ID!, $projectId: ID!, $id: ID!, $merge: [ID]!) {
    mergeContacts(customerId: $customerId, projectId: $projectId, id: $id, merge: $merge) {
      id
    }
  }
`;

export const CONTACTS_PRIO_LIST_QUERY = gql`
  query contactsPrioList(
    $customerId: ID!
    $projectId: ID!
    $filters: ContactsPrioListFilters
    $followupDate: NaiveDate
    $followupCleared: Boolean
    $followupCanceled: Boolean
  ) {
    contactsPrioList(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      parentId
      displayName
      firstname
      lastname
      companyName
      isCompany
      attrs
      nextCallWindow

      lastContact {
        id
        insertedAt
      }

      followups(startDate: $followupDate, cleared: $followupCleared, canceled: $followupCanceled) {
        id
        allDay
        startTime
      }

      primaryAddress {
        id
        address
        zip
        city
      }

      statusId
      status {
        id
        name
      }

      project {
        id
        name
        identifier
      }

      lockedAt
      lockRefreshedAt
      locker {
        ...UserFragment
      }

      insertedAt
      updatedAt
    }
  }
  ${USER_FRAGMENT}
`;

export const CONTACTS_PRIO_LIST_WO_FOLLOWUPS_QUERY = gql`
  query contactsPrioList($customerId: ID!, $projectId: ID!, $filters: ContactsPrioListFilters) {
    contactsPrioList(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      parentId
      displayName
      firstname
      lastname
      companyName
      isCompany
      attrs
      nextCallWindow

      lastContact {
        id
        insertedAt
      }

      nextFollowup {
        id
        allDay
        startTime
      }

      primaryAddress {
        id
        address
        zip
        city
      }

      statusId
      status {
        id
        name
      }

      project {
        id
        name
        identifier
      }

      lockedAt
      lockRefreshedAt
      locker {
        ...UserFragment
      }

      insertedAt
      updatedAt
    }
  }
  ${USER_FRAGMENT}
`;

export const COUNT_CONTACTS_PRIO_LIST_QUERY = gql`
  query countContactsPrioList($customerId: ID!, $projectId: ID!, $filters: ContactsPrioListFilters) {
    countContactsPrioList(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const AGENDA_CONFIG_FRAGMENT = gql`
  fragment AgendaConfigFragment on AgendaConfig {
    id
    config
    from
    to

    insertedAt
    updatedAt
  }
`;

export const AGENDA_CONFIGS_QUERY = gql`
  query agendaConfigs($customerId: ID!, $projectId: ID) {
    agendaConfigs(customerId: $customerId, projectId: $projectId) {
      ...AgendaConfigFragment
    }
  }
  ${AGENDA_CONFIG_FRAGMENT}
`;

export const AGENDA_CONFIG_QUERY = gql`
  query agendaConfig($customerId: ID!, $projectId: ID, $id: ID!) {
    agendaConfig(customerId: $customerId, projectId: $projectId, id: $id) {
      ...AgendaConfigFragment
    }
  }
  ${AGENDA_CONFIG_FRAGMENT}
`;

export const AGENDA_CONFIG_MUTATION = gql`
  mutation mutateAgendaConfig($customerId: ID!, $projectId: ID, $id: ID, $values: AgendaConfigInput!) {
    mutateAgendaConfig(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...AgendaConfigFragment
    }
  }
  ${AGENDA_CONFIG_FRAGMENT}
`;

export const AGENDA_CONFIGS_DELETE_MUTATION = gql`
  mutation deleteAgendaConfig($customerId: ID!, $id: ID!) {
    deleteAgendaConfig(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const ADDFIELDS_QUERY = gql`
  query contactAddfields($customerId: ID!, $projectId: ID!, $filters: ContactAddfieldListFilters) {
    contactAddfields(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      name
      identifier
      automatic
      required
    }
  }
`;

export const ADDFIELDS_COUNT_QUERY = gql`
  query countContactAddfields($customerId: ID!, $projectId: ID!) {
    countContactAddfields(customerId: $customerId, projectId: $projectId)
  }
`;

export const ADDFIELD_QUERY = gql`
  query contactAddfield($customerId: ID!, $projectId: ID!, $id: ID!) {
    contactAddfield(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      identifier
      automatic
      required
    }
  }
`;

export const ADDFIELD_MUTATION = gql`
  mutation mutateContactAddfield($customerId: ID!, $projectId: ID!, $id: ID, $values: ContactAddfieldInput!) {
    mutateContactAddfield(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      id
      name
      identifier
      automatic
      required
    }
  }
`;

export const ADDFIELDS_DELETE_MUTATION = gql`
  mutation deleteContactAddfield($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteContactAddfield(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      identifier
      automatic
      required
    }
  }
`;

export const STATUS_UPDATE_FRAGMENT = gql`
  fragment StatusUpdateFragment on ContactStatusUpdate {
    id
    fromId
    from {
      id
      name
    }
    toId
    to {
      id
      name
    }
    afterDays
  }
`;

export const STATUS_UPDATES = gql`
  query contactStatusUpdates($customerId: ID!, $projectId: ID!, $filters: ContactStatusUpdateListFilters) {
    contactStatusUpdates(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...StatusUpdateFragment
    }
  }
  ${STATUS_UPDATE_FRAGMENT}
`;

export const STATUS_UPDATES_COUNT_QUERY = gql`
  query countContactStatusUpdates($customerId: ID!, $projectId: ID!) {
    countContactStatusUpdates(customerId: $customerId, projectId: $projectId)
  }
`;

export const STATUS_UPDATE_QUERY = gql`
  query contactStatusUpdate($customerId: ID!, $projectId: ID!, $id: ID!) {
    contactStatusUpdate(customerId: $customerId, projectId: $projectId, id: $id) {
      ...StatusUpdateFragment
    }
  }
  ${STATUS_UPDATE_FRAGMENT}
`;

export const STATUS_UPDATE_MUTATION = gql`
  mutation mutateContactStatusUpdate($customerId: ID!, $projectId: ID!, $values: ContactStatusUpdateInput!, $id: ID) {
    mutateContactStatusUpdate(customerId: $customerId, projectId: $projectId, values: $values, id: $id) {
      ...StatusUpdateFragment
    }
  }
  ${STATUS_UPDATE_FRAGMENT}
`;

export const STATUS_UPDATE_DELETE_MUTATION = gql`
  mutation deleteContactStatusUpdate($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteContactStatusUpdate(customerId: $customerId, projectId: $projectId, id: $id) {
      ...StatusUpdateFragment
    }
  }
  ${STATUS_UPDATE_FRAGMENT}
`;

export const CONTACTS_BATCH_ACTION_MUTATION = gql`
  mutation createContactsBatchAction(
    $customerId: ID!
    $projectId: ID!
    $action: ContactBatchAction!
    $filters: ContactsListFilters!
    $values: ContactBatchInput
  ) {
    createContactsBatchAction(
      customerId: $customerId
      projectId: $projectId
      action: $action
      filters: $filters
      values: $values
    ) {
      ...JobResultFragment
    }
  }
  ${JOB_RESULT_FRAGMENT}
`;

export const IMPORT_CONTACTS_MUTATION = gql`
  mutation importContacts($customerId: ID, $projectId: ID, $args: JSON!, $file: Upload!) {
    importContacts(customerId: $customerId, projectId: $projectId, args: $args, file: $file) {
      ...JobResultFragment
    }
  }
  ${JOB_RESULT_FRAGMENT}
`;
