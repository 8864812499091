import { useEffect } from "react";

import _ from "lodash";
import { NavigateFunction, useNavigate } from "react-router-dom";

const genChangeFunction = (url: string, navigate: NavigateFunction) => {
  return (ev: Event) => {
    const detail = (ev as CustomEvent).detail;
    const rx = new RegExp(`^/${detail.oldCustomer.identifier}/${detail.oldProject.identifier}(?=/|$)`);
    const newUrl = url.replace(rx, `/${detail.newCustomer.identifier}/${detail.newProject.identifier}`);
    navigate(newUrl);
  };
};

export default function useCustomerChange(url: CustomerChangeArgument) {
  const navigate = useNavigate();
  const handler = _.isString(url) ? genChangeFunction(url, navigate) : url;

  useEffect(() => {
    document.addEventListener("tt:customerOrProjectChanged", handler);
    return () => document.removeEventListener("tt:customerOrProjectChanged", handler);
  });
}
