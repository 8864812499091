import { Trans, useTranslation } from "react-i18next";

import User from "../../../containers/User";
import type { DocumentType } from "../../../types";
import { dateTimeFormat } from "../../../utils/dates";

type PropsType = {
  document: DocumentType;
};

export default function DocumentOverview({ document }: PropsType) {
  const { t } = useTranslation(["documents"]);

  return (
    <>
      <h3>{document.subject}</h3>
      <span className="TT-documents-list-type">{t(`documents:document_types.${document.type}` as const)}</span>

      <span className="TT-documents-list-project">
        <span className="caption">{t("documents:list.belongs_to")}</span>
        {document.customer.name} / {document.project.name}
      </span>

      {document.relevantProjectId !== document.projectId && (
        <span className="TT-documents-relevant-project">
          <span className="caption">{t("documents:list.relevant_for")}</span>
          {document.relevantProject.customer.name} / {document.relevantProject.name}
        </span>
      )}

      <span className="TT-documents-list-keywords">{document.keywords.join(", ")}</span>
      <Trans parent="span" className="TT-documents-list-created" t={t} i18nKey="documents:list.created">
        <span className="caption">erstellt</span> {{ date: dateTimeFormat(document.insertedAt) }} von{" "}
        <User user={document.creator} />
      </Trans>
      <Trans parent="span" className="TT-documents-list-updated" t={t} i18nKey="documents:list.edited">
        <span className="caption">geändert</span> {{ date: dateTimeFormat(document.updatedAt) }} von{" "}
        <User user={document.editor} />
      </Trans>
    </>
  );
}
