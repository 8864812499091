import { SessionInterface } from "../store/sessionReducer";
import type { MessageInterface, PermissionType } from "../types";
import { userFeatureEnabled } from "./helpers";

export interface MessageMayInterface {
  (session: SessionInterface, path: "messages"): boolean;
  (session: SessionInterface, path: "messages", action: "new"): boolean;
  (session: SessionInterface, path: "messages", action: "show" | "edit" | "delete", group: MessageInterface): boolean;
}

const MessagePermissions: PermissionType<MessageInterface> = {
  messages: {
    index: ({ currentUser }) => userFeatureEnabled(currentUser, "messages"),
  },
};

export default MessagePermissions;
