import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import type { Nilable } from "../../types";

export interface KnowledgebaseReducerInterface {
  search: string;
  archived: boolean;
  filterAttrs: {
    [key: string]: string | boolean;
  };
}

const initialState: KnowledgebaseReducerInterface = {
  search: "",
  archived: false,
  filterAttrs: {},
};

const knowledgebaseSlice = createSlice({
  name: "knowledgebase",
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setArchived(state, action: PayloadAction<boolean>) {
      state.archived = action.payload;
    },
    setAttributeFilter(state, action: PayloadAction<{ name: string; value: Nilable<string | boolean> }>) {
      if (action.payload.value === null || action.payload.value === undefined) {
        delete state.filterAttrs[action.payload.name];
      } else {
        state.filterAttrs[action.payload.name] = action.payload.value;
      }
    },
    resetFilters(state) {
      state.search = "";
      state.archived = false;
      state.filterAttrs = {};
    },
  },
});

export const { setSearch, setArchived, setAttributeFilter, resetFilters } = knowledgebaseSlice.actions;
export const knowledgebaseSelector = (state: RootState) => state.knowledgebase;
export default knowledgebaseSlice.reducer;
