import { useRef, useState } from "react";

import _ from "lodash";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { Document, type DocumentProps, Page, pdfjs } from "react-pdf";

const PDF_VIEWER_OPTIONS = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

type PropsType = {
  file: DocumentProps["file"];
  width?: number;
  height?: number;
  scale?: number;
};

const A4_WIDTH = 595;
const A4_HEIGHT = 842;
const A4_RATIO = A4_WIDTH / A4_HEIGHT;

export default function PdfViewer({ file, width, height, scale }: PropsType) {
  const [numPages, setNumPages] = useState<number>(0);
  const container = useRef<HTMLDivElement>(null);

  function loadSuccess(pdf: PDFDocumentProxy) {
    setNumPages(pdf.numPages);
  }

  const realWidth = width || container.current?.clientWidth || A4_WIDTH;
  const realHeight = height || (container.current?.clientWidth ? container.current?.clientWidth * A4_RATIO : A4_HEIGHT);

  return (
    <Document options={PDF_VIEWER_OPTIONS} file={file} onLoadSuccess={loadSuccess} inputRef={container}>
      {_.times(numPages, (i) => (
        <div key={i} className="TT-pdf-viewer-page">
          <Page pageNumber={i + 1} width={realWidth} height={realHeight} scale={scale} />

          <p className="TT-pdf-viewer-page-no">Seite {i + 1}</p>
        </div>
      ))}
    </Document>
  );
}
