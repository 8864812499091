import { useMutation } from "@apollo/client";

import { Form } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { DOCUMENT_ATTACHMENT_MUTATION } from "../../../../api/documents";
import handleError, { MutationError } from "../../../../handleError";
import { useAppDispatch } from "../../../../hooks";
import type { DocumentAttachmentMutationInterface } from "../../../../types";
import { addDangerFlash } from "../../../flash/flashReducer";
import { DocumentFormProps } from "../../utils";

export default function CustomerDocumentForm({ document }: DocumentFormProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["translation", "documents"]);
  const [mutateDocumentAttachment] = useMutation<DocumentAttachmentMutationInterface>(DOCUMENT_ATTACHMENT_MUTATION, {
    refetchQueries: ["document"],
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      try {
        const { data } = await mutateDocumentAttachment({
          variables: {
            customerId: document.customerId,
            projectId: document.projectId,
            documentId: document.id,
            id: document.attachments[0]?.id,
            values: {
              file: files[0],
            },
          },
        });

        if (!data?.mutateDocumentAttachment) {
          throw new MutationError();
        }
      } catch (e) {
        dispatch(addDangerFlash(t("translation:global.general_error")));
        handleError(e);
      }
    },
  });

  return (
    <fieldset>
      <legend>{t("translation:global.file")}</legend>

      <div className="TT-file-dropzone">
        <section>
          <div {...getRootProps({ className: "dropzone" })}>
            <Form.Label>{t("documents:form.choose_file")}</Form.Label>
            <input id="file" {...getInputProps()} />
          </div>

          {!!document.attachments.length && (
            <aside>{t("documents:form.file_chosen", { name: document.attachments[0].file })}</aside>
          )}
        </section>
      </div>
    </fieldset>
  );
}
