import { useAppSelector } from "../../../hooks";
import { documentsSelector } from "../documentsReducer";
import type { DocumentsListPropsInterface } from "../types";
import CompactList from "./CompactList";
import DetailedList from "./DetailedList";

export default function DocumentsList(props: DocumentsListPropsInterface) {
  const { detailedView } = useAppSelector(documentsSelector);

  return detailedView ? <DetailedList {...props} /> : <CompactList {...props} />;
}
