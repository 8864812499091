import Highcharts from "highcharts";

const initHighcharts = (lang: Highcharts.LangOptions) => {
  Highcharts.setOptions({
    lang,
    credits: {
      enabled: false,
    },
  });
};

export default initHighcharts;
