import { useQuery } from "@apollo/client";

import clsx from "clsx";

import { CUSTOMER_LIST_WITH_CUSTOMER_ADMINISTATORS_QUERY } from "../../../api/customers";
import { useAppSelector } from "../../../hooks";
import type { CustomerInterface, CustomersDataInterface, Nilable } from "../../../types";
import Customer from "./Customer";
import { switcherSelector } from "./switcherReducer";

type PropsType = {
  chooseCustomer: (customer: CustomerInterface) => void;
  chosenCustomer: Nilable<CustomerInterface>;
};

export default function CustomerList({ chooseCustomer, chosenCustomer }: PropsType) {
  const { onlyActive } = useAppSelector(switcherSelector);

  const { data } = useQuery<CustomersDataInterface>(CUSTOMER_LIST_WITH_CUSTOMER_ADMINISTATORS_QUERY, {
    variables: { filters: { active: onlyActive ? true : undefined } },
  });

  if (!data?.customers) return null;

  return (
    <ul className="TT-switcher-customer-list">
      {data.customers.map((c) => (
        <li key={c.id} className={clsx("TT-switcher-customer", { active: chosenCustomer?.id === c.id })}>
          <Customer tag="button" onClick={chooseCustomer} customer={c} />
        </li>
      ))}
    </ul>
  );
}
