import { TTDict } from "../../../types";

export const CONTACT_TYPE_OPTIONS = ["Outbound", "Inbound", "E-Mail", "Datensortierung"].map((opt) => ({
  value: opt,
  label: opt,
}));

export const CATEGORY_OPTIONS = ["Versand Lieferstatus", "Allgemeine Anfrage"].map((opt) => ({
  value: opt,
  label: opt,
}));

export const SPOKEN_PERSON_OPTIONS: TTDict<string, string[]> = {
  Outbound: ["Entscheider", "Firmenmitarbeiter", "falsche Daten"],
  Inbound: ["Entscheider", "Firmenmitarbeiter"],
};

export const INTERESTED_OPTIONS = ["Interesse", "Interesse an anderer Lösung", "kein Interesse", "unentschlossen"];

export const NOT_INTERESTED_REASON_OPTIONS = [
  "Funktionsumfang",
  "Preis/Leistung",
  "keine Buchungabsicht gehabt",
  "bleibt beim aktuellen Anbieter",
  "Unternehmensform ungeeignet",
  "Mitbewerber/Konkurrenz gebucht",
  "Bereits Bestandskunde",
  "Sonstiges",
].map((opt) => ({ value: opt, label: opt }));

export const EMAIL_CONTACT_TYPES = ["beantwortet", "Kontaktaufnahme"];

export const STATUS_VALUE_MAPPING: TTDict<string, TTDict<string, string>> = {
  "attrs.reached": {
    "nicht erreicht": "On Hold",
  },
  "attrs.reclamation": {
    true: "Reklamation",
  },
  "attrs.interested": {
    Interesse: "Übergabe Closing",
    "kein Interesse": "Kein Interesse",
    "Interesse an anderer Lösung": "Angebot angefordert",
    unentschlossen: "Wiedervorlage",
  },
  "attrs.contactUnwanted": {
    true: "Kontakt unerwünscht",
    false: "Wiedervorlage",
  },
  "attrs.spokenPerson": {
    Firmenmitarbeiter: "On Hold",
    "falsche Daten": "falsche Daten",
  },
  "attrs.notInterestedReason": {
    "Unternehmensform ungeeignet": "Lead ungeeignet",
    Sonstiges: "kein Interesse",
  },
  "attrs.dataSortingReason": {
    "Lead ungeeignet laut letztem Kontakt": "Lead ungeeignet",
    "Lead aus dem Ausland": "Lead ungeeignet",
    "Dublette mit unterschiedlicher ID": "Dublette",
    "Dublette mit identischer ID": "Dublette",
  },
};

export const PRODUCT_OPTIONS = ["Fibu", "Lobu", "Jahres-Archiv-Datei Abo"].map((opt) => ({
  value: opt,
  label: opt,
}));

export const PLAN_OPTIONS = ["Basic", "Plus", "Premium"].map((opt) => ({
  value: opt,
  label: opt,
}));

export const ADDONS = [
  "abs.Portal",
  "abs.Portal+digitale Personalakten",
  "abs.Portal+Zeiterfassung",
  "abs.Portal All-in-One",
];

export const ABS_TODO_USER = "2870";

export const DATA_SORTING_REASON = [
  "Lead ungeeignet laut letztem Kontakt",
  "Lead aus dem Ausland",
  "Dublette mit unterschiedlicher ID",
  "Dublette mit identischer ID",
];
