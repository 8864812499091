import React from "react";

import clsx from "clsx";
import { connect, FormikProps, getIn } from "formik";

function ErrorMessage({ formik, path, className }: { formik?: FormikProps<{}>; path: string; className?: string }) {
  const error = getIn(formik!.errors, path);
  if (!error || !getIn(formik!.touched, path)) {
    return null;
  }

  return <div className={clsx("TT-error-feedback", className)}>{error}</div>;
}

export default connect(React.memo(ErrorMessage));
