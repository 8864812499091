import { SessionInterface } from "../store/sessionReducer";
import type { PermissionType, TaskInterface } from "../types";
import { featureEnabled, isCustomerAdmin, projectFeatureEnabled, userFeatureEnabled } from "./helpers";

export interface CustomerContactMayInterface {
  (session: SessionInterface, path: "customer-contacts"): boolean;
  (
    session: SessionInterface,
    path: "customer-contacts",
    action: "new" | "filterUsers" | "offers" | "manageOffers" | "audit" | "export" | "send" | "newInModule",
  ): boolean;
  (
    session: SessionInterface,
    path: "customer-contacts",
    action: "show" | "edit" | "delete",
    cc: TaskInterface,
  ): boolean;
  (session: SessionInterface, path: "customer-contacts", action: "send", cc?: TaskInterface): boolean;
}

const CC_PERMISSIONS: PermissionType<TaskInterface> = {
  "customer-contacts": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "customer_contacts"),
    newInModule: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "customer_contacts") &&
      projectFeatureEnabled(currentProject, "customer_contacts/new"),
    new: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "customer_contacts"),
    delete: ({ currentUser, currentProject, currentCustomer }) =>
      isCustomerAdmin(currentUser, currentCustomer) && featureEnabled(currentUser, currentProject, "customer_contacts"),
    export: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "customer_contacts") &&
      userFeatureEnabled(currentUser, "customer_contacts/export"),
    audit: ({ currentUser, currentProject, currentCustomer }) =>
      isCustomerAdmin(currentUser, currentCustomer) && featureEnabled(currentUser, currentProject, "customer_contacts"),
    send: ({ currentUser, currentProject }, resource) =>
      featureEnabled(currentUser, currentProject, "customer_contacts") &&
      userFeatureEnabled(currentUser, "customer_contacts/send") &&
      !resource?.sent,
    filterUsers: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "customer_contacts") &&
      userFeatureEnabled(currentUser, "customer_contacts/filterUser"),
    offers: ({ currentProject }) => projectFeatureEnabled(currentProject, "customer_contacts/offers"),
    manageOffers: ({ currentUser, currentProject }) =>
      projectFeatureEnabled(currentProject, "customer_contacts/offers") &&
      userFeatureEnabled(currentUser, "customer_contacts/manageOffers"),
  },
};

export default CC_PERMISSIONS;
