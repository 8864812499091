import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../store";
import type { AuditActionType, AuditRelationType, Nullable, UserInterface } from "../../../types";

export interface auditReducerInterface {
  search: Nullable<string>;
  relation: Nullable<AuditRelationType>;
  user: Nullable<UserInterface>;
  action: Nullable<AuditActionType>;
  start: Nullable<Date>;
  stop: Nullable<Date>;
}

const initialState: auditReducerInterface = {
  search: null,
  relation: null,
  user: null,
  action: null,
  start: null,
  stop: null,
};

const auditSlice = createSlice({
  name: "admin/audit",
  initialState,
  reducers: {
    resetFilters(state) {
      state.search = null;
      state.relation = null;
      state.user = null;
      state.start = null;
      state.stop = null;
      state.action = null;
    },
    setSearch(state, { payload }: PayloadAction<Nullable<string>>) {
      state.search = payload;
    },
    setRelation(state, action: PayloadAction<Nullable<AuditRelationType>>) {
      state.relation = action.payload;
    },
    setUser(state, { payload }: PayloadAction<Nullable<UserInterface>>) {
      state.user = payload;
    },
    setAction(state, action: PayloadAction<Nullable<AuditActionType>>) {
      state.action = action.payload;
    },
    setStart(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.start = payload;
    },
    setStop(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.stop = payload;
    },
  },
});

export const { setRelation, setUser, setAction, setStart, setStop, resetFilters, setSearch } = auditSlice.actions;
export const auditSelector = (state: RootState) => state.audit;
export default auditSlice.reducer;
