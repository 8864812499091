import { Card, CardProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type PropsType = {
  data?: any[];
} & CardProps;

const NoDataCard = ({ data, ...props }: PropsType) => {
  const { t } = useTranslation(["translation"]);

  if (!!data?.length) return null;

  return (
    <Card {...props}>
      <Card.Body>
        <Card.Text>{t("translation:global.no_data")}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default NoDataCard;
