import { RenderElementProps } from "slate-react";

export const Image = ({ attributes, children, element }: RenderElementProps) => {
  return (
    <div {...attributes}>
      {children}
      <div className="TT-slate-image">
        <img src={element.url} alt="" />
      </div>
    </div>
  );
};
