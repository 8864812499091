import React from "react";

import { Button } from "react-bootstrap";
import { MdEdit } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const EditButton: TTButtonProps = React.memo(({ children, ...props }) => (
  <Button variant="secondary" {...props}>
    <MdEdit /> {children}
  </Button>
));

export default EditButton;
