import * as Sentry from "@sentry/react";

import { GraphQLFormattedError } from "graphql";

import type { Nilable, TTMutationResultType } from "./types";

export class MutationError extends Error {
  result: Nilable<TTMutationResultType>;

  constructor(result: Nilable<TTMutationResultType> = undefined) {
    const errors = result?.messages.map((msg) => `${msg.field}: ${msg.message}`).join("\n") || "unknown error";
    super("Mutation failed:\n" + errors);
    this.result = result;
  }
}

function includesPathToIgnore(gqlErrors: GraphQLFormattedError[]) {
  const pathsToIgnore = ["login", "refresh"];

  if (gqlErrors.some((gqlE) => gqlE?.path?.some((path) => typeof path === "string" && pathsToIgnore.includes(path)))) {
    return true;
  }

  return false;
}

export default function handleError(e: any, rethrow: boolean = true) {
  const ignoreError = includesPathToIgnore(e?.graphQLErrors || []);

  if (ignoreError) {
    return;
  }

  if (typeof e === "string") {
    e = new Error(e);
    if ("captureStackTrace" in Error) Error.captureStackTrace(e, handleError);
  } else if (e.stack && "captureStackTrace" in Error) {
    const newE = new Error();
    Error.captureStackTrace(newE, handleError);
    e.stack = newE.stack + e.stack;
  }

  console.log(e);

  if (rethrow) {
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(e);
    } else {
      throw e;
    }
  }
}
