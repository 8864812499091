import _ from "lodash";

import { NoDataTag } from "../../../containers/NoData";
import type { DocumentsListPropsInterface } from "../types";
import DocumentsListItem from "./DocumentsListItem";

export default function DetailedList({ documents, deleteDocument, showDocument }: DocumentsListPropsInterface) {
  return (
    <ul className="TT-documents-list">
      <NoDataTag data={documents} />

      {_.map(documents, (document) => (
        <DocumentsListItem
          key={document.id}
          document={document}
          deleteDocument={deleteDocument}
          showDocument={showDocument}
        />
      ))}
    </ul>
  );
}
