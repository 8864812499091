import clsx from "clsx";

import Spinner from "./Spinner";

interface PropsInterface {
  backdrop?: boolean;
  fullscreen?: boolean;
  stretch?: boolean;
  translucent?: boolean;
  absolute?: boolean;
}

export default function Loading({ backdrop, fullscreen, stretch, translucent, absolute }: PropsInterface) {
  const classList = clsx("TT-loading-screen", {
    backdrop,
    fullscreen,
    stretch,
    translucent,
    absolute,
  });

  return (
    <div className={classList}>
      <Spinner color={backdrop ? "#fff" : "#000"} />
    </div>
  );
}
