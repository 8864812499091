import { SessionInterface } from "../store/sessionReducer";
import type { PermissionType, ProjectAttachmentType, UploadType } from "../types";
import { featureEnabled, isCustomerAdmin, projectFeatureEnabled } from "./helpers";

export interface ProjectMayInterface {
  (session: SessionInterface, path: "project/uploads"): boolean;
  (session: SessionInterface, path: "project/uploads", action: "new"): boolean;
  (session: SessionInterface, path: "project/uploads", action: "show" | "edit" | "delete", group: UploadType): boolean;

  (session: SessionInterface, path: "project/hotseat"): boolean;

  (session: SessionInterface, path: "project/attachments"): boolean;
  (session: SessionInterface, path: "project/attachments", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "project/attachments",
    action: "edit" | "delete",
    group: ProjectAttachmentType,
  ): boolean;
}

const UPLOAD_PERMISSIONS: PermissionType<UploadType> = {
  "project/uploads": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "uploads"),
  },
};

const HOT_SEAT_PREMISSIONS: PermissionType<any> = {
  "project/hotseat": {
    index: ({ currentProject, currentUser }) =>
      projectFeatureEnabled(currentProject, "hotseat") &&
      currentUser.customerId === "1" &&
      currentProject.attrs.hotseat_link,
  },
};

const ATTACHMENT_PREMISSIONS: PermissionType<ProjectAttachmentType> = {
  "project/attachments": {
    index: ({ currentCustomer, currentUser }) =>
      currentUser.customerId === "1" && isCustomerAdmin(currentUser, currentCustomer),
  },
};

const ProjectPermissions = {
  ...UPLOAD_PERMISSIONS,
  ...HOT_SEAT_PREMISSIONS,
  ...ATTACHMENT_PREMISSIONS,
};

export default ProjectPermissions;
