import React from "react";

import clsx from "clsx";

import type { AvatarInterface } from "../types";

export type Type = "original" | "medium" | "thumb";

type UserAVatarProps = {
  type: Type;
  avatar: AvatarInterface;
  alt?: string;
  className?: string;
};

function UserAvatar({ type, avatar, alt, className }: UserAVatarProps) {
  return <img src={avatar[type]} alt={alt || ""} className={clsx("TT-user-avatar", type, className)} />;
}

export default React.memo(UserAvatar);
