import { gql } from "@apollo/client";

const CONTACT_GROUP_DETAIL_FRAGMENT = gql`
  fragment ContactGroupDetail on ContactGroup {
    id
    name
    gtype
    attrs
    insertedAt
    updatedAt
  }
`;

export const CONTACT_GROUPS_LIST_QUERY = gql`
  query contactGroups($customerId: ID!, $projectId: ID!) {
    contactGroups(customerId: $customerId, projectId: $projectId) {
      ...ContactGroupDetail
    }
  }
  ${CONTACT_GROUP_DETAIL_FRAGMENT}
`;

export const CONTACT_GROUPS_GET_QUERY = gql`
  query contactGroup($customerId: ID!, $projectId: ID!, $id: ID!) {
    contactGroup(customerId: $customerId, projectId: $projectId, id: $id) {
      ...ContactGroupDetail
    }
  }
  ${CONTACT_GROUP_DETAIL_FRAGMENT}
`;

export const CONTACT_GROUP_DELETE_MUTATION = gql`
  mutation deleteContactGroup($customerId: ID!, $projectId: ID!, $id: ID!, $withContacts: Boolean!) {
    deleteContactGroup(customerId: $customerId, projectId: $projectId, id: $id, withContacts: $withContacts) {
      id
    }
  }
`;

export const CONTACT_GROUP_MUTATION = gql`
  mutation mutateContactGroup($customerId: ID!, $projectId: ID!, $id: ID, $group: ContactGroupInput!) {
    mutateContactGroup(customerId: $customerId, projectId: $projectId, id: $id, group: $group) {
      ...ContactGroupDetail
    }
  }
  ${CONTACT_GROUP_DETAIL_FRAGMENT}
`;
