import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { endOfDay, startOfDay } from "date-fns";

import { RootState } from "../../../store";
import {
  AttributeFilterType,
  Nilable,
  Nullable,
  TeamInterface,
  TodoInterface,
  TodoStatusType,
  UserInterface,
} from "../../../types";

export type TodosReducerType = {
  status: Nullable<TodoStatusType>;
  user: Nullable<UserInterface>;
  team: Nullable<TeamInterface>;
  start: Nullable<Date>;
  stop: Nullable<Date>;
  filterAttrs: Record<string, AttributeFilterType>;
  predefinedFilters: string[];
  todoStatus: Nilable<TodoInterface["status"] | "UNDECIDED">;
  search: string;
};

const initialState: TodosReducerType = {
  status: "NOT_DONE",
  user: null,
  team: null,
  start: null,
  stop: null,
  filterAttrs: {},
  predefinedFilters: [],
  todoStatus: null,
  search: "",
};

const todosSlice = createSlice({
  name: "todos/todos",
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<Nullable<TodoStatusType>>) {
      state.status = action.payload;
    },
    setUser(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.user = action.payload;
    },
    setTeam(state, action: PayloadAction<Nullable<TeamInterface>>) {
      state.team = action.payload;
    },
    setStart(state, action: PayloadAction<Nullable<Date>>) {
      state.start = action.payload ? startOfDay(action.payload) : null;
    },
    setStop(state, action: PayloadAction<Nullable<Date>>) {
      state.stop = action.payload ? endOfDay(action.payload) : null;
    },

    setAttributeFilter(state, action: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (!action.payload.value) {
        delete state.filterAttrs[action.payload.name];
      } else {
        state.filterAttrs[action.payload.name] = action.payload.value;
      }
    },

    addPredefinedFilter(state, action: PayloadAction<string>) {
      state.predefinedFilters.push(action.payload);
    },

    removePredefinedFilter(state, action: PayloadAction<string>) {
      state.predefinedFilters = state.predefinedFilters.filter((filter) => filter !== action.payload);
    },

    setTodoStatus(state, action: PayloadAction<Nilable<TodoInterface["status"] | "UNDECIDED">>) {
      state.todoStatus = action.payload;
    },

    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },

    resetFilters(state) {
      state.status = "NOT_DONE";
      state.user = null;
      state.team = null;
      state.start = null;
      state.stop = null;
      state.filterAttrs = {};
      state.predefinedFilters = [];
      state.todoStatus = null;
      state.search = "";
    },
  },
});

export const {
  setStatus,
  setUser,
  setTeam,
  setStart,
  setStop,
  setAttributeFilter,
  resetFilters,
  addPredefinedFilter,
  removePredefinedFilter,
  setTodoStatus,
  setSearch,
} = todosSlice.actions;
export const todosSelector = (state: RootState) => state.todos.todos;
export default todosSlice.reducer;
