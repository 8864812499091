import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import type { ContactInterface, Nullable } from "../../types";

export interface DialerReducerInterface {
  showDialer: boolean;
  no: Nullable<string>;
  contact: null | ContactInterface;
}

const initialState: DialerReducerInterface = {
  showDialer: false,
  no: "",
  contact: null,
};

const dialerSlice = createSlice({
  name: "dialer",
  initialState,
  reducers: {
    showDialer(state, action: PayloadAction<{ no?: string; contact?: ContactInterface }>) {
      state.showDialer = true;
      state.contact = action.payload.contact || null;
      state.no = action.payload.no || null;
    },
    hideDialer(state) {
      state.showDialer = false;
      state.no = null;
    },
    toggleDialer(state) {
      state.showDialer = !state.showDialer;
    },
  },
});

export const { showDialer, hideDialer, toggleDialer } = dialerSlice.actions;
export const dialerSelector = (state: RootState) => state.dialer;
export default dialerSlice.reducer;
