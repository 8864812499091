import { API_URI } from "../api/session";
import { SessionInterface } from "../store/sessionReducer";
import type { QueryStringType, TodoInterface } from "../types";
import { queryString, sessionPrefix } from "./helpers";

export const todosPath = (session: SessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/todos${queryString(args)}`;
export const newTodoPath = (session: SessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/todos/new${queryString(args)}`;
export const editTodoPath = (session: SessionInterface, todo: TodoInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/todos/${todo.id}/edit${queryString(args)}`;
export const showTodoPath = (session: SessionInterface, todo: TodoInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/todos/${todo.id}${queryString(args)}`;

export const todoAttachmentUrl = ({ currentCustomer, currentProject }: SessionInterface, todo: TodoInterface) =>
  `${API_URI}/todos/${todo.id}?customer_id=${currentCustomer.id}&project_id=${currentProject.id}`;
