import { useEffect, useState } from "react";

import { saveAs } from "file-saver";
import Mime from "mime";
import { useTranslation } from "react-i18next";

import { getAuthorizationToken } from "../../../authorization_token";
import { DownloadButton } from "../../../containers/buttons";
import { documentAttachmentPath } from "../../../path_helpers/document_paths";
import type { DocumentAttachmentType, Nullable } from "../../../types";
import { dateFormat } from "../../../utils/dates";
import PdfViewer from "../../PdfViewer";
import type { DocumentSubcompProps } from "../types";
import { DOCUMENT_FORMS } from "../utils";

async function downloadAttachment(attachment: DocumentAttachmentType) {
  const rsp = await fetch(documentAttachmentPath(attachment), {
    method: "get",
    cache: "no-cache",
    credentials: "same-origin",
    referrer: "no-referrer",
  });

  const blob = await rsp.blob();
  saveAs(blob, attachment.file);
}

export default function CustomerDocument({ document }: DocumentSubcompProps) {
  const [file, setFile] = useState<Nullable<Blob>>(null);
  const [Icon] = DOCUMENT_FORMS[document.type];
  const { t } = useTranslation(["documents", "translation"]);

  const type = Mime.getType(document.attachments[0]?.file);

  useEffect(() => {
    (async () => {
      if (document.attachments.length > 0) {
        const type = Mime.getType(document.attachments[0].file);

        if (type && type.match(/^(image\/|application\/pdf)/)) {
          const rsp = await fetch(documentAttachmentPath(document.attachments[0]), {
            method: "get",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { Authorization: "Bearer " + getAuthorizationToken() },
            referrer: "no-referrer",
          });

          if (!rsp.ok) {
            throw rsp;
          }

          const blob = await rsp.blob();
          setFile(blob);
        }
      }
    })();
  }, [document.attachments]);

  return (
    <div className="TT-documents-document-customer-document">
      <header>
        <img
          src={document.relevantProject.customer.logo.medium}
          alt={document.relevantProject.customer.name}
          className="logo"
        />
        <div className="type">{t(`documents:document_types.${document.type}` as const)}</div>
        <div className="icon">
          <Icon />
        </div>
        <h2>{document.subject}</h2>
      </header>

      <div className="meta-info">
        <div>
          <strong>{t("documents:field_names.id")}</strong> {document.id}
        </div>
        <div>
          <strong>{t("documents:field_names.valid_from")}</strong>
          {document.validFrom ? dateFormat(document.validFrom) : "-"}
        </div>
        <div>
          <strong>{t("documents:field_names.valid_until")}</strong>
          {document.validUntil ? dateFormat(document.validUntil) : "-"}
        </div>
        <div>
          <strong>{t("translation:global.created")}</strong>
          {dateFormat(document.insertedAt)}
        </div>
        <div>
          <strong>{t("translation:global.updated")}</strong>
          {dateFormat(document.updatedAt)}
        </div>
      </div>

      {!!document.attachments.length && (
        <>
          {!!file && type === "application/pdf" && <PdfViewer file={URL.createObjectURL(file)} />}
          {!!file && type !== "application/pdf" && (
            <iframe
              className="TT-documents-inline-blob-preview"
              src={URL.createObjectURL(file)}
              title={t("translation:global.preview")}
            ></iframe>
          )}

          <DownloadButton onClick={() => downloadAttachment(document.attachments[0])}>
            {document.attachments[0].file}
          </DownloadButton>
        </>
      )}
    </div>
  );
}
