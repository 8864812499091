import Notifications from "./Notifications";
import Version from "./Version";
import AutomaticallyReloaded from "./Version/AutomaticallyReloaded";

export default function BottomBar() {
  return (
    <div className="bottom-bar">
      {process.env.NODE_ENV === "production" && <Version />}
      <Notifications />
      <AutomaticallyReloaded />
    </div>
  );
}
