import "./customer-locked.scss";

import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../store/sessionReducer";

export default function CustomerLocked() {
  const { currentCustomer } = useAppSelector(sessionSelector);
  const { t } = useTranslation(["translation"]);

  return (
    <main className="TT-customer-locked">
      <h1>{t("translation:customer_locked.heading")}</h1>

      <ReactMarkdown
        children={currentCustomer.attrs.lock_reason}
        components={{
          h1: "h2",
        }}
      />
    </main>
  );
}
