import { SessionInterface } from "../store/sessionReducer";
import type { QueryStringType, TaskInterface, TaskOfferInterface, TaskProductInterface } from "../types";
import { queryString, sessionPrefix } from "./helpers";

export const customerContactsPath = (session: SessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/customer-contacts${queryString(args)}`;
export const newCustomerContactPath = (session: SessionInterface, args = {}) =>
  `${sessionPrefix(session)}/customer-contacts/new${queryString(args)}`;
export const editCustomerContactPath = (session: SessionInterface, cc: TaskInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/customer-contacts/${cc.id}/edit${queryString(args)}`;
export const showCustomerContactPath = (session: SessionInterface, cc: TaskInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/customer-contacts/${cc.id}${queryString(args)}`;
export const exportCustomerContactPath = (session: SessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/customer-contacts/export${queryString(args)}`;

export const CustomerContactsAuditPath = (session: SessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/customer-contacts/audit${queryString(args)}`;

export const tasksOffersPath = (session: SessionInterface) => `${sessionPrefix(session)}/customer-contacts/offers`;
export const newTasksOfferPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/customer-contacts/offers/new`;
export const editTasksOfferPath = (session: SessionInterface, offer: TaskOfferInterface) =>
  `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}/edit`;
export const showTasksOfferPath = (session: SessionInterface, offer: TaskOfferInterface) =>
  `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}`;

export const newTasksProductPath = (session: SessionInterface, offer: TaskOfferInterface) =>
  `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}/products/new`;
export const editTasksProductPath = (
  session: SessionInterface,
  offer: TaskOfferInterface,
  product: TaskProductInterface,
) => `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}/products/edit/${product.id}`;
export const deleteTasksProductPath = (
  session: SessionInterface,
  offer: TaskOfferInterface,
  product: TaskProductInterface,
) => `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}/products/delete/${product.id}`;
