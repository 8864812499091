import { Channel, Socket } from "phoenix";

import acdEndpointApollo from "../acdEndpointApollo";
import * as Api from "../api";
import { ACD_CURRENT_ENDPOINT_STATUS, ACD_CURRENT_STATE_QUERY } from "../api/acd";
import apolloClient from "../apollo";
import { ACD_SOCKET_PATH } from "../graphqlUtils";
import handleError from "../handleError";
import { showContactPath } from "../path_helpers/contact_paths";
import { AppThunk } from "../store";
import {
  addCallEvent,
  callDisappears,
  setAcdTokenAndUri,
  setCallContact,
  setEndpointSocketConnected,
  setEndpointStates,
  setEndpointStatus,
  setEndpointTokenAndUri,
  setUserSocketConnected,
} from "../store/acdReducer";
import {
  AcdCallEventType,
  AcdCurrentEndpointStateQueryType,
  AcdCurrentEndpointStatusDataType,
  AcdEndpointStatus,
  ContactInterface,
  CustomerInterface,
  GetAcdTokenType,
  GetEndpointTokenType,
  Nullable,
  ProjectInterface,
} from "../types";

export let endpointSocket: Nullable<Socket> = null;
export let userSocket: Nullable<Socket> = null;

export const getAcdToken =
  (customer: CustomerInterface, project: ProjectInterface): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await apolloClient.mutate<GetAcdTokenType>({
        mutation: Api.acd.GET_ACD_TOKEN,
        variables: {
          customerId: customer.id,
          projectId: project.id,
        },
      });
      if (!data) {
        throw new Error("Cannot fetch ACD token from backend.");
      }

      dispatch(setAcdTokenAndUri(data.acdProjectToken));

      if (userSocket) {
        userSocket.disconnect();
        window.dispatchEvent(new CustomEvent("acd:mainSocketClose"));
      }

      userSocket = new Socket(ACD_SOCKET_PATH, { params: { token: data.acdProjectToken.token } });

      userSocket.onOpen(() => {
        window.dispatchEvent(new CustomEvent("acd:mainSocketOpen"));
        dispatch(setUserSocketConnected(true));
      });
      userSocket.onClose(() => {
        window.dispatchEvent(new CustomEvent("acd:mainSocketClose"));
        dispatch(setUserSocketConnected(false));
      });

      userSocket.connect();
    } catch (e) {
      handleError(e);
      return;
    }
  };

export const resetAcdToken = (): AppThunk => async (dispatch) => {
  if (userSocket) userSocket.disconnect();
  userSocket = null;
  dispatch(setAcdTokenAndUri({ token: null, url: null }));
};

export const getEndpointToken = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await apolloClient.mutate<GetEndpointTokenType>({ mutation: Api.acd.GET_ENDPOINT_TOKEN });
    if (!data) {
      throw new Error("Cannot get endpoint token from backend.");
    }

    dispatch(setEndpointTokenAndUri(data.acdEndpointToken));

    if (endpointSocket) {
      endpointSocket.disconnect();
      window.dispatchEvent(new CustomEvent("acd:socketClose"));
    }

    endpointSocket = new Socket(ACD_SOCKET_PATH, { params: { token: data.acdEndpointToken.token } });

    endpointSocket.onOpen(() => {
      window.dispatchEvent(new CustomEvent("acd:socketOpen"));
      dispatch(handleEndpointSocketConnected(endpointSocket!));
    });
    endpointSocket.onClose(() => {
      window.dispatchEvent(new CustomEvent("acd:socketClose"));
      dispatch(handleEndpointSocketConnected(endpointSocket!));
    });

    endpointSocket.connect();
  } catch (e) {
    handleError(e);
    return;
  }
};

export const dispatchIncomingCall = (value: AcdCallEventType) => {
  const event = new CustomEvent("tt:incomingCall", { detail: value });
  document.dispatchEvent(event);

  return addCallEvent(value);
};

export type IncomingCallEvent = CustomEvent<AcdCallEventType>;

export const dispatchCallEvent = (value: AcdCallEventType) => {
  const event = new CustomEvent("tt:callEvent", { detail: value });
  document.dispatchEvent(event);

  if (value.event.msg.match(/setup/)) {
    const event = new CustomEvent("tt:callStart", { detail: value });
    document.dispatchEvent(event);
  }

  return addCallEvent(value);
};

export const callDoesDisappear =
  (callEvent: AcdCallEventType): AppThunk =>
  async (dispatch, getState) => {
    const event = new CustomEvent("tt:callEnd", { detail: callEvent });
    document.dispatchEvent(event);

    const { currentProject, currentUser } = getState().session;
    if (!currentProject || !currentUser) return;

    dispatch(callDisappears(callEvent));
  };

export const incomingCallContactChosen =
  (navigate: Function, contact: ContactInterface): AppThunk =>
  (dispatch, getState) => {
    dispatch(setCallContact(contact));
    navigate(showContactPath(getState().session, contact));
  };

let acdChannel: Nullable<Channel> = null;
let acdUserChannel: Nullable<Channel> = null;

export const handleEndpointSocketConnected =
  (socket: Socket): AppThunk =>
  async (dispatch, getState) => {
    const connState = socket.isConnected();
    const { currentUser } = getState().session;
    dispatch(setEndpointSocketConnected(connState));

    if (connState && !acdChannel) {
      const { data } = await acdEndpointApollo.query<AcdCurrentEndpointStateQueryType>({
        query: ACD_CURRENT_STATE_QUERY,
      });
      dispatch(setEndpointStates(data.currentEndpointState));

      acdChannel = socket.channel("endpoint:lobby");
      acdChannel
        .join()
        .receive("ok", (msg) => {})
        .receive("error", (msg) => console.log("error joining endpoint lobby", msg));

      acdChannel.on("endpoint_status_changed", (msg: AcdEndpointStatus) => {
        // I don't get it. Sometimes either ep.lastEvent or msg.lastEvent seems to be null 🤯
        const state = getState().acd.endpointStates.filter((ep) => ep.lastEvent?.e164 !== msg.lastEvent?.e164);
        if (msg.type !== "USER_DISAPPEARS") {
          state.push(msg);
        }
        dispatch(setEndpointStates(state));
      });
    }

    if (currentUser?.attrs?.e164 && connState && !acdUserChannel) {
      try {
        const { data } = await acdEndpointApollo.query<AcdCurrentEndpointStatusDataType>({
          query: ACD_CURRENT_ENDPOINT_STATUS,
          variables: { no: currentUser.attrs.e164 },
        });

        dispatch(setEndpointStatus(data.currentEndpointStatus));
      } catch (e) {
        handleError(e, false);
      }

      acdUserChannel = socket.channel(`endpoint:${currentUser.attrs.e164}`);
      acdUserChannel
        .join()
        .receive("ok", (msg) => {})
        .receive("error", (msg) => console.log("could not join acd user channel", msg));

      acdUserChannel.on("endpoint_status_changed", (msg: AcdEndpointStatus) => {
        if (msg.type === "USER_DISAPPEARS") {
          dispatch(setEndpointStatus(null));
        } else {
          dispatch(setEndpointStatus(msg));
        }
      });

      acdUserChannel.on("call_event", (msg: AcdCallEventType) => {
        if (msg.direction === "INBOUND" && msg.eventType === "NEW") {
          dispatch(dispatchIncomingCall(msg));
        }
        dispatch(dispatchCallEvent(msg));
        if (msg.eventType === "DISAPPEAR") {
          dispatch(callDoesDisappear(msg));
        }
      });
    }
  };
