import React from "react";

import { MdArchive } from "react-icons/md";

import type { TTButtonProps } from "../../types";
import ConfirmButton from "./ConfirmButton";

const ArchiveButton: TTButtonProps = React.memo(({ children, as: component, ...props }) => {
  const Element = component || ConfirmButton;

  return (
    <Element {...props}>
      <MdArchive /> {children}
    </Element>
  );
});

export default ArchiveButton;
