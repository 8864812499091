import { jsx } from "slate-hyperscript";

import type { CustomElement, CustomText, Nullable } from "../../types";
import { IMAGE_EXTENSIONS } from "./image_extensions";

const ELEMENT_TAGS: Record<string, (el?: Element) => CustomElement> = {
  A: (el) => ({ type: "link", url: el?.getAttribute("href"), children: [] }),
  BLOCKQUOTE: () => ({ type: "quote", children: [] }),
  H1: () => ({ type: "heading-one", children: [] }),
  H2: () => ({ type: "heading-two", children: [] }),
  H3: () => ({ type: "heading-three", children: [] }),
  H4: () => ({ type: "heading-four", children: [] }),
  H5: () => ({ type: "heading-five", children: [] }),
  H6: () => ({ type: "heading-six", children: [] }),
  IMG: (el) => ({ type: "image", url: el?.getAttribute("src"), children: [] }),
  LI: () => ({ type: "list-item", children: [] }),
  OL: () => ({ type: "numbered-list", children: [] }),
  P: () => ({ type: "paragraph", children: [] }),
  PRE: () => ({ type: "code", children: [] }),
  UL: () => ({ type: "bulleted-list", children: [] }),
};

const TEXT_TAGS: Record<string, (el?: Element) => Omit<CustomText, "text">> = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const deserialize = (el: any): any => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  let parent: any = el;

  if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat();

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx("element", attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx("text", attrs, child));
  }

  return children;
};

export const parsedValue = (value: any) => {
  if (value && value.length > 0) {
    if (typeof value === "string") {
      const parsed = new DOMParser().parseFromString(value, "text/html");
      return deserialize(parsed.body);
    }

    return value;
  }

  return [{ type: "paragraph", children: [{ text: "" }] }];
};

export const isUrl = (url: string) => {
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
  const localhostDomainRE = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/;

  const match = url.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
};

export const isImageUrl = (url: Nullable<string>) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop() || "";
  return IMAGE_EXTENSIONS.includes(ext);
};
