import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { isTermitel } from "../../abilities/helpers";
import { OVERDUE_DOCUMENTS_LIST_QUERY } from "../../api/documents";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../store/sessionReducer";
import type { DocumentType, Nullable, OverdueDocumentsDataInterface } from "../../types";
import TTModal from "../TTModal";
import DocumentsList from "./DetailDocumentsList";
import { setModalVisible, setSignatureMissing } from "./documentsReducer";
import Show from "./Show";

type Details = Nullable<DocumentType>;

export default function OverdueDocuments() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const termitel = isTermitel(session.currentUser);
  const { pathname } = useLocation();
  const { t } = useTranslation(["documents"]);

  const { data, refetch } = useQuery<OverdueDocumentsDataInterface>(OVERDUE_DOCUMENTS_LIST_QUERY, { skip: !termitel });

  const [details, openDetails] = useState<Details>(null);

  useEffect(() => {
    if (!data?.overdueDocuments.length || !termitel) return;

    dispatch(setSignatureMissing(true));
    dispatch(setModalVisible(true));
  }, [pathname, dispatch, data?.overdueDocuments, termitel]);

  function hideShowModal() {
    openDetails(null);
    refetch();
  }

  if (!data?.overdueDocuments.length || !termitel) return null;

  return (
    <>
      <TTModal show={true} size="lg" backdrop="static" animation={false}>
        <Modal.Header>
          <Modal.Title>{t("documents:overdue.title")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DocumentsList documents={data!.overdueDocuments} showDocument={(document) => openDetails(document)} />
        </Modal.Body>
      </TTModal>

      {!!details && (
        <Modal show={true} size="lg" className="xxl" backdrop="static" animation={false}>
          <Modal.Header>
            <Modal.Title>{details.subject}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Show
              session={{ ...session, currentCustomer: details.customer, currentProject: details.project }}
              id={details.id}
              cancelAction={hideShowModal}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
