import { useState } from "react";

import { MdEventSeat } from "react-icons/md";

import { may } from "../../../abilities";
import { useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import { getComponent } from "./components";

export default function Hotseat() {
  const session = useAppSelector(sessionSelector);
  const [showForwardModal, setShowForwardModal] = useState(false);

  const ForwardModal = getComponent(session.currentCustomer, session.currentProject);

  function onClick() {
    if (!ForwardModal) {
      window.open(session.currentProject.attrs.hotseat_link, "_blank", "noopener,noreferrer");
      return;
    }

    setShowForwardModal(true);
  }

  return (
    <>
      {may(session, "project/hotseat") && (
        <button type="button" onClick={onClick} title="Hotseat">
          <MdEventSeat />
        </button>
      )}

      {ForwardModal && <ForwardModal show={showForwardModal} onHide={() => setShowForwardModal(false)} />}
    </>
  );
}
