import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

const TEAM_DETAIL_FRAGMENT = gql`
  fragment TeamDetailFragment on Team {
    id
    name
    attrs
    insertedAt
    updatedAt

    users {
      ...UserFragment
    }

    leaders {
      ...UserFragment
    }

    foreignUsers {
      ...UserFragment
    }

    foreignLeaders {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const TEAM_LIST_QUERY = gql`
  query teams($customerId: ID!, $projectId: ID, $filters: TeamsListFilters) {
    teams(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      name
      insertedAt
      updatedAt

      project {
        id
        name
      }
    }
  }
`;

export const COUNT_TEAMS_QUERY = gql`
  query countTeams($customerId: ID!, $projectId: ID!) {
    countTeams(customerId: $customerId, projectId: $projectId)
  }
`;

export const TEAMS_WITH_USERS_LIST_QUERY = gql`
  query teamsWithUsers($customerId: ID!, $projectId: ID) {
    teams(customerId: $customerId, projectId: $projectId) {
      id
      name
      users {
        ...UserFragment
        retired
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const ACD_TEAMS_LIST_QUERY = gql`
  query teamsWithAllUsers($customerId: ID!, $projectId: ID, $filters: TeamsListFilters) {
    teams(customerId: $customerId, projectId: $projectId, filters: $filters) {
      users {
        id
        attrs
      }

      foreignUsers {
        id
        attrs
      }

      leaders {
        id
        attrs
      }

      foreignLeaders {
        id
        attrs
      }
    }
  }
`;

export const TEAMS_WITH_USERS_AND_FOREIGN_USERS_LIST_QUERY = gql`
  query teamsWithUsersAndForeignUsers($customerId: ID!, $projectId: ID) {
    teams(customerId: $customerId, projectId: $projectId) {
      id
      name

      users {
        ...UserFragment
        retired
      }

      foreignUsers {
        ...UserFragment
        retired
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const TEAM_GET_QUERY = gql`
  query team($customerId: ID!, $projectId: ID!, $id: ID!) {
    team(customerId: $customerId, projectId: $projectId, id: $id) {
      ...TeamDetailFragment
    }
  }
  ${TEAM_DETAIL_FRAGMENT}
`;

export const IS_TEAM_LEADER_QUERY = gql`
  query isTeamLeader($customerId: ID!, $projectId: ID!, $userId: ID, $userIds: [ID!]) {
    isTeamLeader(customerId: $customerId, projectId: $projectId, userId: $userId, userIds: $userIds)
  }
`;

export const TEAM_DELETE_MUTATION = gql`
  mutation deleteTeam($id: ID!, $customerId: ID!, $projectId: ID!) {
    deleteTeam(id: $id, customerId: $customerId, projectId: $projectId) {
      ...TeamDetailFragment
    }
  }
  ${TEAM_DETAIL_FRAGMENT}
`;

export const TEAM_MUTATION = gql`
  mutation mutateTeam($id: ID, $customerId: ID!, $projectId: ID!, $team: TeamInput!) {
    mutateTeam(id: $id, customerId: $customerId, projectId: $projectId, team: $team) {
      ...TeamDetailFragment
    }
  }
  ${TEAM_DETAIL_FRAGMENT}
`;

export const COPY_TEAM_MUTATION = gql`
  mutation copyTeam($teamId: ID!, $customerId: ID!, $projectId: ID!, $name: String!) {
    copyTeam(teamId: $teamId, customerId: $customerId, projectId: $projectId, name: $name) {
      id
      name
      insertedAt
      updatedAt
    }
  }
`;

export const RECRUITING_TEAM_OVERVIEW = gql`
  query recruitingTeamOverview($customerId: ID!, $projectId: ID!, $date: NaiveDate!) {
    recruitingTeamOverview(customerId: $customerId, projectId: $projectId, date: $date) {
      name
      teamId
      wanted
      planned
      employed
    }
  }
`;
