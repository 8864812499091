import type { IconType } from "react-icons";
import { MdAnnouncement, MdBusiness, MdDomain, MdInfoOutline, MdQuestionAnswer } from "react-icons/md";

import type { DocumentType, DocumentTypeType } from "../../types";
import CustomerDocumentShow from "./DocumentContents/CustomerDocument";
import FAQDocument from "./DocumentContents/FAQDocument";
import WorkingInstructionShow from "./DocumentContents/WorkingInstruction";
import CustomerDocumentForum from "./Form/CustomerDocument";
import FAQForm from "./Form/FAQ";
import WorkingInstruction from "./Form/WorkingInstruction";
import type { DocumentSubcompType } from "./types";

export type DocumentFormProps = {
  document: DocumentType;
};

export const DOCUMENT_FORMS: Record<DocumentTypeType, [IconType, DocumentSubcompType, DocumentSubcompType]> = {
  WORKING_INSTRUCTION: [MdAnnouncement, WorkingInstruction, WorkingInstructionShow],
  INFORMATION: [MdInfoOutline, WorkingInstruction, WorkingInstructionShow],
  PROJECT_INFORMATION: [MdBusiness, WorkingInstruction, WorkingInstructionShow],
  CUSTOMER_DOCUMENT: [MdDomain, CustomerDocumentForum, CustomerDocumentShow],
  FAQ: [MdQuestionAnswer, FAQForm, FAQDocument],
  OCCUPATIONAL_SAFETY: [MdAnnouncement, WorkingInstruction, WorkingInstructionShow],
  OCCUPATIONAL_HEALTH: [MdAnnouncement, WorkingInstruction, WorkingInstructionShow],
};
