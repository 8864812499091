import { SessionInterface } from "../store/sessionReducer";
import type { CustomerFeedbackAnnotationType, CustomerFeedbackType, PermissionType } from "../types";
import { featureEnabled, isAdmin } from "./helpers";

export interface CustomerFeedbacksMayInterface {
  (session: SessionInterface, path: "customer-feedbacks"): boolean;
  (session: SessionInterface, path: "customer-feedbacks", action: "new" | "import" | "editTopics"): boolean;
  (
    session: SessionInterface,
    path: "customer-feedbacks",
    action: "show" | "edit" | "markDone" | "delete",
    feedbackOrAnnotation: CustomerFeedbackAnnotationType | CustomerFeedbackType,
  ): boolean;
  (
    session: SessionInterface,
    path: "customer-feedbacks",
    action: "allDone" | "classify",
    feedback: CustomerFeedbackType,
  ): boolean;
}

const CustomerFeedbackPermissions: PermissionType<CustomerFeedbackType | CustomerFeedbackAnnotationType> = {
  "customer-feedbacks": {
    index: ({ currentUser, currentProject }) => featureEnabled(currentUser, currentProject, "customer_feedbacks"),
    edit: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "customer_feedbacks") && isAdmin(currentUser),
    new: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "customer_feedbacks") && isAdmin(currentUser),
    import: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "customer_feedbacks") && isAdmin(currentUser),
    delete: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "customer_feedbacks") && isAdmin(currentUser),
    allDone: ({ currentUser, currentProject }, resource) =>
      featureEnabled(currentUser, currentProject, "customer_feedbacks") && !(resource as CustomerFeedbackType).allDone,
    classify: ({ currentUser, currentProject }, resource) =>
      featureEnabled(currentUser, currentProject, "customer_feedbacks") &&
      !(resource as CustomerFeedbackType).allDone &&
      !(resource as CustomerFeedbackType).topicId,
    markDone: ({ currentUser, currentProject }, resource) =>
      featureEnabled(currentUser, currentProject, "customer_feedbacks") &&
      !(resource as CustomerFeedbackAnnotationType).done,
    editTopics: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "customer_feedbacks") && isAdmin(currentUser),
  },
};

export default CustomerFeedbackPermissions;
