import { SessionInterface } from "../store/sessionReducer";
import type { InvoiceInterface } from "../types";
import { sessionPrefix } from "./helpers";

export const invoicesPath = (session: SessionInterface) => `${sessionPrefix(session)}/invoices`;
export const showInvoicePath = (session: SessionInterface, invoice: InvoiceInterface) =>
  `${sessionPrefix(session)}/invoices/${invoice.id}`;
export const newInvoicePath = (session: SessionInterface) => `${sessionPrefix(session)}/invoices/new`;
export const editInvoicePath = (session: SessionInterface, invoice: InvoiceInterface) =>
  `${sessionPrefix(session)}/invoices/${invoice.id}/edit`;
