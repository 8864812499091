import { Alert } from "react-bootstrap";

import { useAppDispatch } from "../../hooks";
import type { FlashMessageInterface } from "../../types";
import { deleteFlashMessage } from "./flashReducer";

type PropsType = {
  message: FlashMessageInterface;
};

export default function FlashMessage({ message }: PropsType) {
  const dispatch = useAppDispatch();

  function onClick() {
    dispatch(deleteFlashMessage(message.id));
  }

  return (
    <Alert variant={message.type} dismissible onClose={onClick}>
      {message.body}
    </Alert>
  );
}
