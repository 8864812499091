import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const TODO_LIST_QUERY = gql`
  query todos($customerId: ID!, $projectId: ID!, $filters: TodoListFilters) {
    todos(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      description
      file
      attrs
      sent
      status
      ownerId
      owner {
        ...UserFragment
      }
      creator {
        ...UserFragment
      }

      contact {
        id
        displayName
        attrs
      }

      allDone

      insertedAt
      updatedAt

      todos {
        id
        attrs
        description
        file
        owner {
          ...UserFragment
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const TODOS_COUNT_QUERY = gql`
  query countTodos($customerId: ID!, $projectId: ID!, $filters: TodoListFilters) {
    countTodos(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const TODOS_FOR_CONTACTS = gql`
  query todos($customerId: ID!, $projectId: ID!, $filters: TodoListFilters) {
    todos(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      description
      file
      attrs
      status
      statusAttrs

      creator {
        ...UserFragment
      }
      owner {
        ...UserFragment
      }

      insertedAt
      updatedAt
    }
  }
  ${USER_FRAGMENT}
`;

export const TODO_DETAILS = gql`
  fragment TodoDetailFragment on Todo {
    id
    parentId
    description
    file
    attrs

    insertedAt
    updatedAt

    allDone
    done
    doneAt
    doneText
    sent

    status
    statusAssignedAt
    statusAttrs

    ownerId
    owner {
      ...UserFragment
    }

    contactId
    contact {
      id
      firstname
      lastname
      displayName
      attrs
    }

    task {
      id
      insertedAt
    }

    doneBy {
      ...UserFragment
    }

    creator {
      ...UserFragment
    }
    editor {
      ...UserFragment
    }

    todos {
      id
      parentId
      attrs
      description
      file

      done
      doneAt
      doneText

      doneBy {
        ...UserFragment
      }

      insertedAt
      updatedAt
    }

    notes {
      id
      text
      file
      insertedAt
      todoId

      creator {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const TODO_GET_QUERY = gql`
  query todo($customerId: ID!, $projectId: ID!, $id: ID!) {
    todo(customerId: $customerId, projectId: $projectId, id: $id) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const TODO_MUTATION = gql`
  mutation mutateTodo($customerId: ID!, $projectId: ID!, $id: ID, $values: TodoInput!) {
    mutateTodo(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const TODO_ARCHIVE_MUTATION = gql`
  mutation archiveTodo($customerId: ID!, $projectId: ID!, $id: ID!, $values: ArchiveTodoInput!) {
    archiveTodo(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const TODO_ADD_COMMENT_MUTATION = gql`
  mutation createTodoNote($customerId: ID!, $projectId: ID!, $todoId: ID!, $values: TodoNoteInput!) {
    createTodoNote(customerId: $customerId, projectId: $projectId, todoId: $todoId, values: $values) {
      id
      todoId
      text
      file
      insertedAt
      creator {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const TODO_DELETE_MUTATION = gql`
  mutation deleteTodo($id: ID!, $customerId: ID!, $projectId: ID!) {
    deleteTodo(id: $id, customerId: $customerId, projectId: $projectId) {
      id
    }
  }
`;

export const TODO_DASHBOARD_QUERY = gql`
  query todoDashboard($customerId: ID!, $projectId: ID!, $types: [String]!) {
    todoDashboard(customerId: $customerId, projectId: $projectId, types: $types) {
      salesStats
      stats
    }
  }
`;

export const TODO_SEND_MUTATION = gql`
  mutation sendTodo($customerId: ID!, $projectId: ID!, $id: ID!) {
    sendTodo(customerId: $customerId, projectId: $projectId, id: $id) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const CONFIRM_TODO_BY_TOKEN_MUTATION = gql`
  mutation confirmTodoWithToken($token: String!) {
    confirmTodoWithToken(token: $token) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const OWN_TODO_MUTATION = gql`
  mutation ownTodo($customerId: ID!, $projectId: ID!, $id: ID!) {
    ownTodo(customerId: $customerId, projectId: $projectId, id: $id) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const ACCEPT_TODO_MUTATION = gql`
  mutation acceptTodo($customerId: ID!, $projectId: ID!, $id: ID!, $statusAttrs: Json!) {
    acceptTodo(customerId: $customerId, projectId: $projectId, id: $id, statusAttrs: $statusAttrs) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const REJECT_TODO_MUTATION = gql`
  mutation rejectTodo($customerId: ID!, $projectId: ID!, $id: ID!, $statusAttrs: Json!) {
    rejectTodo(customerId: $customerId, projectId: $projectId, id: $id, statusAttrs: $statusAttrs) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const ASSIGN_TODO_MUTATION = gql`
  mutation assignTodo($customerId: ID!, $projectId: ID!, $id: ID!, $userId: ID!) {
    assignTodo(customerId: $customerId, projectId: $projectId, id: $id, userId: $userId) {
      ...TodoDetailFragment
    }
  }
  ${TODO_DETAILS}
`;

export const TODO_SUBSCRIPTION = gql`
  subscription todoEvents($customerId: ID!, $projectId: ID!) {
    todoEvents(customerId: $customerId, projectId: $projectId) {
      todoId
      todo {
        ...TodoDetailFragment
      }
    }
  }
  ${TODO_DETAILS}
`;
