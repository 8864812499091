import { SessionInterface } from "../store/sessionReducer";
import type { UserInterface } from "../types";
import { sessionPrefix } from "./helpers";

export const adminUsersPath = (session: SessionInterface) => `${sessionPrefix(session)}/admin/users`;
export const newAdminUserPath = (session: SessionInterface) => `${sessionPrefix(session)}/admin/users/new`;
export const editAdminUserPath = (session: SessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/admin/users/${user.id}/edit`;
export const changePasswordPath = (session: SessionInterface) => `${sessionPrefix(session)}/profile/password`;
export const profilePath = (session: SessionInterface) => `${sessionPrefix(session)}/profile`;
