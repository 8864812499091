import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import { may } from "../../abilities";
import { LAST_NEWS_POST_QUERY } from "../../api/news_feed";
import { NewsImage } from "../../containers/NewsImage";
import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../store/sessionReducer";
import type { LastCompanyNewsDataInterface } from "../../types";

const FlashNews: React.ComponentType = () => {
  const session = useAppSelector(sessionSelector);
  const { t } = useTranslation(["translation"]);

  const { data } = useQuery<LastCompanyNewsDataInterface>(LAST_NEWS_POST_QUERY, {
    variables: { customerId: session.currentUser.customerId },
    skip: !may(session, "staff/company-news"),
  });

  const [identifier, setIdentifier] = useState("");
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (!data?.lastCompanyNewsPost) return;

    setIdentifier(data?.lastCompanyNewsPost.id);

    if (localStorage.getItem(data?.lastCompanyNewsPost.id) !== "seen") {
      setHidden(false);
    }
  }, [data?.lastCompanyNewsPost]);

  function hide() {
    localStorage.setItem(identifier, "seen");
    setHidden(true);
  }

  if (hidden) {
    return null;
  }

  return (
    <>
      {data?.lastCompanyNewsPost && (
        <div className="bg-light p-5 rounded-lg m-3">
          <Row>
            {data.lastCompanyNewsPost.image.medium && (
              <Col xs={6} md={3}>
                <NewsImage image={data.lastCompanyNewsPost.image} type="medium" />
              </Col>
            )}

            <Col>
              {
                <ReactMarkdown
                  children={data?.lastCompanyNewsPost.content}
                  components={{
                    h1: "h3",
                    h2: "h3",
                  }}
                />
              }

              <p className="text-end">
                <Button variant="secondary" onClick={hide}>
                  {t("translation:global.close")}
                </Button>
              </p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default FlashNews;
