import { useCallback, useEffect, useRef, useState } from "react";

import { differenceInMinutes } from "date-fns";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import semver from "semver";

import { userLobbyChannel } from "../../../../api/session";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { VERSIONS } from "../../../../socketHandlers";
import type { Nullable, VersionsEvent } from "../../../../types";
import { bottomBarSelector, setUpdateAvailable } from "../bottomBarReducer";
import ReloadModal from "./ReloadModal";

const getCurrentVersionHandler = () => {
  userLobbyChannel?.push("version", {}).receive("ok", (versions) => {
    const event = new CustomEvent("tt:versions", { detail: versions });
    document.dispatchEvent(event);
  });
};

export default function Version() {
  const [updatedAt, setUpdatedAt] = useState<Nullable<Date>>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const tm = useRef<number>();
  const { t } = useTranslation(["bottomBar"]);
  const refreshPage = () => {
    window.location.reload();
  };
  const { updateAvailable } = useAppSelector(bottomBarSelector);
  const dispatch = useAppDispatch();

  const didReceiveVersion = useCallback(
    (ev: VersionsEvent) => {
      const versions = ev.detail;

      if (!process.env.REACT_APP_VERSION || semver.lte(versions.frontend, process.env.REACT_APP_VERSION)) {
        return;
      }

      setUpdatedAt(new Date());
      dispatch(setUpdateAvailable(true));
    },
    [dispatch],
  );

  useEffect(() => {
    document.addEventListener("tt:versions", didReceiveVersion);

    return () => {
      document.removeEventListener("tt:versions", didReceiveVersion);
    };
  }, [didReceiveVersion]);

  useEffect(() => {
    if (tm.current) {
      window.clearInterval(tm.current);
      tm.current = undefined;
    }

    if (updatedAt) {
      tm.current = window.setInterval(() => {
        const diff = differenceInMinutes(new Date(), updatedAt);

        if (diff === 20) {
          localStorage.setItem("automatic_reload_after_version_update", "1");
          window.location.reload();
          return;
        }

        if (diff === 5) {
          setModalVisible(true);
        }
      }, 60000);
    }

    return () => {
      if (tm.current) window.clearInterval(tm.current);
      tm.current = undefined;
    };
  }, [updatedAt]);

  // sadly there is a race condition I can't really circumvent, thus we need to check vor the VERSIONS global
  useEffect(
    () => {
      if (VERSIONS) {
        didReceiveVersion(new CustomEvent("tt:versions", { detail: VERSIONS }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    window.addEventListener("focus", getCurrentVersionHandler);

    return () => {
      window.removeEventListener("focus", getCurrentVersionHandler);
    };
  });

  if (!updateAvailable) {
    return null;
  }

  return (
    <div>
      {t("bottomBar:update_available")}
      <Button onClick={refreshPage}>{t("bottomBar:reload")}</Button>

      <ReloadModal show={modalVisible} onHide={() => setModalVisible(false)} />
    </div>
  );
}
