import _ from "lodash";

import { SessionInterface } from "../store/sessionReducer";
import type {
  AgendaConfigType,
  ContactGroupInterface,
  ContactInterface,
  HistoryInterface,
  QueryStringType,
} from "../types";
import { queryString, sessionPrefix } from "./helpers";

const getId = (cnt: ContactInterface | string): string => {
  if (_.isObject(cnt)) {
    return cnt.parentId || cnt.id;
  }
  return cnt;
};

export const contactsPath = (session: SessionInterface, query: QueryStringType = null) =>
  `${sessionPrefix(session)}/contacts${queryString(query)}`;
export const newContactPath = (session: SessionInterface) => `${sessionPrefix(session)}/contacts/new`;
export const showContactPath = (
  session: SessionInterface,
  contact: ContactInterface | string,
  qs: QueryStringType = null,
) => `${sessionPrefix(session)}/contacts/${getId(contact)}${queryString(qs)}`;
export const contactsAgendaPath = (session: SessionInterface, query: QueryStringType = null) =>
  `${sessionPrefix(session)}/contacts/agenda${queryString(query)}`;

export const importContactsPath = (session: SessionInterface) => `${sessionPrefix(session)}/contacts/import`;

export const exportContactsPath = (session: SessionInterface) => `${sessionPrefix(session)}/contacts/export`;

export const contactGroupsPath = (session: SessionInterface) => `${sessionPrefix(session)}/contacts/groups`;
export const newContactGroupPath = (session: SessionInterface) => `${sessionPrefix(session)}/contacts/groups/new`;
export const showContactGroupPath = (session: SessionInterface, group: ContactGroupInterface) =>
  `${sessionPrefix(session)}/contacts/groups/${group.id}`;
export const editContactGroupPath = (session: SessionInterface, group: ContactGroupInterface) =>
  `${sessionPrefix(session)}/contacts/groups/${group.id}/edit`;

export const contactCallsPath = (session: SessionInterface) => `${sessionPrefix(session)}/contacts/calls`;
export const showContactCallPath = (session: SessionInterface, history: HistoryInterface) =>
  `${sessionPrefix(session)}/contacts/calls/${history.id}`;

export const contactsBatchAction = (session: SessionInterface) => `${sessionPrefix(session)}/contacts/batch`;
export const contactsBatchActionDelete = (session: SessionInterface) =>
  `${sessionPrefix(session)}/contacts/batch/delete`;
export const contactsBatchActionSetField = (session: SessionInterface) =>
  `${sessionPrefix(session)}/contacts/batch/set-field`;
export const contactsBatchActionTransfer = (session: SessionInterface) =>
  `${sessionPrefix(session)}/contacts/batch/transfer`;

export const contactAgendaConfigsPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/contacts/agenda-configs`;
export const newContactAgendaConfigPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/contacts/agenda-configs/new`;
export const editContactAgendaConfigPath = (session: SessionInterface, config: AgendaConfigType) =>
  `${sessionPrefix(session)}/contacts/agenda-configs/${config.id}`;
