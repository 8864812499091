import { useState } from "react";

import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DoneButton } from "../../../../containers/buttons";
import TTModal from "../../../TTModal";

export default function AutomaticallyReloaded() {
  const [show, setShow] = useState(localStorage.getItem("automatic_reload_after_version_update") === "1");
  const { t } = useTranslation(["bottomBar"]);

  function close() {
    localStorage.removeItem("automatic_reload_after_version_update");
    setShow(false);
  }

  return (
    <TTModal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>{t("bottomBar:automatic_reload.automatic_reload")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{t("bottomBar:automatic_reload.reload_done")}</Modal.Body>

      <Modal.Footer>
        <DoneButton onClick={close}>{t("bottomBar:automatic_reload.understood")}</DoneButton>
      </Modal.Footer>
    </TTModal>
  );
}
