import { getISOWeek, getISOWeekYear } from "date-fns";

import { SessionInterface } from "../store/sessionReducer";
import type { EventFeedbackInterface, EventInterface, Nullable } from "../types";
import { sessionPrefix } from "./helpers";

export const weekCalendarPath = (session: SessionInterface, date: Nullable<Date> = null) => {
  if (!date) {
    date = new Date();
  }

  const wno = getISOWeek(date);
  const year = getISOWeekYear(date);

  return `${sessionPrefix(session)}/calendar/c/${year}/w${wno}`;
};

export const monthCalendarPath = (session: SessionInterface, date: Nullable<Date> = null) => {
  if (!date) {
    date = new Date();
  }

  return `${sessionPrefix(session)}/calendar/c/${date.getFullYear()}/${date.getMonth() + 1}`;
};

export const dayCalendarPath = (session: SessionInterface, date: Nullable<Date> = null) => {
  if (!date) {
    date = new Date();
  }

  return `${sessionPrefix(session)}/calendar/c/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

export const agendaCalendarPath = (session: SessionInterface, date: Nullable<Date> = null) => {
  if (!date) {
    date = new Date();
  }

  return `${sessionPrefix(session)}/calendar/c/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}/agenda`;
};

export const calendarPath = (session: SessionInterface) => `${sessionPrefix(session)}/calendar`;
export const calendarSendEventsPath = (session: SessionInterface) => `${sessionPrefix(session)}/calendar/send`;

export const calendarFeedbackPath = (session: SessionInterface) => `${sessionPrefix(session)}/calendar/feedback`;
export const showCalendarFeedbackPath = (session: SessionInterface, feedback: EventFeedbackInterface) =>
  `${sessionPrefix(session)}/calendar/feedback/${feedback.id}`;
export const editCalendarFeedbackPath = (session: SessionInterface, feedback: EventFeedbackInterface) =>
  `${sessionPrefix(session)}/calendar/feedback/${feedback.id}/edit`;
export const newCalendarFeedbackPath = (session: SessionInterface) => `${sessionPrefix(session)}/calendar/feedback/new`;

export const calendarEventsPath = (session: SessionInterface) => `${sessionPrefix(session)}/calendar/events`;
export const transferCalendarEventsPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/calendar/events/transfer`;
export const showCalendarEventPath = (session: SessionInterface, event: EventInterface) =>
  `${sessionPrefix(session)}/calendar/events/${event.id}`;

export const calendarStornosPath = (session: SessionInterface) => `${sessionPrefix(session)}/calendar/stornos`;
export const showCalendarStornoPath = (session: SessionInterface, storno: EventFeedbackInterface) =>
  `${sessionPrefix(session)}/calendar/stornos/${storno.id}`;
