import { useCallback, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import clsx from "clsx";
import { Dropdown, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  getSuToken,
  removeAuthorizationToken,
  setAuthorizationCookie,
  setAuthorizationToken,
} from "../../../../authorization_token";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { profilePath } from "../../../../path_helpers";
import { messagesPath } from "../../../../path_helpers/messages_paths";
import { logout, sessionSelector } from "../../../../store/sessionReducer";
import { COUNT_MESSAGES_QUERY } from "../../Messages/queries";
import type { CountMessagesQueryResultInterface } from "../../Messages/types";
import LanguageChooser from "./LanguageChooser";
import UserMenuToggle from "./UserMenuToggle";

export default function MobileUserMenu() {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const session = useAppSelector(sessionSelector);
  const { currentUser } = session;
  const { t } = useTranslation(["translation", "nav"]);
  const hasMessages = currentUser.flags.includes("messages");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data, refetch } = useQuery<CountMessagesQueryResultInterface>(COUNT_MESSAGES_QUERY, {
    skip: !hasMessages,
  });
  const unreadMessages = parseInt(data?.countMessages?.unread || "0") || 0;

  useEffect(() => {
    if (hasMessages) {
      document.addEventListener("tt:messagesChanged", refetch);
      return () => {
        document.removeEventListener("tt:messagesChanged", refetch);
      };
    }
  }, [refetch, hasMessages]);

  function toggleUserMenu() {
    setShowUserMenu(!showUserMenu);
  }

  const doUnSu = useCallback(() => {
    const suToken = getSuToken();

    if (suToken) {
      removeAuthorizationToken();
      setAuthorizationToken(suToken);
      setAuthorizationCookie(suToken);

      document.location.href = "/";
    }
  }, []);

  const doLogout = useCallback(() => {
    removeAuthorizationToken();
    dispatch(logout());
    navigate("/login");
  }, [dispatch, navigate]);

  return (
    <Dropdown align={{ lg: "start" }} show={showUserMenu} onToggle={toggleUserMenu}>
      <Nav.Link onClick={toggleUserMenu}>
        <UserMenuToggle avatar={currentUser.avatar} notificationCount={unreadMessages} />
      </Nav.Link>

      <Dropdown.Menu className="mobile-user-menu">
        <Dropdown.Item as={Link} to={profilePath(session)} key="profile">
          {t("translation:header.profile")}
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to={messagesPath(session)}
          key="messages"
          className={clsx({ unread: unreadMessages > 0 })}
        >
          {t("nav.messages")} {unreadMessages ? `(${unreadMessages})` : ""}
        </Dropdown.Item>
        {localStorage.getItem("su_token") && (
          <Dropdown.Item onClick={doUnSu} key="unsu">
            {t("translation:header.unsu")}
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={doLogout} key="logout">
          {t("translation:header.logout")}
        </Dropdown.Item>

        <Dropdown.Divider />
        <LanguageChooser />
      </Dropdown.Menu>
    </Dropdown>
  );
}
