import _ from "lodash";

import i18n from "./i18n";

const currencyFormatters: Partial<Record<string, Partial<Record<string, Intl.NumberFormat>>>> = {
  de: {
    EUR: new Intl.NumberFormat("de", { style: "currency", currency: "EUR" }),
  },
};

const percentageFormatter: Partial<Record<string, Intl.NumberFormat>> = {
  de: new Intl.NumberFormat("de", { style: "percent", maximumFractionDigits: 2, minimumFractionDigits: 2 }),
};

const numberFormatter: Partial<Record<string, Intl.NumberFormat>> = {
  de: new Intl.NumberFormat("de", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
};

const intNumberFormatter: Partial<Record<string, Intl.NumberFormat>> = {
  de: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
};

export const formatCurrency = (value: number, currency: string = "EUR") => {
  if (!currencyFormatters[i18n.language]) currencyFormatters[i18n.language] = {};
  if (!currencyFormatters[i18n.language]![currency]) {
    currencyFormatters[i18n.language]![currency] = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency,
    });
  }

  if (_.isNaN(value)) value = 0;
  // avoid a -0 case, people get confused by this
  if (value === 0) value = 0;

  return currencyFormatters[i18n.language]![currency]?.format(value) || value;
};

export const formatPercentage = (value: number, digits = 2) => {
  if (!percentageFormatter[`${i18n.language}-${digits}`]) {
    percentageFormatter[`${i18n.language}-${digits}`] = new Intl.NumberFormat(i18n.language, {
      style: "percent",
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    });
  }

  if (_.isNaN(value)) value = 0;
  // avoid a -0 case, people get confused by this
  if (value === 0) value = 0;

  return percentageFormatter[`${i18n.language}-${digits}`]!.format(value);
};

export const formatNumber = (value: number, digits = 2) => {
  if (!numberFormatter[`${i18n.language}-${digits}`]) {
    numberFormatter[`${i18n.language}-${digits}`] = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    });
  }

  if (_.isNaN(value)) value = 0;
  // avoid a -0 case, people get confused by this
  if (value === 0) value = 0;

  return numberFormatter[`${i18n.language}-${digits}`]!.format(value);
};

export const formatIntNumber = (value: number) => {
  if (!intNumberFormatter[i18n.language]) {
    intNumberFormatter[i18n.language] = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  }

  if (_.isNaN(value)) value = 0;
  // avoid a -0 case, people get confused by this
  if (value === 0) value = 0;

  return intNumberFormatter[i18n.language]!.format(value);
};

export const formatIntNumberRounded = (value: number) => {
  if (!intNumberFormatter[i18n.language]) {
    intNumberFormatter[i18n.language] = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  }

  if (_.isNaN(value)) value = 0;
  value = Math.round(value);
  // avoid a -0 case, people get confused by this
  if (value === 0) value = 0;

  return intNumberFormatter[i18n.language]!.format(value);
};
