import { ApolloClient } from "@apollo/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { addDays } from "date-fns";

import { CONTACT_MUTATION } from "../../../api/contacts/contacts";
import { MutationError } from "../../../handleError";
import { AppThunk, RootState } from "../../../store";
import {
  ContactInfoType,
  ContactInterface,
  ContactMutationInterface,
  ContactStatusInterface,
  EventFeedbackTypeType,
  EventInterface,
  Nilable,
  Nullable,
} from "../../../types";
import { gqlDateTime } from "../../../utils/dates";
import type { ValuesType } from "../../mails/types";

export type ContactsHistoryTabType =
  | "histories"
  | "attachments"
  | "todos"
  | "todos-done"
  | "customer-contacts"
  | "mails"
  | "audit-log";

export type AdditionalInfoType = { itype: ContactInfoType; value: string };

type ContactShowReducerState = {
  showEventModal: boolean;
  event: Nullable<Partial<EventInterface>>;
  showFeedbackModal: boolean;
  feedbackModalClosable: boolean;
  feedbackType: Nullable<EventFeedbackTypeType>;

  showSubContactModal: boolean;
  subContact: Nullable<ContactInterface>;

  showCreateMail: boolean;
  createMailValues: Nilable<Partial<ValuesType>>;
  showAddHistoryModal: boolean;
  showAddCustomerContactModal: boolean;
  showAddTodoModal: boolean;

  showAddAttachmentModal: boolean;

  activeHistoryTab: ContactsHistoryTabType;

  showContactInfosModal: boolean;
  showTransferModal: boolean;
  showStatusModal: boolean;
  contact: Nullable<ContactInterface>;
  additionalInfo: Nullable<AdditionalInfoType>;
};

const initialState: ContactShowReducerState = {
  showEventModal: false,
  showFeedbackModal: false,
  feedbackModalClosable: true,
  event: null,
  feedbackType: null,

  showSubContactModal: false,
  subContact: null,

  showCreateMail: false,
  createMailValues: null,
  showAddHistoryModal: false,
  showAddCustomerContactModal: false,
  showAddTodoModal: false,

  showAddAttachmentModal: false,

  showContactInfosModal: false,
  showTransferModal: false,
  showStatusModal: false,
  contact: null,
  additionalInfo: null,

  activeHistoryTab: "histories",
};

type ContactInfoActionType = PayloadAction<{
  show: boolean;
  contact: Nullable<ContactInterface>;
  additionalInfo?: AdditionalInfoType;
}>;

const contactsShowSlice = createSlice({
  name: "contacts.show",
  initialState,
  reducers: {
    setEventInfos(
      state,
      action: PayloadAction<{ event: Nullable<Partial<EventInterface>>; show: boolean; closable: boolean }>,
    ) {
      state.showEventModal = action.payload.show;
      state.event = action.payload.event;
      state.feedbackModalClosable = action.payload.closable;
    },
    setFeedbackInfos(
      state,
      action: PayloadAction<{ event: Nullable<EventInterface>; show: boolean; type: Nullable<EventFeedbackTypeType> }>,
    ) {
      state.showFeedbackModal = action.payload.show;
      state.event = action.payload.event;
      state.feedbackType = action.payload.type;
    },

    setSubcontactInfos(state, action: PayloadAction<{ show: boolean; contact: Nullable<ContactInterface> }>) {
      state.showSubContactModal = action.payload.show;
      state.subContact = action.payload.contact;
    },

    setShowCreateMail(state, action: PayloadAction<boolean>) {
      state.showCreateMail = action.payload;
      state.createMailValues = null;
    },

    setShowCreateMailWithValues(state, action: PayloadAction<{ show: boolean; values: Nilable<Partial<ValuesType>> }>) {
      state.showCreateMail = action.payload.show;
      state.createMailValues = action.payload.values;
    },

    setShowAddHistoryModal(state, action: PayloadAction<boolean>) {
      state.showAddHistoryModal = action.payload;
    },

    setShowAddCustomerContactModal(state, action: PayloadAction<boolean>) {
      state.showAddCustomerContactModal = action.payload;
    },

    setShowAddTodoModal(state, action: PayloadAction<boolean>) {
      state.showAddTodoModal = action.payload;
    },

    setActiveHistoryTab(state, action: PayloadAction<ContactsHistoryTabType>) {
      state.activeHistoryTab = action.payload;
    },

    setShowAttachmentModal(state, action: PayloadAction<boolean>) {
      state.showAddAttachmentModal = action.payload;
    },

    setShowContactInfos(state, action: ContactInfoActionType) {
      state.showContactInfosModal = action.payload.show;
      state.contact = action.payload.contact;
      state.additionalInfo = action.payload.additionalInfo || null;
    },

    setShowTransferModal(state, action: PayloadAction<{ show: boolean; contact: Nullable<ContactInterface> }>) {
      state.showTransferModal = action.payload.show;
      state.contact = action.payload.contact;
    },

    setShowStatusModal(state, action: PayloadAction<{ show: boolean; contact: Nullable<ContactInterface> }>) {
      state.showStatusModal = action.payload.show;
      state.contact = action.payload.contact;
    },
  },
});

export const {
  setEventInfos,
  setFeedbackInfos,
  setSubcontactInfos,
  setShowCreateMail,
  setShowAddHistoryModal,
  setShowAddCustomerContactModal,
  setShowAddTodoModal,
  setActiveHistoryTab,
  setShowAttachmentModal,
  setShowContactInfos,
  setShowTransferModal,
  setShowStatusModal,
  setShowCreateMailWithValues,
} = contactsShowSlice.actions;
export const contactsShowSelector = (state: RootState) => state.contacts.show;
export default contactsShowSlice.reducer;

type UpdateContactStatusType = (
  contact: ContactInterface,
  status: ContactStatusInterface | undefined,
  client: ApolloClient<any>,
  values?: Record<string, any>,
) => AppThunk;

export const updateContact: UpdateContactStatusType =
  (contact, status, client, values) => async (dispatch, getState) => {
    const { currentUser, currentProject, currentCustomer } = getState().session;

    const { data } = await client.mutate<ContactMutationInterface>({
      mutation: CONTACT_MUTATION,
      variables: {
        customerId: currentCustomer.id,
        projectId: currentProject.id,
        id: contact.id,
        contact: { ...(values || {}), statusId: status?.id },
      },
    });

    if (!data?.mutateContact) {
      throw new MutationError();
    }

    if (status?.enforceFollowup) {
      const startTime = addDays(new Date(), status.defaultFollowupDays || 0);

      dispatch(
        setEventInfos({
          show: true,
          closable: false,
          event: {
            typeId: status.followupEventTypeId,
            subject: status.defaultFollowupSubject || "",
            startTime: gqlDateTime(startTime),
            endTime: gqlDateTime(startTime),
            contactId: contact.id,
            ownerId: currentUser.id,
          },
        }),
      );
    }
  };
