import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import type { AttributeFilterType, Nilable, Nullable, UserInterface } from "../../types";

export interface HotseatReducerInterface {
  category: Nullable<string>;
  subCategory: Nullable<string>;
  user: Nullable<UserInterface>;
  creator: Nullable<UserInterface>;
  start: Nullable<Date>;
  stop: Nullable<Date>;
  questionFilterAttrs: Record<string, AttributeFilterType>;
}

const initialState: HotseatReducerInterface = {
  category: null,
  subCategory: null,
  user: null,
  creator: null,
  start: null,
  stop: null,
  questionFilterAttrs: {},
};

const hotseatSlice = createSlice({
  name: "hotseat",
  initialState,
  reducers: {
    resetFilters(state) {
      state.user = null;
      state.creator = null;
      state.category = null;
      state.subCategory = null;
      state.start = null;
      state.stop = null;
      state.questionFilterAttrs = {};
    },
    setUser(state, { payload }: PayloadAction<Nullable<UserInterface>>) {
      state.user = payload;
    },
    setCreator(state, { payload }: PayloadAction<Nullable<UserInterface>>) {
      state.creator = payload;
    },
    setCategory(state, { payload }: PayloadAction<Nullable<string>>) {
      state.category = payload;
    },
    setSubCategory(state, { payload }: PayloadAction<Nullable<string>>) {
      state.subCategory = payload;
    },
    setStart(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.start = payload;
    },
    setStop(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.stop = payload;
    },
    setQuestionFilterAttrs(state, action: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (!action.payload.value) {
        delete state.questionFilterAttrs[action.payload.name];
      } else {
        state.questionFilterAttrs[action.payload.name] = action.payload.value;
      }
    },
  },
});

export const {
  resetFilters,
  setUser,
  setCreator,
  setCategory,
  setStart,
  setStop,
  setSubCategory,
  setQuestionFilterAttrs,
} = hotseatSlice.actions;
export const hotseatSelector = (state: RootState) => state.hotseat;
export default hotseatSlice.reducer;
