import React, { ElementType } from "react";

import clsx from "clsx";
import { FormGroup } from "react-bootstrap";

type PropsType = {
  children?: React.ReactNode;
  className?: string;
  as?: ElementType<any> | undefined;
  md?: number;
  lg?: number;
  xs?: number;
  id?: string;
};

const TTFormGroup: React.ComponentType<PropsType> = React.forwardRef(
  ({ children, className, as, xs, md, lg, id }, ref) => (
    <FormGroup
      className={clsx("form-group", className, {
        [`col-md-${md}`]: !!md,
        [`col-xs-${xs}`]: !!xs,
        [`col-lg-${lg}`]: !!lg,
      })}
      as={as}
      ref={ref}
      id={id}
    >
      {children}
    </FormGroup>
  ),
);

export default TTFormGroup;
