import { useRef, useState } from "react";

import { fetchEventSource } from "@microsoft/fetch-event-source";

import { useAppSelector } from ".";
import { sessionSelector } from "../store/sessionReducer";

type AIEvent = {
  id: string;
  object: string;
  created: number;
  model: string;
  system_fingerprint: string;

  choices: {
    index: number;
    finish_reason: string | null;
    delta: {
      content?: string;
    };
  }[];

  usage: {
    completion_tokens: number;
    prompt_tokens: number;
    total_tokens: number;
  };
};

export const AI_ENABLED_USERS = [
  "a.erdogan",
  "b.benson",
  "ckruse",
  "c.richter",
  "d.guerman",
  "k.boukacem",
  "m.benson",
  "n.telkes",
];

export default function useAi(): [string, boolean, (text: string) => void, () => void] {
  const { currentCustomer } = useAppSelector(sessionSelector);
  const [suggestion, setSuggestion] = useState("");
  const [runningRequest, setRunningRequest] = useState(false);
  const abortController = useRef(new AbortController());

  if (!currentCustomer.attrs.openai_key) {
    return ["", false, () => {}, () => {}];
  }

  function onCancel() {
    abortController.current.abort();
    abortController.current = new AbortController();
    setRunningRequest(false);
  }

  function sendRequest(text: string) {
    if (!text || text.length < 5 || !currentCustomer.attrs.openai_key) {
      return;
    }

    setRunningRequest(true);
    setSuggestion("");

    fetchEventSource("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      mode: "cors",
      referrer: "no-referrer",
      referrerPolicy: "no-referrer",
      signal: abortController.current.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentCustomer.attrs.openai_key}`,
      },
      body: JSON.stringify({
        messages: [
          {
            role: "user",
            content:
              "Du bist ein Sekretär. Korrigiere, redigiere und verbessere den Text. Es handelt sich um die Zusammesfassung eines Kundengesprächs. Der neue Text soll mit kurzen, prägnanten Sätze geschrieben sein. sevDesk ist ist der Name einer Software.",
          },
          { role: "assistant", content: "Ja. Bitte senden Sie mir den Text, den Sie bearbeitet haben möchten." },
          { role: "user", content: text },
        ],
        model: "gpt-4-1106-preview",
        stream: true,
      }),
      async onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          console.log("Client side error ", res);
        }
      },
      onmessage(event) {
        if (!event.data || event.data === "[DONE]") {
          setRunningRequest(false);
          return;
        }

        const parsedData: AIEvent = JSON.parse(event.data);
        for (const choice of parsedData.choices) {
          if (!!choice.finish_reason) {
            setRunningRequest(false);
            return;
          }

          setSuggestion((suggestion) => suggestion + (choice.delta.content || ""));
        }
      },
      onclose() {
        setRunningRequest(false);
        console.log("Connection closed by the server");
      },
      onerror(err) {
        setRunningRequest(false);
        console.log("There was an error from server", err);
        throw err;
      },
    });
  }

  return [suggestion, runningRequest, sendRequest, onCancel];
}
