import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";

export type MetaListReducerType = {
  pages: Partial<Record<string, number>>;
};

const initialState: MetaListReducerType = {
  pages: {},
};

const metaListSlice = createSlice({
  name: "metaList",
  initialState,
  reducers: {
    setPage(state, { payload }: PayloadAction<{ key: string; page: number }>) {
      state.pages[payload.key] = payload.page;
    },
  },
});

export const { setPage } = metaListSlice.actions;
export const metalistSelector = (key: string) => (state: RootState) => state.metalist.pages[key] || 0;
export default metaListSlice.reducer;
