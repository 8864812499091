import clsx from "clsx";
import { differenceInMinutes } from "date-fns";
import { getIn } from "formik";

import { useAppSelector } from "../hooks";
import { sessionSelector } from "../store/sessionReducer";
import type { Nilable, UserInterface } from "../types";
import { parsedDate } from "../utils/dates";
import UserAvatar from "./UserAvatar";

type UserProps = {
  user: Nilable<UserInterface>;
  withUsername?: boolean;
  withHomeOfficeStatus?: boolean;
  className?: string;
  containerClassName?: string;
};

const displayNameForUser = (user: UserInterface, withUsername: boolean = false, isAnonymous: boolean) => {
  if (isAnonymous) {
    return [`Benutzer #${user.id}`];
  }

  let name = "";
  if (user.contact) {
    name = user.contact.displayName;

    if (withUsername) {
      name += ` (${user.username})`;
    }
  } else {
    name = user.username;
  }

  return name.split(/\s+/);
};

export default function User({ user, withUsername, withHomeOfficeStatus, className, containerClassName }: UserProps) {
  const { currentUser, currentProject } = useAppSelector(sessionSelector);

  if (!user) return null;

  const isAnonymous =
    currentUser.customerId !== "1" && user.customerId === "1" && getIn(currentProject, "attrs.anonymize", true); // we use getIn() for the default value when not set
  const [firstPart, ...lastPart] = displayNameForUser(user, withUsername, isAnonymous);
  const avatar = isAnonymous
    ? { thumb: "/avatar-anonymous.svg", medium: "/avatar-anonymous.svg", original: "/avatar-anonymous.svg" }
    : user.avatar;

  const isOnline = !!user.lastSeenAt
    ? differenceInMinutes(new Date(), parsedDate(user.lastSeenAt)) <= 10 && !isAnonymous
    : undefined;

  return (
    <span className={clsx(containerClassName)}>
      <span className={clsx("TT-user", "redacted", className, { "is-online": isOnline })}>
        <span className="TT-user-first-part">
          <UserAvatar type="thumb" alt="Benutzer" avatar={avatar} /> {firstPart}
        </span>{" "}
        {lastPart.join(" ")}
      </span>
      {withHomeOfficeStatus && user.homeoffice && <span className="TT-user-homeoffice">Home-Office</span>}
    </span>
  );
}
