import { Socket } from "phoenix";

import { socketConnected } from "./actions/sessionActions";
import { getAuthorizationToken, getSuToken } from "./authorization_token";
import { SOCKET_PATH } from "./graphqlUtils";
import store from "./store";
import type { Nilable } from "./types";

function createPhoenixSocket(token: Nilable<string>, su_token: Nilable<string>) {
  const params: { token?: string; su_token?: string } = {};

  if (token) {
    params.token = token;
  }

  if (su_token) {
    params.su_token = su_token;
  }

  return new Socket(SOCKET_PATH, { params });
}

let token: Nilable<string> = null;
let TTSocket: Nilable<Socket> = null;

store.subscribe(() => {
  const newToken = getAuthorizationToken();
  const suToken = getSuToken();

  if (newToken !== token) {
    token = newToken;

    if (TTSocket) {
      TTSocket?.disconnect();
    }

    TTSocket = createPhoenixSocket(token, suToken);

    if (!!token || !!suToken) {
      TTSocket!.connect();
    }

    TTSocket.onOpen(() => store.dispatch(socketConnected(TTSocket!)));
  }
});

export const getTTSocket = () => TTSocket;
