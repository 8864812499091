import { useCallback } from "react";

import { Dropdown, Nav, NavItem, NavLink } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  getSuToken,
  removeAuthorizationToken,
  setAuthorizationCookie,
  setAuthorizationToken,
} from "../../../../authorization_token";
import UserAvatar from "../../../../containers/UserAvatar";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { profilePath } from "../../../../path_helpers/admin_users_paths";
import { sessionSelector } from "../../../../store/sessionReducer";
import { logout } from "../../../../store/sessionReducer";

export default function UserMenu() {
  const session = useAppSelector(sessionSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  const { currentUser } = session;

  const unSuCb = useCallback(() => {
    const suToken = getSuToken();

    if (suToken) {
      removeAuthorizationToken();
      setAuthorizationToken(suToken);
      setAuthorizationCookie(suToken);

      document.location.href = "/";
    }
  }, []);
  const logoutCb = useCallback(() => {
    removeAuthorizationToken();
    dispatch(logout());
    navigate("/login");
  }, [dispatch, navigate]);

  return (
    <Nav className="TT-header-nav">
      <Dropdown as={NavItem}>
        <Dropdown.Toggle as={NavLink}>
          <UserAvatar avatar={currentUser.avatar} type="thumb" className="header" /> {currentUser.contact.displayName}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={profilePath(session)}>
            {t("translation:header.profile")}
          </Dropdown.Item>
          {localStorage.getItem("su_token") && (
            <Dropdown.Item onClick={unSuCb}>{t("translation:header.unsu")}</Dropdown.Item>
          )}
          <Dropdown.Item onClick={logoutCb}>{t("translation:header.logout")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
}
