import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import { createBrowserHistory } from "history";

import { rootReducer } from "./rootReducer";

export const history = createBrowserHistory();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    store.replaceReducer(rootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export { rootReducer };

export default store;
