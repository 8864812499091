import { combineReducers } from "redux";

import acdCallsReducer from "../components/acd/Calls/acdCallsReducer";
import dialerReducer from "../components/acd/dialerReducer";
import auditReducer from "../components/admin/audit/auditReducer";
import adminAuditReducer from "../components/admin/audit/auditReducer";
import communicationsReducer from "../components/admin/communications/communicationsReducer";
import mailAttachmentsReducer from "../components/admin/MailAttachments/mailAttachmentsReducer";
import mailTemplatesReducer from "../components/admin/MailTemplates/mailTemplatesReducer";
import adminProjectsReducer from "../components/admin/projects/projectsReducer";
import adminUsersReducer from "../components/admin/users/usersReducer";
import adminJobsReducer from "../components/admin/utilities/Jobs/adminJobsReducer";
import bottomBarReducer from "../components/app/BottomBar/bottomBarReducer";
import scaffoldReducer from "../components/app/scaffoldReducer";
import switcherReducer from "../components/app/Switcher/switcherReducer";
import calendarReducer from "../components/calendar/calendarReducer";
import contactsCallsReducer from "../components/contacts/calls/contactCallsReducer";
import contactsReducer from "../components/contacts/contactsReducer";
import contactsShowReducer from "../components/contacts/show/contactsShowReducer";
import CustomerContactsReducer from "../components/CustomerContacts/customerContactsReducer";
import customerFeedbackReducer from "../components/CustomerFeedback/customerFeedbackReducer";
import dashboardReducer from "../components/dashboard/dashboardReducer";
import documentsReducer from "../components/documents/documentsReducer";
import fileExchangeReducer from "../components/FileExchange/fileExchangeReducer";
import flashReducer from "../components/flash/flashReducer";
import hotseatReducer from "../components/HotSeat/hotseatReducer";
import inventoryReducer from "../components/inventory/inventoryReducer";
import knowledgebaseReducer from "../components/knowledgebase/knowledgebaseReducer";
import mailsReducer from "../components/mails/mailsReducer";
import messagesReducer from "../components/messages/messagesReducer";
import metalistReducer from "../components/MetaList/metalistReducer";
import punchlistReducer from "../components/punchlist/punchlistReducer";
import reminderNotificationReducer from "../components/ReminderNotifications/reminderNotificationReducer";
import rosterReducer from "../components/roster/rosterReducer";
import changeRequestsReducer from "../components/staff/ChangeRequests/changeRequestReducer";
import coachingsReducer from "../components/staff/Coachings/coachingsReducer";
import employeeCouponsReducer from "../components/staff/Coupons/employeeCouponsReducer";
import performanceReducer from "../components/staff/Performance/performanceReducer";
import timesheetReducer from "../components/staff/Timesheets/timesheetReducer";
import worktimesChangeRequestReducer from "../components/staff/WorktimeChangeRequests/worktimesChangeRequestReducer";
import worktimesReducer from "../components/staff/Worktimes/worktimesReducer";
import statsReducer from "../components/stats/statsReducer";
import ticketsReducer from "../components/tickets/ticketsReducer";
import todosReducer from "../components/todos/todos/todosReducer";
import acdReducer from "./acdReducer";
import sessionReducer from "./sessionReducer";
import sortingReducer from "./sortingReducer";

export const rootReducer = combineReducers({
  session: sessionReducer,
  flash: flashReducer,
  switcher: switcherReducer,
  sorting: sortingReducer,
  dialer: dialerReducer,
  scaffold: scaffoldReducer,
  acd: acdReducer,
  admin: combineReducers({
    users: adminUsersReducer,
    communications: communicationsReducer,
    mailTemplates: mailTemplatesReducer,
    mailAttachments: mailAttachmentsReducer,
    projects: adminProjectsReducer,
    audit: adminAuditReducer,
    jobs: adminJobsReducer,
  }),
  contacts: combineReducers({
    contacts: contactsReducer,
    show: contactsShowReducer,
    calls: contactsCallsReducer,
  }),
  todos: combineReducers({
    customerContacts: CustomerContactsReducer,
    todos: todosReducer,
  }),
  calendar: calendarReducer,
  coachings: coachingsReducer,
  stats: statsReducer,
  performance: performanceReducer,
  dashboard: dashboardReducer,
  knowledgebase: knowledgebaseReducer,
  worktimes: worktimesReducer,
  worktimesChangeRequests: worktimesChangeRequestReducer,
  timesheets: timesheetReducer,
  acdCalls: acdCallsReducer,
  roster: rosterReducer,
  documents: documentsReducer,
  mails: mailsReducer,
  messages: messagesReducer,
  tickets: ticketsReducer,
  audit: auditReducer,
  punchlist: punchlistReducer,
  metalist: metalistReducer,
  customerFeedback: customerFeedbackReducer,
  hotseat: hotseatReducer,
  employeeCoupons: employeeCouponsReducer,
  inventory: inventoryReducer,
  changeRequests: changeRequestsReducer,
  fileExchange: fileExchangeReducer,
  bottomBarReducer: bottomBarReducer,
  reminderNotifications: reminderNotificationReducer,
});
