import { useEffect } from "react";

import { useApolloClient } from "@apollo/client";

import { Route, Routes, useParams } from "react-router-dom";

import { GET_SESSION_CUSTOMER } from "../../api/session";
import Loading from "../../containers/Loading";
import { ReactLazyLoading } from "../../containers/ReactLoading";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../store/sessionReducer";
import { setCurrentCustomerAndProject } from "../../store/sessionReducer";
import type { CustomerDataInterface } from "../../types";
import Main from "../app/Main";

const CustomerContactsInterface = ReactLazyLoading(() => import("../CustomerContacts"));
const AdminInterface = ReactLazyLoading(() => import("../admin"));
const ProfileInterface = ReactLazyLoading(() => import("../admin/users/profile"));
const ContactsInterface = ReactLazyLoading(() => import("../contacts"));
const TodosInterface = ReactLazyLoading(() => import("../todos"));
const JobsInterface = ReactLazyLoading(() => import("../jobs"));
const CalendarInterface = ReactLazyLoading(() => import("../calendar"));
const StatsInterface = ReactLazyLoading(() => import("../stats"));
const InvoicesInterface = ReactLazyLoading(() => import("../invoices"));
const ProjectInterface = ReactLazyLoading(() => import("../project"));
const KnowledgebaseInterface = ReactLazyLoading(() => import("../knowledgebase"));
const MessagesInterface = ReactLazyLoading(() => import("../messages"));
const StaffInterface = ReactLazyLoading(() => import("../staff"));
const AcdInterface = ReactLazyLoading(() => import("../acd"));
const RosterInterface = ReactLazyLoading(() => import("../roster"));
const DocumentsInterface = ReactLazyLoading(() => import("../documents"));
const TicketsInterface = ReactLazyLoading(() => import("../tickets"));
const PunchlistInterface = ReactLazyLoading(() => import("../punchlist"));
const MailsInterface = ReactLazyLoading(() => import("../mails"));
const InventoryInterface = ReactLazyLoading(() => import("../inventory"));
const CustomerFeedbackInterface = ReactLazyLoading(() => import("../CustomerFeedback"));
const HotSeatInterface = ReactLazyLoading(() => import("../HotSeat"));
const ProjectNewsInterface = ReactLazyLoading(() => import("../ProjectNews"));
const FileExchange = ReactLazyLoading(() => import("../FileExchange"));
const ReminderNotifications = ReactLazyLoading(() => import("../ReminderNotifications"));

export default function RRoutes() {
  const { currentCustomer, currentProject } = useAppSelector(sessionSelector);
  const { customer, project } = useParams<"customer" | "project">();

  const client = useApolloClient();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (customer !== currentCustomer.identifier || project !== currentProject.identifier) {
      (async () => {
        const { data } = await client.query<CustomerDataInterface>({
          query: GET_SESSION_CUSTOMER,
          variables: { identifier: customer },
        });
        const newProject = data.customer.projects.find((p) => p.identifier === project);

        if (newProject) {
          dispatch(setCurrentCustomerAndProject({ customer: data.customer, project: newProject }));
        } else {
          throw new Error("could not find project!");
        }
      })();
    }
  }, [customer, project, currentCustomer, currentProject, client, dispatch]);

  if (customer !== currentCustomer.identifier || project !== currentProject.identifier) {
    return <Loading absolute fullscreen translucent backdrop />;
  }

  return (
    <Routes>
      <Route path="profile/*" element={<ProfileInterface />} />
      <Route path="admin/*" element={<AdminInterface />} />
      <Route path="contacts/*" element={<ContactsInterface />} />
      <Route path="mails/*" element={<MailsInterface />} />
      <Route path="todos/*" element={<TodosInterface />} />
      <Route path="customer-contacts/*" element={<CustomerContactsInterface />} />
      <Route path="jobs/*" element={<JobsInterface />} />
      <Route path="calendar/*" element={<CalendarInterface />} />
      <Route path="stats/*" element={<StatsInterface />} />
      <Route path="invoices/*" element={<InvoicesInterface />} />
      <Route path="project/*" element={<ProjectInterface />} />
      <Route path="knowledgebase/*" element={<KnowledgebaseInterface />} />
      <Route path="messages/*" element={<MessagesInterface />} />
      <Route path="staff/*" element={<StaffInterface />} />
      <Route path="roster/*" element={<RosterInterface />} />
      <Route path="documents/*" element={<DocumentsInterface />} />
      <Route path="acd/*" element={<AcdInterface />} />
      <Route path="tickets/*" element={<TicketsInterface />} />
      <Route path="punchlist/*" element={<PunchlistInterface />} />
      <Route path="inventory/*" element={<InventoryInterface />} />
      <Route path="customer-feedbacks/*" element={<CustomerFeedbackInterface />} />
      <Route path="hotseat/*" element={<HotSeatInterface />} />
      <Route path="project-news/*" element={<ProjectNewsInterface />} />
      <Route path="file-exchange/*" element={<FileExchange />} />
      <Route path="reminder-notifications/*" element={<ReminderNotifications />} />

      <Route path="/*" element={<Main />} />
    </Routes>
  );
}
