import { gql } from "@apollo/client";

export const PROFILE_LIST_QUERY = gql`
  query profiles($customerId: ID!, $limit: Int, $offset: Int) {
    profiles(customerId: $customerId, limit: $limit, offset: $offset) {
      id
      name
      attrs
      insertedAt
      updatedAt
    }
  }
`;

export const COUNT_PROFILES_QUERY = gql`
  query countProfiles($customerId: ID!) {
    countProfiles(customerId: $customerId)
  }
`;

export const PROFILE_GET_QUERY = gql`
  query profile($customerId: ID!, $id: ID!) {
    profile(customerId: $customerId, id: $id) {
      id
      name
      attrs
      insertedAt
      updatedAt

      users {
        id
        username
        contact {
          id
          displayName
        }
      }
    }
  }
`;

export const PROFILE_DELETE_MUTATION = gql`
  mutation deleteProfile($id: ID!, $customerId: ID!) {
    deleteProfile(id: $id, customerId: $customerId) {
      id
    }
  }
`;

export const PROFILE_MUTATION = gql`
  mutation mutateProfile($customerId: ID!, $id: ID, $profile: ProfileInput!) {
    mutateProfile(customerId: $customerId, id: $id, profile: $profile) {
      id
      name
      insertedAt
      updatedAt
    }
  }
`;
