import { gql } from "@apollo/client";

export const CUSTOMER_CONTACT_DASHBOARD_QUERY = gql`
  query customerContactDashboard($customerId: ID!, $projectId: ID!) {
    customerContactDashboard(customerId: $customerId, projectId: $projectId) {
      hour
      types
    }
  }
`;

export const CUSTOMER_CONTACT_ATTR_STATS_QUERY = gql`
  query customerContactAttrStats(
    $customerId: ID!
    $projectId: ID!
    $attrName: String!
    $start: NaiveDateTime
    $stop: NaiveDateTime
    $filterAttrs: Json
  ) {
    customerContactAttrStats(
      customerId: $customerId
      projectId: $projectId
      attrName: $attrName
      start: $start
      stop: $stop
      filterAttrs: $filterAttrs
    ) {
      stats
    }
  }
`;

export const CUSTOMER_CONTACT_ATTR_STATS_BY_USER_QUERY = gql`
  query customerContactAttrStats(
    $customerId: ID!
    $projectId: ID!
    $attrName: String!
    $start: NaiveDateTime
    $stop: NaiveDateTime
  ) {
    customerContactAttrStats(
      customerId: $customerId
      projectId: $projectId
      attrName: $attrName
      start: $start
      stop: $stop
    ) {
      stats
    }
  }
`;

export const CUSTOMER_CONTACT_ATTR_STATS_BY_HOUR_QUERY = gql`
  query customerContactAttrStatsByHour(
    $customerId: ID!
    $projectId: ID!
    $attrName: String!
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
  ) {
    customerContactAttrStatsByHour(
      customerId: $customerId
      projectId: $projectId
      attrName: $attrName
      start: $start
      stop: $stop
    ) {
      stats
    }
  }
`;

export const TASK_OFFERS_LIST_QUERY = gql`
  query taskOffers($customerId: ID!, $projectId: ID!, $filters: TaskOfferListFilters) {
    taskOffers(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      name
      start
      ends
    }
  }
`;

export const COUNT_TASK_OFFERS_QUERY = gql`
  query countTaskOffers($customerId: ID!, $projectId: ID!, $filters: TaskOfferListFilters) {
    countTaskOffers(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const GET_TASK_OFFER_QUERY = gql`
  query taskOffer($customerId: ID!, $projectId: ID!, $id: ID!) {
    taskOffer(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      start
      ends
      products {
        id
        name
        price
      }
    }
  }
`;

export const TASK_PRODUCTS_QUERY = gql`
  query taskProducts($customerId: ID!, $projectId: ID!, $filters: TaskProductListFilters) {
    taskProducts(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      name
      price
    }
  }
`;

export const GET_TASK_PRODUCT_QUERY = gql`
  query taskProduct($customerId: ID!, $projectId: ID!, $id: ID!) {
    taskProduct(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      price
      offer {
        id
        name
      }
    }
  }
`;

export const TASK_OFFER_MUTATION = gql`
  mutation mutateTaskOffer($customerId: ID!, $projectId: ID!, $id: ID, $values: TaskOfferInput!) {
    mutateTaskOffer(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      id
      name
      start
      ends
    }
  }
`;

export const DELETE_TASK_OFFERS_MUTATION = gql`
  mutation deleteTaskOffer($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteTaskOffer(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      start
      ends
    }
  }
`;

export const TASK_PRODUCT_MUTATION = gql`
  mutation mutateTaskProduct($customerId: ID!, $projectId: ID!, $offerId: ID!, $id: ID, $values: TaskProductInput!) {
    mutateTaskProduct(customerId: $customerId, projectId: $projectId, offerId: $offerId, id: $id, values: $values) {
      id
      name
      price
    }
  }
`;

export const DELETE_TASK_PRODUCT_MUTATION = gql`
  mutation deleteTaskProduct($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteTaskProduct(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      price
    }
  }
`;

export const EXPORT_TASKS_MUTATION = gql`
  mutation exportTasks(
    $customerId: ID!
    $projectId: ID!
    $filters: TaskListFilters
    $fields: [String!]!
    $fieldNames: [String!]!
  ) {
    exportTasks(
      customerId: $customerId
      projectId: $projectId
      filters: $filters
      fields: $fields
      fieldNames: $fieldNames
    ) {
      id
    }
  }
`;

export const REGEN_TASK_POINTS_MUTATION = gql`
  mutation recalculateTaskPoints($starts: NaiveDateTime!, $stops: NaiveDateTime!) {
    recalculateTaskPoints(starts: $starts, stops: $stops) {
      id
    }
  }
`;
