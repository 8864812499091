import { useEffect, useState } from "react";

import { gql, useQuery } from "@apollo/client";

import { isToday } from "date-fns";
import { Toast } from "react-bootstrap";

import { DoneButton } from "../../../containers/buttons";
import { formatCurrency } from "../../../number_format";
import type { CurrentEmployeeCouponDataInterface } from "../../../types";
import { parsedDate } from "../../../utils/dates";

const CURRENT_COUPON_QUERY = gql`
  query currentEmployeeCoupon {
    currentEmployeeCoupon {
      id
      month
      amount
    }
  }
`;

export default function CouponBonus() {
  const [isVisible, setIsVisible] = useState(false);

  const { data } = useQuery<CurrentEmployeeCouponDataInterface>(CURRENT_COUPON_QUERY);

  useEffect(() => {
    if (!data?.currentEmployeeCoupon) return;

    const date = localStorage.getItem(`coupon-bonus-${data.currentEmployeeCoupon.id}`);
    const parsed = date ? parsedDate(date) : null;
    const seenToday = parsed ? isToday(parsed) : false;

    setIsVisible(!!data.currentEmployeeCoupon.amount && !seenToday);
  }, [data]);

  const currentCoupon = data?.currentEmployeeCoupon;

  if (!currentCoupon || currentCoupon === null) {
    return null;
  }

  async function hide() {
    setIsVisible(false);
    if (currentCoupon) {
      localStorage.setItem(`coupon-bonus-${currentCoupon.id}`, JSON.stringify(new Date()));
    }
  }

  return (
    <Toast className="TT-coupon-toast" show={isVisible} onClose={hide}>
      <Toast.Header closeButton>
        <span className="me-auto">Gutschein verfügbar!</span>
      </Toast.Header>

      <Toast.Body>
        <h4>Vielen herzlichen Dank für Ihre herausragende Arbeit!</h4>
        <p>
          Wir möchten uns auf besondere Weise bei Ihnen bedanken und haben einen Sachgutschein über{" "}
          {formatCurrency(currentCoupon.amount)} für Sie vorbereitet.
        </p>

        <DoneButton onClick={hide} size="sm">
          alles klar!
        </DoneButton>
      </Toast.Body>
    </Toast>
  );
}
