import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DoneButton } from "../../containers/buttons";
import TTModal from "../TTModal";

type PropsType = {
  toggle: () => void;
  show: boolean;
};

export default function HelpModal({ toggle, show }: PropsType) {
  const { t } = useTranslation(["helpModal"]);

  return (
    <TTModal onHide={toggle} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>DON'T PANIC!</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          {t("helpModal:p1")}
          <strong>{t("helpModal:right_and_important")}</strong>
        </p>

        <p>{t("helpModal:p2")}</p>

        <ul>
          <li>{t("helpModal:li1")}</li>

          <li>{t("helpModal:li2")}</li>

          <li> {t("helpModal:li3")}</li>

          <li>{t("helpModal:li4")}</li>

          <li>
            {t("helpModal:li5")}

            <ol>
              <li> {t("helpModal:li6")}</li>
              <li> {t("helpModal:li7")}</li>
              <li> {t("helpModal:li8")}</li>
              <li>{t("helpModal:li9")}</li>
              <li>{t("helpModal:li10")}</li>
              <li>{t("helpModal:li11")}</li>
              <li>{t("helpModal:li12")}</li>
            </ol>
          </li>
        </ul>

        <h4>{t("helpModal:contact_informations")}</h4>

        <dl className="TT-help-modal-contact-infos">
          <dt>IT Support Line</dt>
          <dd className="last">
            <a href="tel:+4972435610665">072435610 665</a>
          </dd>

          <dt>Christian Richter</dt>
          <dd>c.richter@termitel.de</dd>
          <dd className="last">
            <a href="tel:+4972435610308">072435610 308</a>
          </dd>

          <dt>Dominik Guerman</dt>
          <dd>d.guerman@termitel.de</dd>
          <dd>
            <a href="tel:+4972435610362">072435610 362</a>
          </dd>
          <dd className="last">
            <a href="tel:+4915222730046">0152 22730046</a>
          </dd>

          <dt>Anil Erdogan</dt>
          <dd>a.erdogan@termitel.de</dd>
          <dd>
            <a href="tel:+4972435610330">072435610 330</a>
          </dd>
          <dd className="last">
            <a href="tel:+4917641667774">0176 41667774</a>
          </dd>

          <dt>Kenza Boukacem</dt>
          <dd>k.boukacem@termitel.de</dd>
          <dd>
            <a href="tel:+4972435610336">072435610 336</a>
          </dd>
          <dd className="last">
            <a href="tel:+491604882592">0160 4882592</a>
          </dd>

          <dt>Matthew Benson</dt>
          <dd>m.benson@termitel.de</dd>
          <dd>
            <a href="tel:+4972435610100">072435610 100</a>
          </dd>
          <dd className="last">
            <a href="tel:+491702769644">0170 2769644</a>
          </dd>

          <dt>Christian Kruse</dt>
          <dd>c.kruse@termitel.de</dd>
          <dd>
            <a href="tel:+4972435610666">072435610 666</a>
          </dd>
          <dd className="last">
            <a href="tel:+491702769646">0170 2769646</a>
          </dd>
        </dl>
      </Modal.Body>

      <Modal.Footer>
        <DoneButton onClick={toggle}>{t("helpModal:understood")}</DoneButton>
      </Modal.Footer>
    </TTModal>
  );
}
