import type { SessionInterface } from "../store/sessionReducer";
import type { InventoryItem, InventoryItemType, InventoryReservation, InventorySet, UserInterface } from "../types";
import { sessionPrefix } from "./helpers";

export const inventoryItemTypesPath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory`;
export const inventoryItemTypePath = (session: SessionInterface, itemType: InventoryItemType) =>
  `${sessionPrefix(session)}/inventory/${itemType.id}`;
export const editInventoryItemTypePath = (session: SessionInterface, itemType: InventoryItemType) =>
  `${sessionPrefix(session)}/inventory/${itemType.id}/edit`;
export const newInventoryItemTypePath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory/new`;

export const inventoryImportDguvPath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory/dguv-import`;
export const dguvCheckListPath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory/needs-check`;
export const handoverItemsPath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory/handover`;
export const handbackItemsPath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory/handback`;

export const inventoryItemPath = (session: SessionInterface, itemType: InventoryItemType, item: InventoryItem) =>
  `${sessionPrefix(session)}/inventory/${itemType.id}/${item.id}`;
export const soloInventoryItemPath = (session: SessionInterface, item: InventoryItem) =>
  `${sessionPrefix(session)}/inventory/${item.itemTypeId}/${item.id}`;

export const inventorySetsPath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory/sets`;
export const editInventorySetPath = (session: SessionInterface, set: InventorySet) =>
  `${sessionPrefix(session)}/inventory/sets/${set.id}/edit`;
export const newInventorySetPath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory/sets/new`;

export const inventoryReservationsPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/inventory/reservations`;
export const newInventoryReservationPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/inventory/reservations/new`;
export const editInventoryReservationPath = (session: SessionInterface, reservation: InventoryReservation) =>
  `${sessionPrefix(session)}/inventory/reservations/${reservation.id}/edit`;
export const handoverInventoryReservationPath = (session: SessionInterface, reservation: InventoryReservation) =>
  `${sessionPrefix(session)}/inventory/reservations/${reservation.id}/handover`;

export const inventoryUsersPath = (session: SessionInterface) => `${sessionPrefix(session)}/inventory/users`;
export const showInventoryUserPath = (session: SessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/inventory/users/${user.id}`;
