import { useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import {
  createEditor,
  Descendant,
  Editor,
  Point,
  Range,
  Element as SlateElement,
  Node as SlateNode,
  Transforms,
} from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from "slate-react";

import type { ElementType, InlineTypes } from "../../types";
import { Element } from "./Element";
import { Leaf } from "./Leaf";
import Toolbar, { toggleMark } from "./Toolbar";
import { insertImage } from "./Toolbar/ImageButton";
import { isImageUrl, parsedValue } from "./utilts";

export type PropsType = {
  id: string;
  value: string | Descendant[];
  onChange?: (value: Descendant[]) => void;
  readOnly?: boolean;
  toolbar?: boolean;
  onImageUpload?: (ev: React.ChangeEvent<HTMLInputElement>) => Promise<string | undefined>;
};

const dummy = () => {};

const SHORTCUTS: { [key: string]: string } = {
  "-": "list-item",
  "+": "list-item",
  ">": "quote",
  "#": "heading-one",
  "##": "heading-two",
  "###": "heading-three",
};

const INLINE_SHORTCUTS: { [key: string]: string } = {
  "**": "bold",
  __: "italic",
  "``": "code",
};

const withShortcuts = (editor: Editor) => {
  const { deleteBackward, insertText, insertBreak } = editor;

  editor.insertText = (text) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });

      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };

      const lastChars = Editor.string(editor, range).slice(-2);

      if (Object.keys(INLINE_SHORTCUTS).includes(lastChars)) {
        toggleMark(editor, INLINE_SHORTCUTS[lastChars] as InlineTypes);
        deleteBackward("character");
        deleteBackward("character");
      }
    }

    if (text.endsWith(" ") && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range) + text.slice(0, -1);
      const type = SHORTCUTS[beforeText];

      if (type) {
        Transforms.select(editor, range);

        if (!Range.isCollapsed(range)) {
          Transforms.delete(editor);
        }

        const newProperties: Partial<SlateElement> = {
          type: type as ElementType,
        };
        Transforms.setNodes<SlateElement>(editor, newProperties, {
          match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
        });

        return;
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (
          !Editor.isEditor(block) &&
          SlateElement.isElement(block) &&
          block.type !== "paragraph" &&
          Point.equals(selection.anchor, start)
        ) {
          const newProperties: Partial<SlateElement> = {
            type: "paragraph",
          };
          Transforms.setNodes(editor, newProperties);

          if (block.type === "list-item") {
            Transforms.unwrapNodes(editor, {
              match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "bulleted-list",
              split: true,
            });
          }

          return;
        }
      }

      deleteBackward(...args);
    }
  };

  editor.insertBreak = () => {
    insertBreak();

    const match = Editor.above(editor, {
      match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
    });

    if (match) {
      const [block] = match;

      if (!Editor.isEditor(block) && block.type === "list-item") {
        return editor;
      }
    }

    const newProperties: Partial<SlateElement> = {
      type: "paragraph",
    };
    Transforms.setNodes(editor, newProperties);
  };

  return editor;
};

const withImages = (editor: Editor) => {
  const { isVoid, insertData } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      const fileListArray = Array.from(files);

      fileListArray.forEach((file) => {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url as string);
          });

          reader.readAsDataURL(file);
        }
      });
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const withLinks = (editor: Editor) => {
  const { isInline } = editor;

  editor.isInline = (element) => (element.type === "link" ? true : isInline(element));

  return editor;
};

export default function SlateEditor({ value, onChange, readOnly = false, toolbar = true, onImageUpload }: PropsType) {
  const editor = useMemo(() => withShortcuts(withLinks(withImages(withReact(withHistory(createEditor()))))), []);
  const [v, setV] = useState(typeof value === "string" || !value || !value.length ? parsedValue(value) : value);

  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

  useEffect(() => {
    const updatedValue = parsedValue(value);
    setV(updatedValue);
    editor.children = updatedValue;
  }, [value, readOnly, editor]);

  const handleDOMBeforeInput = useCallback(
    (e: InputEvent) => {
      queueMicrotask(() => {
        const pendingDiffs = ReactEditor.androidPendingDiffs(editor);

        const scheduleFlush = pendingDiffs?.some(({ diff, path }) => {
          if (!diff.text.endsWith(" ")) {
            return false;
          }

          const { text } = SlateNode.leaf(editor, path);
          const beforeText = text.slice(0, diff.start) + diff.text.slice(0, -1);
          if (!(beforeText in SHORTCUTS)) {
            return false;
          }

          const blockEntry = Editor.above(editor, {
            at: path,
            match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
          });
          if (!blockEntry) {
            return false;
          }

          const [, blockPath] = blockEntry;
          return Editor.isStart(editor, Editor.start(editor, path), blockPath);
        });

        if (scheduleFlush) {
          ReactEditor.androidScheduleFlush(editor);
        }
      });
    },
    [editor],
  );

  return (
    <div className={clsx("TT-slate-editor", { editable: !readOnly })}>
      <Slate initialValue={v} onChange={onChange || dummy} editor={editor}>
        {toolbar && <Toolbar onImageUpload={onImageUpload} />}

        <Editable
          className={clsx("TT-slate-editor-editable", { editable: !readOnly })}
          value={v}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly={readOnly}
          onDOMBeforeInput={handleDOMBeforeInput}
          spellCheck
          autoFocus
        />
      </Slate>
    </div>
  );
}
