import React from "react";

import clsx from "clsx";
import _ from "lodash";
import TextareaAutosize from "react-autosize-textarea";

export interface RefPropsInterface {
  value?: string;
  readOnly?: boolean;
  valid?: boolean;
  invalid?: boolean;
  ref?: React.Ref<any>;
  children?: React.ReactNode;
  id?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  onBlur?: (ev: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
  spellCheck?: boolean;
}

interface PropsInterface {
  forwardedRef: React.Ref<any>;
}

type PropsType = RefPropsInterface & PropsInterface;

const TxtArea: any = TextareaAutosize;

function TTTextArea(props: PropsType) {
  let className = clsx("form-control", props.className, {
    "is-invalid": props.invalid,
    "is-valid": props.valid,
  });

  return (
    <TxtArea
      rows={3}
      maxRows={15}
      className={className}
      {..._.omit(props, ["valid", "invalid", "forwardedRef", "className"])}
      ref={props.forwardedRef}
      placeholder={props.placeholder}
    >
      {props.children}
    </TxtArea>
  );
}

export default React.forwardRef<React.FunctionComponent, RefPropsInterface>((props, ref) => (
  <TTTextArea {...props} forwardedRef={ref} />
));
