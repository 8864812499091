import React, { useEffect, useState } from "react";

type PropsType = {
  desktopComponent: React.ReactNode;
  mobileComponent: React.ReactNode;
};

export default function ResponsiveComponent({ desktopComponent, mobileComponent }: PropsType) {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      const mq = window.matchMedia("(max-width: 768px)");
      setIsMobile(mq.matches);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile ? <>{mobileComponent}</> : <>{desktopComponent}</>;
}
