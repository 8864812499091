import { SessionInterface } from "../store/sessionReducer";
import type { JobResultInterface, PermissionType } from "../types";

export interface JobsMayInterface {
  (session: SessionInterface, path: "jobs"): boolean;
  (session: SessionInterface, path: "jobs"): boolean;
  (session: SessionInterface, path: "jobs", action: "show" | "delete", task: JobResultInterface): boolean;
}

const JobsPermissions: PermissionType<null> = {
  jobs: {
    index: () => true,
  },
};

export default JobsPermissions;
