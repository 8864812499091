import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

const TASK_FRAGMENT = gql`
  fragment TaskFragment on Task {
    id
    contactType
    type
    questionnaireType
    attrs
    reactionNeeded
    description
    sent

    insertedAt
    updatedAt

    contact {
      id
      displayName
      attrs
    }

    creator {
      ...UserFragment
    }

    editor {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const TASKS_LIST_QUERY = gql`
  query tasks($customerId: ID!, $projectId: ID!, $filters: TaskListFilters) {
    tasks(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASKS_COUNT_QUERY = gql`
  query countTasks($customerId: ID!, $projectId: ID!, $filters: TaskListFilters) {
    countTasks(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const TASK_GET_QUERY = gql`
  query task($customerId: ID!, $projectId: ID!, $id: ID!) {
    task(customerId: $customerId, projectId: $projectId, id: $id) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASK_MUTATION = gql`
  mutation mutateTask($customerId: ID!, $projectId: ID!, $id: ID, $values: TaskInput!) {
    mutateTask(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASK_DELETE_MUTATION = gql`
  mutation deleteTask($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteTask(customerId: $customerId, projectId: $projectId, id: $id) {
      id
    }
  }
`;

export const TASK_SEND_MUTATION = gql`
  mutation sendTask($customerId: ID!, $projectId: ID!, $id: ID!) {
    sendTask(customerId: $customerId, projectId: $projectId, id: $id) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const GET_QUESTIONNAIRE_QUERY = gql`
  query questionnaireTask($customerId: ID!, $projectId: ID!, $contactId: ID!, $questionnaireType: String!) {
    questionnaireTask(
      customerId: $customerId
      projectId: $projectId
      contactId: $contactId
      questionnaireType: $questionnaireType
    ) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;
