import { RS_API_URI } from "../api/session";
import { SessionInterface } from "../store/sessionReducer";
import type { DocumentAttachmentType, DocumentType, UserInterface } from "../types";
import { sessionPrefix } from "./helpers";

export const documentsPath = (session: SessionInterface) => `${sessionPrefix(session)}/documents`;
export const newDocumentPath = (session: SessionInterface) => `${sessionPrefix(session)}/documents/new`;
export const missingSignaturesPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/documents/missing-signatures`;
export const missingSignaturesForUserPath = (session: SessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/documents/missing-signatures/${user.id}`;
export const showDocumentPath = (session: SessionInterface, document: DocumentType) =>
  `${sessionPrefix(session)}/documents/${document.id}`;
export const editDocumentPath = (session: SessionInterface, document: DocumentType) =>
  `${sessionPrefix(session)}/documents/${document.id}/edit`;

export const documentAttachmentPath = (attachment: DocumentAttachmentType) =>
  `${RS_API_URI}/documents/attachments/${attachment.id}`;
