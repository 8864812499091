import React from "react";

import _ from "lodash";
import { MdArrowDownward, MdArrowUpward, MdSort } from "react-icons/md";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { sortButtonClicked, SortingReducerEntryType, SortingReducerType } from "../../store/sortingReducer";

const getCurrentOrder = (path: string, state: SortingReducerType) => {
  path = (path || "").replace(/^\/[^/]+\/[^/]+\//, "");
  return state[path] || { order: "name", direction: "ASC" };
};

type Props = {
  path?: string;
  order: string;
  orderAttr?: string;
  children: React.ReactNode;
};

const sortIcon = (active: boolean, dir: "ASC" | "DESC") => {
  if (!active) return <MdSort />;
  if (dir === "ASC") return <MdArrowDownward />;
  return <MdArrowUpward />;
};

const isActive = (sorting: SortingReducerEntryType, props: Props) => {
  if (sorting.order === props.order && (!sorting.orderAttr || sorting.orderAttr === props.orderAttr)) {
    return "active";
  }

  return "inactive";
};

export default function TableSortButton(props: Props) {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const path = props.path || pathname;
  const sorting = useAppSelector((state) => state.sorting);

  const onChangeOrder = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    dispatch(
      sortButtonClicked({
        path: (path || "").replace(/^\/[^/]+\/[^/]+\//, "") as any,
        order: props.order,
        orderAttr: props.orderAttr,
      }),
    );
  };

  const currentSorting = getCurrentOrder(path, sorting);
  const active = isActive(currentSorting, props);
  const filteredProps = _.omit(props, ["order", "orderAttr", "sortButtonClicked"]);

  return (
    <button
      className={`TT-table-sort-button ${active} ${currentSorting.direction}`}
      onClick={onChangeOrder}
      {...filteredProps}
    >
      {props.children} {sortIcon(active === "active", currentSorting.direction)}
    </button>
  );
}
