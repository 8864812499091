import { differenceInDays } from "date-fns";

import { SessionInterface } from "../store/sessionReducer";
import type { InvoiceInterface, PermissionType } from "../types";
import { parsedDate } from "../utils/dates";
import { featureEnabled, isAdmin } from "./helpers";

export interface InvoicesMayInterface {
  (session: SessionInterface, path: "invoices"): boolean;
  (session: SessionInterface, path: "invoices", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "invoices",
    action: "show" | "edit" | "delete" | "confirm",
    invoice: InvoiceInterface,
  ): boolean;
}

const MAX_EDITABLE_DAYS = 3;

const InvoicePermissions: PermissionType<InvoiceInterface> = {
  invoices: {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "billing"),
    show: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "billing"),
    new: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "billing") && isAdmin(currentUser),
    edit: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "billing") &&
      isAdmin(currentUser) &&
      !resource.locked &&
      (resource.preview ||
        (differenceInDays(new Date(), parsedDate(resource.updatedAt)) <= MAX_EDITABLE_DAYS &&
          !["sug-ag", "sug-gmbh"].includes(currentCustomer.identifier))),
    delete: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "billing") &&
      isAdmin(currentUser) &&
      !resource.locked &&
      (resource.preview ||
        (differenceInDays(new Date(), parsedDate(resource.updatedAt)) <= MAX_EDITABLE_DAYS &&
          !["sug-ag", "sug-gmbh"].includes(currentCustomer.identifier))),
    confirm: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "billing") &&
      isAdmin(currentUser) &&
      !resource.locked &&
      (resource.preview ||
        (differenceInDays(new Date(), parsedDate(resource.updatedAt)) <= MAX_EDITABLE_DAYS &&
          !["sug-ag", "sug-gmbh"].includes(currentCustomer.identifier))),
  },
};

export default InvoicePermissions;
