import { gql } from "@apollo/client";

export const FEASTDAYS_LIST_QUERY = gql`
  query feastdays($customerId: ID!, $filters: ListFeastdaysFilters) {
    feastdays(customerId: $customerId, filters: $filters) {
      id
      name
      day
      insertedAt
      updatedAt
    }
  }
`;

export const COUNT_FEASTDAYS_QUERY = gql`
  query countFeastdays($customerId: ID!, $filters: CountFeastdaysFilters) {
    countFeastdays(customerId: $customerId, filters: $filters)
  }
`;

export const FEASTDAY_GET_QUERY = gql`
  query feastday($customerId: ID!, $id: ID!) {
    feastday(customerId: $customerId, id: $id) {
      id
      name
      day
      insertedAt
      updatedAt
    }
  }
`;

export const FEASTDAY_DELETE_MUTATION = gql`
  mutation deleteFeastday($customerId: ID!, $id: ID!) {
    deleteFeastday(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const FEASTDAY_MUTATION = gql`
  mutation mutateFeastday($customerId: ID!, $id: ID, $feastday: FeastdayInput!) {
    mutateFeastday(customerId: $customerId, id: $id, feastday: $feastday) {
      id
      name
      day
      insertedAt
      updatedAt
    }
  }
`;
