import clsx from "clsx";

import { useAppSelector } from "../../hooks";
import FlashMessage from "./FlashMessage";
import { flashSelector } from "./flashReducer";

type PropsType = {
  className?: string;
  inline?: boolean;
};

export default function FlashMessagesList({ className, inline }: PropsType) {
  const flash = useAppSelector(flashSelector);

  if (flash.length === 0) {
    return null;
  }

  return (
    <div className={clsx("TT-flash-message-list", className, { inline })}>
      {flash.map((message) => (
        <FlashMessage key={message.id} message={message} />
      ))}
    </div>
  );
}
