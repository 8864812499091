import { ToastContainer } from "react-bootstrap";

import { useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import CouponBonus from "./CouponBonus";

export default function ToastNotifications() {
  const { currentUser } = useAppSelector(sessionSelector);

  if (currentUser.customerId !== "1") return null;

  return (
    <ToastContainer position="top-end" className="TT-toast-container">
      <CouponBonus />
    </ToastContainer>
  );
}
