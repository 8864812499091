import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { bottomBarSelector, setDisplayNotitificationDialog } from "../bottomBarReducer";

export default function Notifications() {
  const { t } = useTranslation(["bottomBar"]);
  const { displayNotitificationDialog } = useAppSelector(bottomBarSelector);
  const dispatch = useAppDispatch();

  if (!displayNotitificationDialog) {
    return null;
  }

  const allowNotifications = () => {
    Notification.requestPermission().finally(() => {
      dispatch(setDisplayNotitificationDialog(false));
    });
  };

  return (
    <div>
      {t("bottomBar:notifications_avilable")}
      <Button onClick={allowNotifications}>{t("bottomBar:allow")}</Button>
    </div>
  );
}
