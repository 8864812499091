import { gql } from "@apollo/client";

export const CUSTOMER_MUTATION = gql`
  mutation mutateCustomer($id: ID, $values: CustomerInput!) {
    mutateCustomer(id: $id, values: $values) {
      id
      name
      active
      inDeletion
      identifier
      defaultProjectId
      attrs

      projects {
        id
        name
        identifier
        active
      }

      customerUsers {
        id
        username
        contact {
          id
          displayName
        }
      }

      customerAdministrators {
        id
        username
        contact {
          id
          displayName
        }
      }
    }
  }
`;

export const CUSTOMER_LIST_QUERY = gql`
  query customers($filters: CustomersListFilter) {
    customers(filters: $filters) {
      id
      name
      identifier
      active
      inDeletion
      attrs

      logo {
        thumb
        medium
        original
      }
    }
  }
`;

export const CUSTOMER_LIST_FOR_USER_QUERY = gql`
  query customersForUser($userId: ID!) {
    customersForUser(userId: $userId) {
      id
      name
      identifier
    }
  }
`;

export const CUSTOMER_LIST_WITH_CUSTOMER_ADMINISTATORS_QUERY = gql`
  query customersWithCustomerAdministrators($filters: CustomersListFilter) {
    customers(filters: $filters) {
      id
      name
      identifier
      active
      inDeletion
      attrs

      logo {
        thumb
        medium
        original
      }

      customerAdministrators {
        id
        username
      }
    }
  }
`;

export const CUSTOMER_LIST_WITH_CUSTOMER_ADMINISTATORS_AND_PROJECTS_QUERY = gql`
  query customersWithCustomerAdministratorsAndProjects($filters: CustomersListFilter) {
    customers(filters: $filters) {
      id
      name
      identifier
      active
      inDeletion
      attrs

      logo {
        thumb
        medium
        original
      }

      customerAdministrators {
        id
        username
      }

      projects {
        id
        name
        identifier
        active
        attrs
        insertedAt
        updatedAt
      }
    }
  }
`;

export const CUSTOMER_GET_QUERY = gql`
  query customer($customerId: ID!) {
    customer(id: $customerId) {
      id
      name
      active
      inDeletion
      identifier
      defaultProjectId
      attrs

      logo {
        thumb
        medium
        original
      }

      projects {
        id
        name
        identifier
        active
      }

      customerUsers {
        id
        username
        contact {
          id
          displayName
        }
      }

      customerAdministrators {
        id
        username
        contact {
          id
          displayName
        }
      }
    }
  }
`;

export const CUSTOMER_AND_PROJECTS_GET_QUERY = gql`
  query customerAndProjects($customerId: ID!) {
    customer(id: $customerId) {
      id
      name
      identifier

      projects {
        id
        name
        identifier
      }
    }
  }
`;

export const CUSTOMER_DELETE_MUTATION = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      id
      name
      active
      inDeletion
      identifier
      defaultProjectId
      attrs

      projects {
        id
        name
        identifier
        active
      }

      customerUsers {
        id
        username
        contact {
          id
          displayName
        }
      }

      customerAdministrators {
        id
        username
        contact {
          id
          displayName
        }
      }
    }
  }
`;
