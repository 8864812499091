import { differenceInMinutes } from "date-fns";

import { SessionInterface } from "../store/sessionReducer";
import type { PermissionType, TicketTimelineEntryType, TicketType } from "../types";
import { parsedDate } from "../utils/dates";
import { featureEnabled, isAdmin, isCustomerAdmin, userFeatureEnabled } from "./helpers";

export interface TicketMayInterface {
  (session: SessionInterface, path: "tickets"): boolean;
  (session: SessionInterface, path: "tickets", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "tickets",
    action: "edit" | "delete" | "show" | "owner" | "order" | "forecastEffort",
    customer: TicketType,
  ): boolean;

  (
    session: SessionInterface,
    path: "tickets/timeline",
    action: "edit" | "delete",
    entry: TicketTimelineEntryType,
  ): boolean;
}

const TicketPermissions: PermissionType<TicketType> = {
  tickets: {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "tickets"),
    edit: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "tickets") &&
      (isCustomerAdmin(currentUser, currentCustomer) ||
        resource.ownerId === currentUser.id ||
        resource.reporterId === currentUser.id),
    owner: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "tickets") && userFeatureEnabled(currentUser, "tickets/owner"),
    delete: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "tickets") && isCustomerAdmin(currentUser, currentCustomer),
    forecastEffort: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "tickets") &&
      (isAdmin(currentUser) || userFeatureEnabled(currentUser, "tickets/effort")),
    order: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "tickets") &&
      (isAdmin(currentUser) || userFeatureEnabled(currentUser, "tickets/order")),
  },
};

const TicketTimelinePermissions: PermissionType<TicketTimelineEntryType> = {
  "tickets/timeline": {
    edit: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "tickets") &&
      resource.userId === currentUser.id &&
      resource.type !== "STATUS_CHANGE" &&
      differenceInMinutes(new Date(), parsedDate(resource.insertedAt)) < 10,
    delete: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "tickets") &&
      resource.userId === currentUser.id &&
      resource.type !== "STATUS_CHANGE" &&
      differenceInMinutes(new Date(), parsedDate(resource.insertedAt)) < 10,
  },
};

const TicketsPermissions = {
  ...TicketPermissions,
  ...TicketTimelinePermissions,
};

export default TicketsPermissions;
