import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../hooks";
import { sessionSelector } from "../../../../store/sessionReducer";
import { dateFormat } from "../../../../utils/dates";
import SlateEditor from "../../../SlateEditor";
import type { DocumentSubcompProps, ValuesType } from "../../types";
import { DOCUMENT_FORMS } from "../../utils";

export default function Preview({ document }: DocumentSubcompProps) {
  const { t } = useTranslation(["translation", "documents"]);
  const { values } = useFormikContext<ValuesType>();
  const { currentCustomer } = useAppSelector(sessionSelector);
  const [Icon] = DOCUMENT_FORMS[values.type];

  return (
    <fieldset>
      <legend>{t("translation:global.preview")}</legend>

      <div className="TT-documents-document-workinginstruction">
        <header>
          <img src={currentCustomer.logo.medium} alt={currentCustomer.name} className="logo" />
          <div className="type">{t(`documents:document_types.${values.type}`)}</div>
          <div className="icon">
            <Icon />
          </div>
          <h2>{values.subject}</h2>
        </header>

        <div className="meta-info">
          <div>
            <strong>{t("documents:field_names.id")}</strong> {document.id}
          </div>
          <div>
            <strong>{t("documents:field_names.valid_from")}</strong>
            {document.validFrom ? dateFormat(document.validFrom) : "-"}
          </div>
          <div>
            <strong>{t("documents:field_names.valid_until")}</strong>
            {document.validUntil ? dateFormat(document.validUntil) : "-"}
          </div>
          <div>
            <strong>{t("translation:global.created")}</strong>
            {dateFormat(document.insertedAt)}
          </div>
          <div>
            <strong>{t("translation:global.updated")}</strong>
            {dateFormat(document.updatedAt)}
          </div>
        </div>

        <SlateEditor id="document-preview" value={values.document.content || ""} toolbar={false} readOnly />

        <p>{t("documents:show.sorted_under")}</p>

        <ul className="TT-documents-document-keywords">
          {values.keywords.map((k) => (
            <li key={k.id}>{k.keyword}</li>
          ))}
        </ul>
      </div>
    </fieldset>
  );
}
