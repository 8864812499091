import { Route, Routes } from "react-router-dom";

import { useAppSelector } from "../../hooks";
import useTitle from "../../hooks/useTitle";
import { sessionSelector } from "../../store/sessionReducer";
import { getDashboardComponent } from "../dashboard/components";

export default function Main() {
  useTitle("Termitool");
  const session = useAppSelector(sessionSelector);
  const DashboardComponent = getDashboardComponent(session);

  return (
    <Routes>
      <Route path="/*" element={<DashboardComponent />} />
    </Routes>
  );
}
