import type { CountMessagesInterface, IdType, MessageInterface } from "./types";

document.addEventListener("tt:userChannelJoined", (ev) => {
  const userChannel = ev.detail;
  userChannel.on("job_done", (job) => {
    const event = new CustomEvent("tt:jobFinished", { detail: job });
    document.dispatchEvent(event);
  });

  userChannel.on("messages_changed", (data: CountMessagesInterface) => {
    const event = new CustomEvent("tt:messagesChanged", { detail: data });
    document.dispatchEvent(event);
  });

  userChannel.on("message_notification", (message: MessageInterface) => {
    const event = new CustomEvent("tt:messageNotification", { detail: message });
    document.dispatchEvent(event);
  });
});

export let VERSIONS = null;

document.addEventListener("tt:userLobbyChannelJoined", (ev) => {
  const userLobbyChannel = ev.detail;

  userLobbyChannel.on("versions", (versions) => {
    VERSIONS = versions;
    const event = new CustomEvent("tt:versions", { detail: versions });
    document.dispatchEvent(event);
  });

  userLobbyChannel.push("version", {}).receive("ok", (versions) => {
    VERSIONS = versions;
    const event = new CustomEvent("tt:versions", { detail: versions });
    document.dispatchEvent(event);
  });
});

document.addEventListener("tt:customerChannelJoined", (ev) => {
  const customerChannel = ev.detail;

  customerChannel.on("customer_edited", (rsp: IdType) => {
    const event = new CustomEvent("tt:customerEdited", { detail: rsp.id });
    document.dispatchEvent(event);
  });

  customerChannel.on("project_edited", (rsp: IdType) => {
    const event = new CustomEvent("tt:projectEdited", { detail: rsp.id });
    document.dispatchEvent(event);
  });
});
