import React from "react";

import { getIn } from "formik";

import { ReactLazyLoading } from "../../containers/ReactLoading";
import { SessionInterface } from "../../store/sessionReducer";

const FiniDashboard = ReactLazyLoading(() => import("../../customers/fini/dashboard"));

const SugDashboard = ReactLazyLoading(() => import("../../customers/sug/dashboard"));

const SkDashboard = ReactLazyLoading(() => import("../../customers/sk/dashboard"));

type DashboardComponentsType = {
  default: {
    default?: React.ComponentType;
  };

  [customerIdentifier: string]: Partial<{
    default: React.ComponentType;
    [projectIdentifier: string]: React.ComponentType;
  }>;
};

const DASHBOARD_COMPONENTS: DashboardComponentsType = {
  "fini-resort-badenweiler": {
    default: FiniDashboard,
  },
  "sevenit-gmbh": {
    sales: ReactLazyLoading(() => import("../../customers/sevenit/dashboard/SalesDashboard")),
    "welcome-calls": ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Welcome")),
    "upselling-6-2021": ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Upselling621")),
    "upgrade-plan-b": ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Outbound")),
    "implementation-calls": ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Outbound")),
    retention: ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Retention")),
    default: ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Support")),
  },

  "sug-ag": {
    default: SugDashboard,
  },

  "sug-gmbh": {
    default: SugDashboard,
  },

  "sk-dienstleistungen": {
    default: SkDashboard,
  },

  "lr-health-beauty": {
    service: ReactLazyLoading(() => import("../../customers/lr-health-beauty/dashboard/Service")),
    "outbound-exit": ReactLazyLoading(() => import("../../customers/lr-health-beauty/dashboard/OutboundExit")),
    "lr-uk": ReactLazyLoading(() => import("../../customers/lr-health-beauty/dashboard/UK")),
    "service-uk": ReactLazyLoading(() => import("../../customers/lr-health-beauty/dashboard/UKService")),
  },

  "lr-france": {
    service: ReactLazyLoading(() => import("../../customers/lr-france/dashboard/Service")),
  },

  metacrew: {
    foodist: ReactLazyLoading(() => import("../../customers/metacrew/dashboard/foodist")),
  },

  chrono24: {
    "2021-11": ReactLazyLoading(() => import("../../customers/chrono24/dashboard/Nov2021")),
  },

  euromaster: {
    erreichbarkeit: ReactLazyLoading(() => import("../../customers/euromaster/dashboard/Erreichbarkeit")),
    "pdf-rechnungen": ReactLazyLoading(() => import("../../customers/euromaster/dashboard/PdfRechnungen")),
  },

  "edition-sportiva": {
    "oktoberfest-2022": ReactLazyLoading(() => import("../../customers/sportiva/dashboard/Oktoberfest2022")),
  },

  termitel: {
    bewerbermanagement: ReactLazyLoading(
      () => import("../../customers/termitel/dashboard/bewerbermanagement/Dashboard"),
    ),
    vertrieb: ReactLazyLoading(() => import("../../customers/termitel/dashboard/Vertrieb")),
    default: ReactLazyLoading(() => import("../../customers/termitel/dashboard/verwaltung/Dashboard")),
  },

  friendsjob: {
    partnergewinnung: ReactLazyLoading(() => import("../../customers/friendsjob/dashboard")),
  },

  hauck: {
    "pos-promotion": ReactLazyLoading(() => import("../../customers/hauk/dashboard/pos-promotion")),
    winterpromotion: ReactLazyLoading(() => import("../../customers/hauk/dashboard/winterpromotion")),
  },

  unn: {
    default: ReactLazyLoading(() => import("../../customers/unn/Dashboard")),
  },

  proxytel: {
    default: ReactLazyLoading(() => import("../../customers/proxytel/vertrieb/Dashboard")),
  },

  mphone: {
    default: ReactLazyLoading(() => import("../../customers/mphone/vertrieb/Dashboard")),
  },

  commlace: {
    default: ReactLazyLoading(() => import("../../customers/commlace/Dashboard")),
  },

  "iso-part-gmbh": {
    default: ReactLazyLoading(() => import("../../customers/iso-part-gmbh/Dashboard")),
  },

  "abs-rz": {
    default: ReactLazyLoading(() => import("../../customers/abs/Dashboard")),
  },

  cityweb: {
    default: ReactLazyLoading(() => import("../../customers/cityweb/Dashboard")),
  },
  reputativ: {
    default: ReactLazyLoading(() => import("../../customers/reputativ/Dashboard")),
  },
  smr: {
    default: ReactLazyLoading(() => import("../../customers/smr/dashboard")),
  },
  carprotec: {
    default: ReactLazyLoading(() => import("../../customers/carpro-tec/Dashboard")),
  },
  default: {
    default: () => {
      return null;
    },
  },
};

export const getDashboardComponent = ({ currentCustomer, currentProject }: SessionInterface) =>
  getIn(DASHBOARD_COMPONENTS, [currentCustomer.identifier, currentProject.identifier]) ||
  getIn(DASHBOARD_COMPONENTS, [currentCustomer.identifier, "default"]) ||
  getIn(DASHBOARD_COMPONENTS, ["default", "default"]);
