import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import TTBreadcrumbItem from "./TTBreadcrumbItem";

export default function TTBreadcrumb() {
  const session = useAppSelector(sessionSelector);
  const { t } = useTranslation(["translation"]);

  return (
    <Breadcrumb aria-label={t("translation:nav.breadcrumb")}>
      {session.breadcrumb.map((item, idx) => (
        <TTBreadcrumbItem key={item.type} item={item} last={idx === session.breadcrumb.length - 1} />
      ))}
    </Breadcrumb>
  );
}
