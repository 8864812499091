import { gql } from "@apollo/client";

export const JOB_RESULT_FRAGMENT = gql`
  fragment JobResultFragment on JobResult {
    id
    module
    done
    seen
    hasAttachment
    filename
    userId
    report
    insertedAt
    updatedAt
  }
`;

export const JOB_RESULTS_QUERY = gql`
  query jobResults($filters: ListJobResultsFilters) {
    jobResults(filters: $filters) {
      ...JobResultFragment
    }
  }
  ${JOB_RESULT_FRAGMENT}
`;

export const COUNT_JOB_RESULTS_QUERY = gql`
  query countJobResults {
    countJobResults {
      seen
      unseen
    }
  }
`;

export const COUNT_JOB_RESULTS_SUBSCRIPTION = gql`
  subscription countJobResults {
    countJobResults {
      seen
      unseen
    }
  }
`;

export const JOB_RESULT_QUERY = gql`
  query jobResult($id: ID!) {
    jobResult(id: $id) {
      ...JobResultFragment
    }
  }
  ${JOB_RESULT_FRAGMENT}
`;

export const JOB_RESULT_DONE_MUTATION = gql`
  mutation markJobResultSeen($id: ID!) {
    markJobResultSeen(id: $id) {
      ...JobResultFragment
    }
  }
  ${JOB_RESULT_FRAGMENT}
`;

export const JOB_RESULT_DELETE_MUTATION = gql`
  mutation deleteJobResult($id: ID!) {
    deleteJobResult(id: $id) {
      id
    }
  }
`;

export const JOB_CREATE_MUTATION = gql`
  mutation jobResultCreate($customerId: ID, $projectId: ID, $module: String!, $config: Json, $file: Upload) {
    jobResultCreate(customerId: $customerId, projectId: $projectId, module: $module, config: $config, file: $file) {
      successful
      messages {
        field
        message
        template
        code
        options {
          key
          value
        }
      }

      result {
        status
      }
    }
  }
`;
