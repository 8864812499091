import React from "react";

import { Button } from "react-bootstrap";
import { MdDone } from "react-icons/md";

import type { TTButtonProps } from "../../types";

const DoneButton: TTButtonProps = React.memo(({ children, ...props }) => (
  <Button variant="secondary" {...props}>
    <MdDone /> {children}
  </Button>
));

export default DoneButton;
