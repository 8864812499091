import React from "react";

import { Button } from "react-bootstrap";
import { MdKeyboardVoice } from "react-icons/md";

interface RecordButtonProps {
  isRecording: boolean;
  onClick?: () => void;
  size?: "sm" | "lg";
  variant?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
}

const RecordButton: React.FC<RecordButtonProps> = React.memo(
  ({ isRecording, onClick, variant = "secondary", size }) => (
    <Button variant={variant} size={size} onClick={isRecording ? onClick : onClick}>
      <MdKeyboardVoice /> {isRecording ? "Aufnahme stoppen" : "Text diktieren"}
    </Button>
  ),
);

export default RecordButton;
