import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFragment on Document {
    id
    subject
    type
    keywords
    visible
    signatureType
    validFrom
    validUntil
    relevantProjectId
    signatureDue

    creatorId
    creator {
      ...UserFragment
    }

    editorId
    editor {
      ...UserFragment
    }

    insertedAt
    updatedAt
  }
  ${USER_FRAGMENT}
`;

export const DOCUMENT_SIGNATURE_FRAGMENT = gql`
  fragment DocumentSignatureFragment on DocumentSignature {
    id
    type
    userId
    documentId
    insertedAt
    updatedAt
  }
`;

export const DETAIL_DOCUMENT_FRAGMENT = gql`
  fragment DetailDocumentFragment on Document {
    ...DocumentFragment
    document

    customerId
    projectId

    relevantProject {
      id
      name
      customer {
        id
        name
        logo {
          thumb
          medium
          original
        }
      }
    }

    attachments {
      id
      file
    }

    missingSignatures {
      ...UserFragment
    }

    signatures {
      ...DocumentSignatureFragment

      user {
        ...UserFragment
      }
    }
  }

  ${DOCUMENT_FRAGMENT}
  ${DOCUMENT_SIGNATURE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const DOCUMENTS_LIST_QUERY = gql`
  query documents($filters: ListDocumentsFilters) {
    documents(filters: $filters) {
      ...DocumentFragment

      customer {
        id
        identifier
        name
      }

      project {
        id
        identifier
        name
        attrs
      }

      relevantProject {
        id
        identifier
        name

        customer {
          id
          identifier
          name
        }
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const DOCUMENTS_COUNT_QUERY = gql`
  query countDocuments($filters: ListDocumentsFilters) {
    countDocuments(filters: $filters)
  }
`;

export const DOCUMENT_QUERY = gql`
  query document($customerId: ID!, $projectId: ID!, $id: ID!) {
    document(customerId: $customerId, projectId: $projectId, id: $id) {
      ...DetailDocumentFragment
    }
  }
  ${DETAIL_DOCUMENT_FRAGMENT}
`;

export const DOCUMENT_MUTATION = gql`
  mutation mutateDocument($customerId: ID!, $projectId: ID!, $id: ID, $values: DocumentInput!) {
    mutateDocument(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...DocumentFragment
      document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation deleteDocument($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteDocument(customerId: $customerId, projectId: $projectId, id: $id) {
      id
    }
  }
`;

export const CONFIRM_DOCUMENT_MUTATION = gql`
  mutation confirmDocument($customerId: ID!, $projectId: ID!, $id: ID!) {
    confirmDocument(customerId: $customerId, projectId: $projectId, id: $id) {
      ...DocumentSignatureFragment

      user {
        ...UserFragment
      }
    }
  }
  ${DOCUMENT_SIGNATURE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const MISSING_SIGNATURES_QUERY = gql`
  query missingDocumentSignaturesForUsers($customerId: ID!, $projectId: ID!) {
    missingDocumentSignaturesForUsers(customerId: $customerId, projectId: $projectId) {
      userId
      missing
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const MISSING_SIGNATURES_FOR_USER_QUERY = gql`
  query missingDocumentSignaturesForUser($customerId: ID!, $projectId: ID!, $userId: ID!) {
    missingDocumentSignaturesForUser(customerId: $customerId, projectId: $projectId, userId: $userId) {
      ...DocumentFragment

      customer {
        id
        identifier
        name
      }

      project {
        id
        identifier
        name
      }

      relevantProject {
        id
        identifier
        name

        customer {
          id
          identifier
          name
        }
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const DOCUMENT_ATTACHMENT_MUTATION = gql`
  mutation mutateDocumentAttachment(
    $customerId: ID!
    $projectId: ID!
    $documentId: ID!
    $id: ID
    $values: DocumentAttachmentInput!
  ) {
    mutateDocumentAttachment(
      customerId: $customerId
      projectId: $projectId
      documentId: $documentId
      id: $id
      values: $values
    ) {
      id
      file
    }
  }
`;

export const OVERDUE_DOCUMENTS_LIST_QUERY = gql`
  query overdueDocuments {
    overdueDocuments {
      ...DocumentFragment

      customer {
        id
        identifier
        name
      }

      project {
        id
        identifier
        name
        attrs
      }

      relevantProject {
        id
        identifier
        name
        attrs

        customer {
          id
          identifier
          name
        }
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;
