import { useEffect, useState } from "react";

import { ButtonGroup, Dropdown } from "react-bootstrap";
import type { IconType } from "react-icons";
import { MdAdd, MdAddCircle, MdCloudDownload, MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";

import { scaffoldSelector } from "../components/app/scaffoldReducer";
import { useAppSelector } from "../hooks";
import { AddButton, ExportButton, NewButton } from "./buttons";
import TTButton from "./buttons/TTButton";

export type NavGroup = NavButton[];

export type NavButton = {
  label: string;
  icon?: IconType;
  to?: string | null;
  allowed?: boolean | null;
  active?: boolean;
  action?: string;
  onExport?: () => void;
  onClick?: () => void;
};

function getItemByAction(button: NavButton, type: string, key: string | number) {
  switch (button.action) {
    case "new":
      if (type === "button") {
        return (
          <NewButton active={!!button.active} as={Link} to={button.to!} key={key} variant="outline-secondary" size="sm">
            {button.label}
          </NewButton>
        );
      }

      return (
        <Dropdown.Item as={Link} to={button.to!} key={key}>
          <MdAdd /> {button.label}
        </Dropdown.Item>
      );

    case "add":
      if (type === "button") {
        return (
          <AddButton active={!!button.active} as={Link} to={button.to!} variant="outline-secondary" size="sm" key={key}>
            {button.label}
          </AddButton>
        );
      }

      return (
        <Dropdown.Item as={Link} to={button.to!} key={key}>
          <MdAddCircle /> {button.label}
        </Dropdown.Item>
      );

    case "export":
      if (type === "button") {
        return (
          <ExportButton
            active={!!button.active}
            size="sm"
            variant="outline-secondary"
            onClick={button.onExport}
            key={key}
          >
            {button.label}
          </ExportButton>
        );
      }

      return (
        <Dropdown.Item onClick={button.onExport} key={key}>
          <MdCloudDownload /> {button.label}
        </Dropdown.Item>
      );

    case "onClick":
      if (type === "button") {
        return (
          <TTButton active={!!button.active} size="sm" variant="outline-secondary" onClick={button.onClick} key={key}>
            {button.icon && <button.icon />} {button.label}
          </TTButton>
        );
      }

      return (
        <Dropdown.Item onClick={button.onClick} key={key}>
          {button.icon && <button.icon />} <MdCloudDownload /> {button.label}
        </Dropdown.Item>
      );

    default:
      if (type === "button") {
        return (
          <TTButton as={Link} to={button.to!} active={!!button.active} variant="outline-secondary" size="sm" key={key}>
            {button.icon && <button.icon />} {button.label}
          </TTButton>
        );
      }

      return (
        <Dropdown.Item as={Link} to={button.to!} key={key}>
          {button.icon && <button.icon />} {button.label}
        </Dropdown.Item>
      );
  }
}

const activeItem = (links?: NavButton[]) => links?.find((link) => link.active) || links?.[0];

const SubSubNav = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { subSubNav } = useAppSelector(scaffoldSelector);

  useEffect(() => {
    const handleResize = () => {
      const mq = window.matchMedia("(max-width: 768px)");
      setIsMobile(mq.matches);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!subSubNav.length) return null;

  if (isMobile) {
    const navItems = subSubNav[0]?.groups.reduce<NavButton[]>((acc, curr) => [...acc, ...curr], []);
    const activeComponent = activeItem(navItems);

    // this only happens when there is a bug
    if (!activeComponent) return null;

    return (
      <div className="TT-menu">
        <Dropdown drop="down">
          <Dropdown.Toggle variant="outline-secondary">
            {activeComponent.icon ? <activeComponent.icon /> : <MdMenu />} {activeComponent.label}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {navItems?.map((item, i) => item.allowed && getItemByAction(item, "dropdown", i))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  return (
    <>
      <div className="TT-sub-sub-nav">
        {subSubNav[0]?.groups.map(
          (group, index) =>
            group.some((button) => button.allowed) && (
              <ButtonGroup key={index} size="sm">
                {group.map((button, i) => button.allowed && getItemByAction(button, "button", `${index}-${i}`))}
              </ButtonGroup>
            ),
        )}
      </div>
    </>
  );
};

export default SubSubNav;
