import { CONTACT_GET_QUERY, CONTACT_MUTATION } from "../../../../../api/contacts/contacts";
import apolloClient from "../../../../../apollo";
import { MutationError } from "../../../../../handleError";
import { SessionInterface } from "../../../../../store/sessionReducer";
import {
  ContactDataInterface,
  ContactInterface,
  ContactMutationInterface,
  ContactQueryVarsInterface,
  HistoryInterface,
} from "../../../../../types";

export const setContactField = async (
  session: SessionInterface,
  contact: ContactInterface,
  history: HistoryInterface,
) => {
  if (history.htype !== "CALL") return;

  const { data } = await apolloClient.query<ContactDataInterface, ContactQueryVarsInterface>({
    query: CONTACT_GET_QUERY,
    variables: {
      customerId: session.currentCustomer.id,
      projectId: session.currentProject.id,
      id: contact.id,
    },
  });

  if (!data.contact) return;

  const { data: cData } = await apolloClient.mutate<ContactMutationInterface>({
    mutation: CONTACT_MUTATION,
    variables: {
      customerId: session.currentCustomer.id,
      projectId: session.currentProject.id,
      id: contact.id,
      contact: {
        attrs: {
          ...data.contact.attrs,
          addfields: { ...(data.contact.attrs?.addfields || {}), gespraechszusammenfassung: history.details },
        },
      },
    },
  });

  if (!cData?.mutateContact) {
    throw new MutationError();
  }
};
